import React from "react";
import Dashboardpage from "Pages/Profile/Dashboard";
import Headerlogin from "Component/Header/Headerlogin";

export default function Dashboardpagetheme() {
  return (
    <>
       <Headerlogin heading="Dashboard"/>
       <Dashboardpage/>
    </>
  );
}

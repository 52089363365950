import * as CONST from "./actionTypes";

export const getTransaction = (payload) => ({
  type: CONST.GET_TRANSACTION,
  payload: payload,
});

export const getTransactionSuccess = (data) => ({
  type: CONST.GET_TRANSACTION_SUCCESS,
  payload: data,
});

export const getTransactionFail = (error) => ({
  type: CONST.GET_TRANSACTION_FAIL,
  payload: error,
});



export const addTransaction = (data, callback) => ({
  type: CONST.ADD_TRANSACTION,
  payload: { data, callback },
});

export const addTransactionSuccess = (data) => ({
  type: CONST.ADD_TRANSACTION_SUCCESS,
  payload: data,
});

export const addTransactionFail = (error) => ({
  type: CONST.ADD_TRANSACTION_FAIL,
  payload: error,
});




export const addInvestment = (data, callback) => ({
  type: CONST.ADD_INVESTMENT,
  payload: { data, callback },
});

export const addInvestmentSuccess = (data) => ({
  type: CONST.ADD_INVESTMENT_SUCCESS,
  payload: data,
});

export const addInvestmentFail = (error) => ({
  type: CONST.ADD_INVESTMENT_FAIL,
  payload: error,
});

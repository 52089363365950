import { UPLOAD_FILE, REMOVE_FILE, API_FAIL, UPLOAD_FILE_REQUEST, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAIL } from "./actionTypes"

export const uploadFileRequest = (data, callback) => ({
  type: UPLOAD_FILE_REQUEST,
  payload: { data, callback },
})

export const uploadFileSuccess = (data) => ({
  type: UPLOAD_FILE_SUCCESS,
  payload: data,
})
export const uploadFileFail = (error) => ({
  type: UPLOAD_FILE_FAIL,
  payload: error,
})

export const removeFile = fileId => ({
  type: REMOVE_FILE,
  payload: fileId,
})

import React, { useEffect, useState } from "react";
import { Button, Form } from "reactstrap";
import Transacsubmit from "Component/Modals/Transacsubmitmmod";
import { Slider } from "antd";
import useWallet from "Hooks/wallet";
import Web3Intraction from "utils/web3Intraction1";
import toastr from "toastr";

const marks = {
  1: {
    style: {
      color: "#fff",
      opacity: 0.5,
    },
    label: <strong>1X</strong>,
  },
  2: {
    style: {
      color: "#fff",
      opacity: 0.5,
    },
    label: <strong>2X</strong>,
  },
  3: {
    style: {
      color: "#fff",
      opacity: 0.5,
    },
    label: <strong>3X</strong>,
  },
  4: {
    style: {
      color: "#fff",
      opacity: 0.5,
    },
    label: <strong>4X</strong>,
  },
  5: {
    style: {
      color: "#fff",
      opacity: 0.5,
    },
    label: <strong>5X</strong>,
  },
};
//  https://api.coingecko.com/api/v3/simple/price?ids=DAI&vs_currencies=USD
const Buycelltab = (props) => {
  const wallet = useWallet();
  const [Transacsubmitmmod, setTransacsubmitmmod] = useState(false);
  const [balance, setBalance] = useState(0);
  const [fields, setfields] = useState({
    quantity: 0,
    collateral: 0,
    leverage: 0,
  });
  useEffect(() => {
    if (props.settings && wallet.account) {
      (async () => {
        try {
          const web3Intraction = new Web3Intraction(
            "ethereum",
            wallet.provider,
            props.settings
          );
          const amount = await web3Intraction.getBalance(wallet.account);
          setBalance(amount);
        } catch (error) {
          setBalance(0);
        }
      })();
    }
  }, [props.settings, wallet]);
  const handleChange = (event) => {
    const { name, value } = event.target;

    setfields((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <>
      <Transacsubmit
        show={Transacsubmitmmod}
        onhide={() => setTransacsubmitmmod(false)}
      />
      <section className="buycelltab">
        <div
          className="buybtn_wrapping p-1 bg-dark w-100 rounded d-flex align-items-center 
           justify-content-between"
        >
          <Button className="active">Long OLE</Button>
          <Button>Short OLE</Button>
        </div>
        <Form className="position-relative">
          <div className="price-input_wrap bottom_dropdown">
            <div className="right_dropdown w-100 d-flex align-items-center justify-content-between">
              <div className="left_chainn">
                <div className="ac_input">
                  <input
                    type="number"
                    placeholder="0"
                    min={0}
                    name="quantity"
                    onChange={handleChange}
                  />
                </div>
                <div className="ac_token d-flex align-items-center">
                  <p className="m-0 text-white">
                    {props?.pairDetails?.secondryToken?.symbol}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="custom_range">
            <Slider
              marks={marks}
              trackBg="f00"
              defaultValue={1}
              min={1}
              max={5}
            />
            <div className="poolinfo_here p-3 rounded bg-dark">
              <ul>
                <li>
                  <p>Collateral</p>
                  <p>Balance: {parseFloat(Number(balance).toFixed(4))} Eth</p>
                </li>
                <li>
                  <div className="ac_input">
                    <input
                      type="number"
                      readOnly
                      placeholder="0"
                      min={0}
                      name="amount"
                    />
                  </div>
                  <div className="ac_token d-flex align-items-center">
                    <div className="ac_img me-1">
                      <img
                        src="https://w7.pngwing.com/pngs/268/1013/png-transparent-ethereum-eth-hd-logo-thumbnail.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <p>ETH</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="poolinfo_here">
            <ul>
              <li>
                <p>Asset used as Collateral</p>
                <p>9.254151</p>
              </li>
              <li>
                <p>Value of Collateral asset</p>
                <p>1,050.00</p>
              </li>
              <li>
                <p>Leverage used</p>
                <p>10.53%</p>
              </li>
              <li>
                <p>Entry price in Trade</p>
                <p>195421</p>
              </li>
            </ul>

            <div className="buyselll_btn">
              <Button className="connect_wallet_cta w-100">Done</Button>
            </div>
          </div>
          <div className="buyselll_btn"></div>
        </Form>
      </section>
    </>
  );
};

export default Buycelltab;

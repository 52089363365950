import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Fade } from "react-reveal";

const Monitoring = (props) => {
  const { settingData } = props;
  return (
    <Fade>
      <section className="monitoring_sec">
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <div className="mointoring_head text-start">
                <Fade top>
                  <h2 className="Section_head">
                    {(settingData?.lastSection && settingData?.lastSection.title) ? settingData?.lastSection.title : "The Best Place for Leverage Trading."}
                  </h2>
                </Fade>
                <Fade top>
                  <p>
                    {(settingData?.lastSection && settingData?.lastSection.description) ? settingData?.lastSection.description : "We provide our users with a platform to trade on multiple chains, and access advanced analytics tools to make more informed trading decisions. Advanced analytics tools, such as real-time market data and technical analysis charts, as well as several other functionalities which make LeverageIT distinctive from its competitors."}
                  </p>
                </Fade>

                <Fade top>
                  <Button className="btn_common">DOCUMENTATION </Button>
                </Fade>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} className="monitoring_mob p-0 mt-5 d-md-none d-xs-block">
              <div className="monitoring_right">
                <img src={(settingData?.lastSection && settingData?.lastSection.image) ? settingData?.lastSection.image : "/images/moniotring.png"} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fade>
  );
};

export default Monitoring;

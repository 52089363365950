import { call, put, takeEvery } from "redux-saga/effects";
import * as CONST from "./actionTypes";
import * as ACT from "./actions";
import { getExpertsApi} from "helpers/backend_helper";


function* getExpertsSaga({ payload }) {
  try {
    const response = yield call(getExpertsApi, payload);
    if (response.status == "failure") {
      return yield put(ACT.getExpertFail(response.message));
    }
    yield put(ACT.getExpertSuccess(response));
  } catch (error) {
    yield put(ACT.getExpertFail(error));
  }
}

function* expertSaga() {
  yield takeEvery(CONST.GET_EXPERT, getExpertsSaga);
}

export default expertSaga;

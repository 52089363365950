import React from "react";
import { Spinner, Button } from "react-bootstrap";

function ClickButton(props) {
  const { btnText, className, disabled, onClick } = props;

  return (
    <Button
      className={`${className ? className : "done-btn mt-3"}`}
      type={props.type || "button"}
      disabled={disabled}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          onClick(e);
        }
      }}
    >
      <div className="d-flex align-center justify-center submit-btn">
        {disabled && (
          <div className="spinner">
            <Spinner color="primary" size="sm" />
          </div>
        )}

        {btnText}
      </div>
    </Button>
  );
}

export default ClickButton;

import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Fade } from "react-reveal";
import Tilt from "react-parallax-tilt";
import { useSelector } from "react-redux";

const Home = (props) => {
  const { settingData } = props;
  return (
    <Fade>
      <section className="home_banner_main position-relative comon_pad">
        <div className="banner_right_gra"></div>
        <div className="heder_gra"></div>
        <div className="banner_overlay"></div>
        <Container className="position-relative">
          <Row>
            <Col lg={6} md={6} sm={12}>
              <div className="Home_banner_left text-start position-relative">
                <div className="headingup_dot"></div>
                <div className="paraup_dot"></div>
                <div className="btnbottom_dot"></div>

                <Fade top>
                  <h1>{(settingData?.heroSection && settingData?.heroSection?.title) ? settingData?.heroSection?.title
                    : "ProfIT Effortlessly"}
                  </h1>
                </Fade>

                <Fade top>
                  <p>
                    {(settingData?.heroSection && settingData?.heroSection?.description) ? settingData?.heroSection?.description
                      : "Decentralized Leverage Trading Protocol, for Crypto, Stocks and more... Trade with ease in a unique and simplified interface. Open leverage trading positions on both stock and cryptocurrency assets. Get reimbursed a percentage of your loses through the lucky-losers initiative."}

                  </p>
                </Fade>

                <Fade top>
                  <Button className="btn_border">EXPLAIN IT</Button>
                </Fade>
              </div>
            </Col>

            <Col lg={6} md={6} sm={12}>
              <div className="left_banner_img position-relative">
                <div className="img_left_grad"></div>
                <div className="img_top_grad"></div>
                <div className="img_right_grad"></div>

                <Tilt className="parallax-effect" perspective={500}>
                  <div className="inner-element">
                    <img
                      src={(settingData?.heroSection && settingData?.heroSection?.image) ? settingData?.heroSection?.image : "/images/tiltimg.png"}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Tilt>
              </div>
            </Col>
          </Row>
        </Container>
      </section>



    </Fade>
  );
};

export default Home;

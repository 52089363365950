import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
} from "reactstrap";

const Searchpair = (props) => {
    const [toggledrop, settoggledrop] = useState(false);
    const togdropdown = () => {
      settoggledrop((prevstate) => !prevstate);
    };
  return (
    <section className="trade_fillter d-flex justify-content-between my-4">
        <div className="left_filter_side d-flex align-items-center">
          <div className="custom_dropdown position-relative me-3">
            <Button onClick={togdropdown}>
              <div className="showtext d-flex align-items-center justify-content-between gap-2">
                <div className="icon_show">
                  <img src="/images/ether.png" alt="" className="img-fluid" />
                </div>
                <p className="m-0">Ethereum</p>
                
              </div>
            </Button>
            
          </div>
        </div>

        <div className="right_search">
          <Form>
            <FormGroup className="position-relative">
              <Input
                type="search"
                name="search"
                id="exampleEmail"
                onChange={props.searchKeyword}
                placeholder="Search pair by symbol, name, contract or token"
              />
              <div className="searchfille_icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <path
                    d="M10.5625 6.4375C10.5625 5.27718 10.1016 4.16438 9.28109 3.34391C8.46062 2.52344 7.34782 2.0625 6.1875 2.0625C5.02718 2.0625 3.91438 2.52344 3.09391 3.34391C2.27344 4.16438 1.8125 5.27718 1.8125 6.4375C1.8125 7.59782 2.27344 8.71062 3.09391 9.53109C3.91438 10.3516 5.02718 10.8125 6.1875 10.8125C7.34782 10.8125 8.46062 10.3516 9.28109 9.53109C10.1016 8.71062 10.5625 7.59782 10.5625 6.4375ZM9.71758 10.8973C8.74961 11.6656 7.52187 12.125 6.1875 12.125C3.0457 12.125 0.5 9.5793 0.5 6.4375C0.5 3.2957 3.0457 0.75 6.1875 0.75C9.3293 0.75 11.875 3.2957 11.875 6.4375C11.875 7.77187 11.4156 8.99961 10.6473 9.96758L14.3086 13.6289C14.5656 13.8859 14.5656 14.3016 14.3086 14.5559C14.0516 14.8102 13.6359 14.8129 13.3816 14.5559L9.71758 10.8973Z"
                    fill="#CACEDB"
                  />
                </svg>
              </div>
            </FormGroup>
          </Form>
        </div>
      </section>
  )
}

export default Searchpair
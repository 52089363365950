import * as CONST from "./actionTypes";

const INIT_STATE = {
  blogs: [],
  blog:null,
  totalBlogs: 0,
  error: "",
  loading: false,
};

const blog = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Blogs */
    case CONST.GET_BLOGS:
      return {
        ...state,
        loading: true,
      };

    case CONST.GET_BLOGS_SUCCESS:
      return {
        ...state,
        blogs: action.payload.data || [],
        totalBlogs: action.payload.totalCount || 0,
        loading: false,
        error: "",
      };
    case CONST.GET_BLOGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Get Blogs END */

    /* Add blog */
    case CONST.ADD_BLOG:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case CONST.ADD_BLOG_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CONST.ADD_BLOG_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Add blog END */

    /* Get blog */
    case CONST.GET_BLOG:
      return {
        ...state,
        error: "",
        loading: true,
        blog: null,
      };

    case CONST.GET_BLOG_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        blog: null,
      };

    case CONST.GET_BLOG_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        blog: action.payload,
      };
    /* Add blog END */

    /* Update blog */
    case CONST.PUT_BLOG:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case CONST.PUT_BLOG_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CONST.PUT_BLOG_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Update blog END */

    /* Delete blog */
    case CONST.DELETE_BLOG:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case CONST.DELETE_BLOG_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CONST.DELETE_BLOG_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Delete blog END */

    default:
      return state;
  }
};

export default blog;

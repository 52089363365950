

// Blogs
export const GET_BLOGS = "GET_BLOGS";
export const GET_BLOGS_FAIL = "GET_BLOGS_FAIL";
export const GET_BLOGS_SUCCESS = "GET_BLOGS_SUCCESS";


// Add Post
export const ADD_BLOG = "ADD_BLOG";
export const ADD_BLOG_FAIL = "ADD_BLOG_FAIL";
export const ADD_BLOG_SUCCESS = "ADD_BLOG_SUCCESS";

// Get Post
export const GET_BLOG = "GET_BLOG";
export const GET_BLOG_FAIL = "GET_BLOG_FAIL";
export const GET_BLOG_SUCCESS = "GET_BLOG_SUCCESS";

// update Post
export const PUT_BLOG = "PUT_BLOG";
export const PUT_BLOG_FAIL = "PUT_BLOG_FAIL";
export const PUT_BLOG_SUCCESS = "PUT_BLOG_SUCCESS";

// Delete Post
export const DELETE_BLOG = "DELETE_BLOG";
export const DELETE_BLOG_FAIL = "DELETE_BLOG_FAIL";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";


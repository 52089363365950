import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";
import * as API from "helpers/backend_helper";

function* sendOtp({ payload: { user, callback } }) {
  try {
    const response = yield call(API.postsendOtp, user);

    callback && callback(response);
    if (response.status == "failure") {
      return yield put(ACTIONS.sendOtpFailed(response.message));
    }
    
    yield put(ACTIONS.sendOtpSuccessful(response));
  } catch (error) {
    yield put(ACTIONS.sendOtpFailed("Internal Server Error"));
  }
}

export function* watchsendOtp() {
  yield takeEvery(TYPES.SEND_OTP, sendOtp);
}

function* accountSaga() {
  yield all([fork(watchsendOtp)]);
}

export default accountSaga;


export const GET_ALL_PAIRS_LIST_REQUEST = "GET_ALL_PAIRS_LIST_REQUEST"
export const GET_ALL_PAIRS_LIST_SUCCESS = "GET_ALL_PAIRS_LIST_SUCCESS"
export const GET_ALL_PAIRS_LIST_FAIL = "GET_ALL_PAIRS_LIST_FAIL"


export const GET_TOP_TRADE_PAIRS_REQUEST = "GET_TOP_TRADE_PAIRS_REQUEST"
export const GET_TOP_TRADE_PAIRS_SUCCESS = "GET_TOP_TRADE_PAIRS_SUCCESS"
export const GET_TOP_TRADE_PAIRS_FAIL = "GET_TOP_TRADE_PAIRS_FAIL"

export const GET_TRADE_TOKENS_REQUEST = "GET_TRADE_TOKENS_REQUEST"
export const GET_TRADE_TOKENS_SUCCESS = "GET_TRADE_TOKENS_SUCCESS"
export const GET_TRADE_TOKENS_FAIL = "GET_TRADE_TOKENS_FAIL"

export const GET_PAIR_DETAILS = "GET_PAIR_DETAILS"
export const GET_PAIR_DETAILS_SUCCESS = "GET_PAIR_DETAILS_SUCCESS"
export const GET_PAIR_DETAILS_FAIL = "GET_PAIR_DETAILS_FAIL"


export const GET_TRADE_DETAILS = "GET_TRADE_DETAILS"
export const GET_TRADE_DETAILS_SUCCESS = "GET_TRADE_DETAILS_SUCCESS"
export const GET_TRADE_DETAILS_FAIL = "GET_TRADE_DETAILS_FAIL"


export const GET_CANDLES_LIST = "GET_CANDLES_LIST"
export const GET_CANDLES_LIST_SUCCESS = "GET_CANDLES_LIST_SUCCESS"
export const GET_CANDLES_LIST_FAIL = "GET_CANDLES_LIST_FAIL"

export const GET_TOKEN_CURRENT_PRICE = "GET_TOKEN_CURRENT_PRICE"
export const GET_TOKEN_CURRENT_PRICE_SUCCESS = "GET_TOKEN_CURRENT_PRICE_SUCCESS"
export const GET_TOKEN_CURRENT_PRICE_FAIL = "GET_TOKEN_CURRENT_PRICE_FAIL"

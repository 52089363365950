import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

import Hotpairs from "../Hotpairs";
import Searchpair from "../Searchpair";
import Pairpricechart from "./Pairpricechart";
import { useDispatch, useSelector } from "react-redux";
import { getTradeTokenRequest } from "store/actions";

const Leveragetrade = () => {
  const dispatch = useDispatch();
  const { tradeTokens } = useSelector((state) => state.Crypto);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
  });
  const searchKeyword = (e) => {
    setFilters((prev) => ({ ...prev, search: e.target.value }))
  }

  useEffect(() => {
    dispatch(getTradeTokenRequest(filters));
  }, [filters]);
  return (
    <section className="trade_new my-3">
      <Container fluid>
        <Hotpairs tradeTokens = {tradeTokens} />
        <Searchpair searchKeyword = {searchKeyword} />
        <Pairpricechart heading="Pair Price Chart" />
      </Container>
    </section>
  );
};

export default Leveragetrade;

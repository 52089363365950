import React, { useEffect, useState } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Tab, Tabs, Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTransaction, getExpert, getBlogs } from "store/actions";
import CardWrapper from "Component/BlogCards/CardWrapper";
import InvestmentList from "Component/InvestmentList";

const Expertprofilecol = (props) => {
  const { id } = useParams();

  const { user } = useSelector(({ Login }) => Login);
  const { blogs, loading } = useSelector((state) => state.Blogs);

  const { experts } = useSelector(({ Expert }) => Expert);

  const dispatch = useDispatch();

  const [filters, setFiters] = useState({
    page: 1,
    limit: 100,
    loading: false,
    user: id,
    fields: [{ fieldName: "isInvestment", fieldValue: true }],
  });

  const [expert, setExpert] = useState(undefined);

  useEffect(() => {
    if (user) {
      dispatch(getExpert({ ...filters, fields: null }));
      dispatch(getTransaction({ ...filters }));
    }
  }, [user, filters]);

  useEffect(() => {
    setExpert(experts[0]);
  }, [user, experts, setExpert]);

  useEffect(() => {
    if (id) {
      dispatch(getBlogs({ userId: id }));
    }
  }, [id]);

  return (
    <>
      <section className="section_expertprofile mt-3">
        <div className="shared_head d-flex justify-content-between align-items_center mb-3">
          <h3 className="card_heading">{props?.heading}</h3>
        </div>

        <div className="profiler_Card">
          <div className="expertprofile_banner">
            <img src="/images/coverimg.png" alt="" className="img-fluid" />
          </div>
          <div className="expert_profilepannel">
            <div className="Expert_users_side">
              <div className="profile_img me-3">
                <img
                  src={expert?.profileImage?.link}
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="Expert_profilename">
                <h5>{expert?.username}</h5>
                <p>{expert && expert.username ? `@${expert.username}` : ""}</p>
              </div>
            </div>

            <div className="Expert_asstes_side">
              <div className="assets_left">
                {/* <h6>Total Assets</h6>

                <div className="d-flex align-items-center gap-2">
                  <h3>2,460.89</h3>
                  <span className="shot_name">USD</span>
                </div>
                <p>
                  0.23415600 <span className="shot_name">BTC</span>
                </p> */}
              </div>
              <div className="assets_right">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="116"
                  height="75"
                  viewBox="0 0 116 75"
                  fill="none"
                >
                  <path
                    d="M114.903 74.2257C114.659 74.4325 114.351 74.5462 114.032 74.5469H1.95089C1.21428 74.5469 0.611607 73.9384 0.611607 73.1946C0.611607 72.8734 0.728796 72.5692 0.929688 72.3156L29.4397 38.1707C29.9085 37.596 30.7623 37.5284 31.3315 38.0017C31.3817 38.0524 31.4487 38.1031 31.4989 38.1707L32.7898 39.7181C39.9046 48.2461 43.462 52.5101 48.1409 52.5113C52.8197 52.5124 56.3792 48.2501 63.4981 39.7255L74.6071 26.4229C75.0759 25.8482 75.9297 25.7805 76.4989 26.2538C76.5491 26.3045 76.6161 26.3553 76.6663 26.4229L115.104 72.3325C115.539 72.8903 115.472 73.7524 114.903 74.2257Z"
                    fill="url(#paint0_linear_692_708)"
                  />
                  <path
                    d="M28.8705 0.340908C34.7801 0.340908 39.5848 5.19219 39.5848 11.1591C39.5848 17.126 34.7801 21.9773 28.8705 21.9773C22.9609 21.9773 18.1562 17.126 18.1562 11.1591C18.1562 5.19219 22.9609 0.340908 28.8705 0.340908Z"
                    fill="url(#paint1_linear_692_708)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_692_708"
                      x1="-12.5882"
                      y1="11.4712"
                      x2="133"
                      y2="11.4712"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#2745EA" />
                      <stop offset="1" stop-color="#CF7BF4" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_692_708"
                      x1="-12.5882"
                      y1="11.4712"
                      x2="133"
                      y2="11.4712"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#2745EA" />
                      <stop offset="1" stop-color="#CF7BF4" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="tabbing_profiler mt-4">
          <Tabs
            defaultActiveKey="posts"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="posts" title="Posts">
              <CardWrapper
                blogs={blogs}
                enableActions={false}
                loading={loading}
              />
            </Tab>
            <Tab eventKey="investments" title="Investments">
              <InvestmentList id={id} />
            </Tab>
          </Tabs>
        </div>
      </section>
    </>
  );
};

export default Expertprofilecol;

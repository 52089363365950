import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import { loginUser, loginWithWalletRequest } from "store/actions";
import CryptoJS from "crypto-js";
import useWallet from "Hooks/wallet";
import { getNetworkUrl } from "helpers/constants";

const Signin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const wallet = useWallet();

  const [fields, setFields] = useState({
    role: "USER",
    email: "",
    password: "",
  });

  const handleChange = (name) => (e) => {
    setFields((prev) => ({ ...prev, [name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (fields.role == "") {
      return toastr.error("Role is required!");
    }
    if (fields.email == "") {
      return toastr.error("Email is required!");
    }
    if (fields.password == "") {
      return toastr.error("Password is required!");
    }
    dispatch(loginUser(fields, navigate));
  };

  const encryptWalletAddress = (address) => {
    // Replace 'yourSecretKey' with a strong secret key
    const secretKey = "leverage!!2@@!6";
    return CryptoJS.AES.encrypt(address, secretKey).toString();
  };

  console.log("wallet",wallet.account)
  const { settings } = useSelector((state) => state.Settings);

  const loginWithWallet = async () => {
    // if (loadingLoginWithWallet) {
    //   return;
    // }
    // const callback = (response) => {
    //   if (response?.status == "success") {
    //     toastr.success("Login succesfully");
    //     navigate("/dashboard");
    //   } else {
    //     toastr.error(
    //       response?.message
    //         .replace("_", " ")
    //         .toLocaleLowerCase()
    //         .charAt(0)
    //         .toUpperCase() +
    //         response?.message?.replace("_", " ").toLocaleLowerCase().slice(1)
    //     );
    //   }
    // };
    console.log("settings",settings);
    const networkUrl = getNetworkUrl("ethereum",settings)
    console.log("networkUrl",networkUrl)
    const walletRes = await wallet.connect("",networkUrl?.chainId);

    if (walletRes[0] && walletRes[0].provider) {
      let res = await wallet.getSign(
        walletRes[0].provider,
        walletRes[0].accounts
      );
      res = JSON.parse(JSON.stringify(res));
console.log("res",res)
      if (res?.code === 4001) {
        toastr.error(res?.message);
      } else {
        const callback = (success, res) => {
          console.log("callback callback")
          // dispatch(currentUser());
          if (!success) {
            wallet.disconnect();
            return toastr.error(res.message);
          } else {
            wallet.connectSuccess(walletRes[0]);
            navigate("/dashboard");
          }
        };

        console.log("walletAccount", walletRes);
        const walletAddress = walletRes?.[0]?.["accounts"]?.[0]?.["address"];
        console.log(
          "wallet after connect",
          walletRes?.[0]?.["accounts"]?.[0]?.["address"]
        );
        if (!walletAddress && !wallet.account) {
          return;
        }
        const walltAdd = encryptWalletAddress(walletAddress || wallet.account);
        console.log("walletAdd",walltAdd);
        if(!walltAdd) return toastr.error("Wallet encryption wallet!");
        dispatch(
          loginWithWalletRequest({ walletAddress: walltAdd}, callback)
        );
      }
    }

   
  };

  return (
    <Fade>
      <section className="signup_main common_sign_main sign_in">
        <Container>
          <div className="sign_up_inner">
            <div className="logo_form mb-4">
              <img
                width={"150px"}
                src="/images/logo.png"
                alt=""
                className="img-fluid"
              />
            </div>
            <h2 className="text-center Signup_head">Login</h2>

            <Form className="signup_form" onSubmit={handleSubmit}>
              <div className="checks d-flex justify-content-evenly">
                <FormGroup check className="check_box">
                  <Label check>
                    <Input
                      type="radio"
                      name="radio1"
                      value="USER"
                      onChange={handleChange("role")}
                      checked={fields.role === "USER"}
                    />{" "}
                    Normal User
                  </Label>
                </FormGroup>
                <FormGroup check className="check_box">
                  <Label check>
                    <Input
                      type="radio"
                      name="radio1"
                      value="EXPERT"
                      onChange={handleChange("role")}
                      checked={fields.role === "EXPERT"}
                    />{" "}
                    Expert User
                  </Label>
                </FormGroup>
              </div>
              <FormGroup className="position-relative">
                <Input
                  type="email"
                  id="exampleEmail"
                  placeholder="Email Id"
                  onChange={handleChange("email")}
                />
                <div className="leftform_icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M4.16663 4.16663H15C15.663 4.16663 16.2989 4.43002 16.7677 4.89886C17.2366 5.3677 17.5 6.00358 17.5 6.66663V14.1666C17.5 14.8297 17.2366 15.4656 16.7677 15.9344C16.2989 16.4032 15.663 16.6666 15 16.6666H4.16663C3.50358 16.6666 2.8677 16.4032 2.39886 15.9344C1.93002 15.4656 1.66663 14.8297 1.66663 14.1666V6.66663C1.66663 6.00358 1.93002 5.3677 2.39886 4.89886C2.8677 4.43002 3.50358 4.16663 4.16663 4.16663ZM4.16663 4.99996C3.74996 4.99996 3.38329 5.14163 3.09996 5.39163L9.58329 9.58329L16.0666 5.39163C15.7833 5.14163 15.4166 4.99996 15 4.99996H4.16663ZM9.58329 10.5916L2.60829 6.06663C2.54163 6.24996 2.49996 6.45829 2.49996 6.66663V14.1666C2.49996 14.6087 2.67555 15.0326 2.98811 15.3451C3.30068 15.6577 3.7246 15.8333 4.16663 15.8333H15C15.442 15.8333 15.8659 15.6577 16.1785 15.3451C16.491 15.0326 16.6666 14.6087 16.6666 14.1666V6.66663C16.6666 6.45829 16.625 6.24996 16.5583 6.06663L9.58329 10.5916Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </FormGroup>

              <FormGroup className="position-relative">
                <Input
                  type="password"
                  id="examplePassword"
                  placeholder="Password"
                  onChange={handleChange("password")}
                />
                <div className="leftform_icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M15.8333 9.16663H4.16667C3.24619 9.16663 2.5 9.91282 2.5 10.8333V16.6666C2.5 17.5871 3.24619 18.3333 4.16667 18.3333H15.8333C16.7538 18.3333 17.5 17.5871 17.5 16.6666V10.8333C17.5 9.91282 16.7538 9.16663 15.8333 9.16663Z"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.83337 9.16663V5.83329C5.83337 4.72822 6.27236 3.66842 7.05376 2.88701C7.83516 2.10561 8.89497 1.66663 10 1.66663C11.1051 1.66663 12.1649 2.10561 12.9463 2.88701C13.7277 3.66842 14.1667 4.72822 14.1667 5.83329V9.16663"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </FormGroup>

              <Link to="/forget-password" className="forgot_password">
                Forgot Password
              </Link>

              <Button className="btn_common w-100 text-uppercase" type="submit">
                Login
              </Button>
              <Button 
                  onClick={()=>loginWithWallet()}
                  className="btn_common w-100 mt-2 text-uppercase" 
                  type="button"
                >
                Login with metamask
              </Button>
              
            </Form>

            <div className="dont_have_account my-4">
              <p>
                Don’t have an account? <Link to="/sign-up">Sign up here </Link>
              </p>
            </div>
          </div>
        </Container>
      </section>
    </Fade>
  );
};

export default Signin;

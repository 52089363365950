import { ClickButton } from "Component/InputElements";
import React from "react";
import { Button, Modal } from "react-bootstrap";

function ConfirmMod({
  show,
  handlePop,
  handleConfirm,
  title,
  cancelTxt,
  confirmTxt,
  hideConfirmBtn = false,
  iconType = "",
  loading,
}) {
  return (
    <Modal show={show} onHide={handlePop} size="sm" centered>
      <Modal.Body>
        <Button
          onClick={handlePop}
          variant="transparent"
          className="border-0 p-0 closeBtn position-absolute"
          style={{ top: "10px", right: "10px" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
          >
            <path
              d="M8 18.75L12.5 14.25L17 18.75L18.75 17L14.25 12.5L18.75 8L17 6.25L12.5 10.75L8 6.25L6.25 8L10.75 12.5L6.25 17L8 18.75ZM12.5 25C10.7708 25 9.14583 24.6717 7.625 24.015C6.10417 23.3583 4.78125 22.4679 3.65625 21.3437C2.53125 20.2187 1.64083 18.8958 0.985 17.375C0.329167 15.8542 0.000833333 14.2292 0 12.5C0 10.7708 0.328333 9.14583 0.985 7.625C1.64167 6.10417 2.53208 4.78125 3.65625 3.65625C4.78125 2.53125 6.10417 1.64083 7.625 0.985C9.14583 0.329167 10.7708 0.000833333 12.5 0C14.2292 0 15.8542 0.328333 17.375 0.985C18.8958 1.64167 20.2187 2.53208 21.3437 3.65625C22.4687 4.78125 23.3596 6.10417 24.0162 7.625C24.6729 9.14583 25.0008 10.7708 25 12.5C25 14.2292 24.6717 15.8542 24.015 17.375C23.3583 18.8958 22.4679 20.2187 21.3437 21.3437C20.2187 22.4687 18.8958 23.3596 17.375 24.0162C15.8542 24.6729 14.2292 25.0008 12.5 25Z"
              fill="white"
            ></path>
          </svg>
        </Button>
        <div className="py-4 text-center">
          <div className="icn my-3">
            {iconType == "warning" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="70"
                height="70"
                viewBox="0 0 100 100"
                fill="none"
              >
                <path
                  d="M47.81 16.31C48.0242 15.9131 48.3418 15.5816 48.7291 15.3505C49.1164 15.1194 49.559 14.9974 50.01 14.9974C50.461 14.9974 50.9036 15.1194 51.2909 15.3505C51.6782 15.5816 51.9958 15.9131 52.21 16.31L84.71 76.31C84.9174 76.6911 85.022 77.1196 85.0136 77.5533C85.0053 77.9871 84.8841 78.4112 84.6622 78.784C84.4402 79.1567 84.1251 79.4653 83.7477 79.6794C83.3704 79.8935 82.9438 80.0057 82.51 80.005H17.5C17.0669 80.0052 16.6411 79.8929 16.2644 79.6791C15.8878 79.4653 15.5731 79.1573 15.3513 78.7852C15.1295 78.4132 15.0081 77.9899 14.9992 77.5569C14.9902 77.1239 15.0938 76.6959 15.3 76.315L47.81 16.31ZM56.6 13.93C53.765 8.69 46.25 8.69 43.415 13.93L10.905 73.93C10.2857 75.0722 9.9737 76.3556 9.99962 77.6546C10.0255 78.9536 10.3885 80.2236 11.0529 81.3402C11.7172 82.4567 12.6603 83.3815 13.7896 84.024C14.9189 84.6664 16.1957 85.0045 17.495 85.005H82.51C83.8097 85.0053 85.0871 84.6679 86.2171 84.0258C87.3471 83.3837 88.2909 82.459 88.9559 81.3423C89.6208 80.2256 89.9842 78.9553 90.0103 77.6559C90.0365 76.3564 89.7245 75.0725 89.105 73.93L56.605 13.93H56.6ZM52.5 37.5C52.5 36.837 52.2366 36.2011 51.7678 35.7322C51.2989 35.2634 50.663 35 50 35C49.337 35 48.7011 35.2634 48.2322 35.7322C47.7634 36.2011 47.5 36.837 47.5 37.5V57.5C47.5 58.163 47.7634 58.7989 48.2322 59.2678C48.7011 59.7366 49.337 60 50 60C50.663 60 51.2989 59.7366 51.7678 59.2678C52.2366 58.7989 52.5 58.163 52.5 57.5V37.5ZM53.75 68.75C53.75 69.7446 53.3549 70.6984 52.6516 71.4016C51.9484 72.1049 50.9946 72.5 50 72.5C49.0054 72.5 48.0516 72.1049 47.3484 71.4016C46.6451 70.6984 46.25 69.7446 46.25 68.75C46.25 67.7554 46.6451 66.8016 47.3484 66.0984C48.0516 65.3951 49.0054 65 50 65C50.9946 65 51.9484 65.3951 52.6516 66.0984C53.3549 66.8016 53.75 67.7554 53.75 68.75Z"
                  fill="#FF8A00"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="70"
                height="70"
                viewBox="0 0 100 100"
                fill="none"
              >
                <g clipPath="url(#clip0_1_2)">
                  <path
                    d="M50 0C22.3627 0 0 22.3646 0 50C0 77.6373 22.3646 100 50 100C77.6373 100 100 77.6354 100 50C100 22.3627 77.6354 0 50 0ZM50 92.1875C26.6811 92.1875 7.8125 73.3174 7.8125 50C7.8125 26.6811 26.6826 7.8125 50 7.8125C73.3189 7.8125 92.1875 26.6826 92.1875 50C92.1875 73.3189 73.3174 92.1875 50 92.1875Z"
                    fill="#F8C886"
                  />
                  <path
                    d="M50 25.1713C47.8426 25.1713 46.0938 26.9201 46.0938 29.0775V54.2324C46.0938 56.3898 47.8426 58.1386 50 58.1386C52.1574 58.1386 53.9062 56.3898 53.9062 54.2324V29.0775C53.9062 26.9201 52.1574 25.1713 50 25.1713Z"
                    fill="#F8C886"
                  />
                  <path
                    d="M50 73.4688C52.9124 73.4688 55.2734 71.1078 55.2734 68.1953C55.2734 65.2829 52.9124 62.9219 50 62.9219C47.0876 62.9219 44.7266 65.2829 44.7266 68.1953C44.7266 71.1078 47.0876 73.4688 50 73.4688Z"
                    fill="#F8C886"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1_2">
                    <rect width="100" height="100" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            )}
          </div>
          <h4 className="m-0 fw-sbold">{title || ""}</h4>
          {/* <p className="m-0 fw-light">
              Are you sure you want to delete wallet?
            </p> */}
        </div>
        <div className="btnWRp d-flex align-items-center justify-content-around ">
          <Button onClick={handlePop} className="btn-purchanse bg-gray">
            {cancelTxt || "Cancel"}
          </Button>
          {!hideConfirmBtn && (
            <ClickButton
              className="btn-purchanse"
              onClick={handleConfirm}
              disabled={loading}
              btnText={confirmTxt || "Confirm"}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmMod;

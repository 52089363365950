import React from "react";
import Headerlogin from "Component/Header/Headerlogin";
import Tradeexperts from "Pages/Profile/Tradeexperts";

export default function Tradeexpertstheme() {
  return (
    <>
       <Headerlogin heading="Dashboard"/>
       <Tradeexperts/>
    </>
  );
}

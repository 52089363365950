import React from "react";
import { Button } from "react-bootstrap";
import { Spinner } from "react-bootstrap";

function SubmitButton(props) {
  const { btnText, className, disabled } = props;
  return (
    <>
      <Button
        className={`  ${className ? className : ""}`}
        type="submit"
        disabled={disabled}
        
        // onClick={() => navigate("/phrase")}
      >
        <div className="d-flex align-center justify-center submit-btn">
          {disabled && (
            <div className="spinner">
              <Spinner color="primary" size="sm"/>
            </div>
          )}

          {btnText}
        </div>
      </Button>
    </>
  );
}

export default SubmitButton;

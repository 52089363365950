export const STAKE_AMOUNT = "STAKE_AMOUNT"
export const STAKE_AMOUNT_SUCCESSFUL = "STAKE_AMOUNT_SUCCESSFUL"
export const STAKE_AMOUNT_FAILED = "STAKE_AMOUNT_FAILED"

//Unstake Amount
export const UNSTAKE_AMOUNT = "UNSTAKE_AMOUNT"
export const UNSTAKE_AMOUNT_SUCCESSFUL = "UNSTAKE_AMOUNT_SUCCESSFUL"
export const UNSTAKE_AMOUNT_FAILED = "UNSTAKE_AMOUNT_FAILED"

// Stake Tokens
export const GET_STAKE_TOKENS = "GET_STAKE_TOKENS"
export const GET_STAKE_TOKENS_FAIL = "GET_STAKE_TOKENS_FAIL"
export const GET_STAKE_TOKENS_SUCCESS = "GET_STAKE_TOKENS_SUCCESS"

// Stake List
export const GET_STAKE_LIST = "GET_STAKE_LIST"
export const GET_STAKE_LIST_FAIL = "GET_STAKE_LIST_FAIL"
export const GET_STAKE_LIST_SUCCESS = "GET_STAKE_LIST_SUCCESS"

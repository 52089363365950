import {
  LOGIN_USER,
  LOGIN_OTP,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  GET_CURRENT_USER,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAIL,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  GET_LANGUAGES,
  GET_LANGUAGES_SUCCESS,
  GET_LANGUAGES_FAIL,
  GET_TFA_OTP,
  POST_TFA_VERIFY_OTP,
  POST_TFA_VALIDATE_OTP,
  POST_TFA_DISABLE_OTP,
  LOGIN_WALLET_REQUEST,
  LOGIN_WALLET_SUCCESS,
  LOGIN_WALLET_FAIL,
} from "./actionTypes";

export const loginUser = (user, navigate) => {
  return {
    type: LOGIN_USER,
    payload: { user, navigate },
  };
};

export const loginWithOtp = (user, navigate) => {
  return {
    type: LOGIN_OTP,
    payload: { user, navigate },
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const logoutUser = (history, role) => {
  return {
    type: LOGOUT_USER,
    payload: { history, role },
  };
};

export const currentUser = () => {
  return {
    type: GET_CURRENT_USER,
  };
};

export const currentUserSuccess = (user) => {
  return {
    type: GET_CURRENT_USER_SUCCESS,
    payload: user,
  };
};

export const currentUserFail = (error) => {
  return {
    type: GET_CURRENT_USER_FAIL,
    payload: error,
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  };
};

export const getLanguages = () => ({
  type: GET_LANGUAGES,
});

export const getLanguagesSuccess = (data) => ({
  type: GET_LANGUAGES_SUCCESS,
  payload: data,
});

export const getLanguagesFail = (error) => ({
  type: GET_LANGUAGES_FAIL,
  payload: error,
});

export const getTfaOtp = (callback) => ({
  type: GET_TFA_OTP,
  payload: { callback },
});

export const postTfaVerifyOtp = (data, callback) => ({
  type: POST_TFA_VERIFY_OTP,
  payload: { data, callback },
});

export const postTfaValidateOtp = (data, callback) => ({
  type: POST_TFA_VALIDATE_OTP,
  payload: { data, callback },
});

export const postTfaDisableOtp = (data, callback) => ({
  type: POST_TFA_DISABLE_OTP,
  payload: { data, callback },
});


export const loginWithWalletRequest = (data,callback) => {
  return {
    type: LOGIN_WALLET_REQUEST,
    payload: { data, callback }
  };
};


export const loginWithWalletSuccess = (user) => {
  return {
    type: LOGIN_WALLET_SUCCESS,
    payload: user,
  };
};


export const loginWithFail = (error) => {
  return {
    type: LOGIN_WALLET_FAIL,
    payload: error,
  };
};

import React from "react";
import Headerlogin from "Component/Header/Headerlogin";
import Buyplatform from "Pages/Profile/Buyplatform";

export default function Buyplatformtheme() {
  return (
    <>
       <Headerlogin heading="Dashboard"/>
       <Buyplatform/>
    </>
  );
}

import Web3 from "web3";
import { Contract, ethers } from "ethers";

// Constant
import {
  getNetworkUrl,
  getContractDetails,
  factoryAddress,
  factoryAbi,
  pairTokensAbi,
} from "../helpers/constants";

import routerAbi from "./routerAbi.json";
import tokenAbi from "./tokenAbi.json";
import usdtAbi from "./usdtAbi.json";

class Web3Intraction {
  constructor(blockchain, provider, settings) {
    const networkUrl = getNetworkUrl(blockchain || "ethereum", settings);

    if (provider) {
      this.PROVIDER = new ethers.providers.Web3Provider(
        provider,
        networkUrl
          ? { name: networkUrl.chainName, chainId: Number(networkUrl.chainId) }
          : "any"
      );

      this.SIGNER = this.PROVIDER.getSigner();
    } else if (networkUrl) {
      this.PROVIDER = new ethers.providers.JsonRpcProvider(
        networkUrl.url,
        networkUrl
          ? { name: networkUrl.chainName, chainId: Number(networkUrl.chainId) }
          : "any"
      );

      this.SIGNER = this.PROVIDER;
    }

    this.routerContractSetting = getContractDetails(
      blockchain || "ethereum",
      settings,
      "routerContract"
    );

    this.factoryContractSetting = getContractDetails(
      blockchain || "ethereum",
      settings,
      "factoryContract"
    );

    this.stakeContractSetting = getContractDetails(
      blockchain || "ethereum",
      settings,
      "stakeContract"
    );

    this.leverageTradingSetting = getContractDetails(
      blockchain || "ethereum",
      settings,
      "leverageTradingContract"
    );

    if (networkUrl) {
      this.web3 = new Web3(networkUrl.url);
    }

    /* this.WEBSOCKET_PROVIDER = new providers.WebSocketProvider(
      networkUrl.websocketUrl
    ); */

    this.settings = settings;
    this.networkUrl = networkUrl;
  }

  getNetworkUrl() {
    return this.networkUrl;
  }

  getTokenPairAddress = async (tokenAAddress, tokenBAddress) => {
    return new Promise(async (resolve, reject) => {
      try {
        const factoryContract = new Contract(
          factoryAddress,
          factoryAbi,
          this.PROVIDER
        );
        const pairAddress = await factoryContract.getPair(
          tokenAAddress,
          tokenBAddress
        );
        resolve(pairAddress);
      } catch (error) {
        reject(error);
      }
    });
  };

  getNetworkUrl() {
    return this.networkUrl;
  }

  async getTokensPriceAndPoolShare(tokenPairAddress) {
    return new Promise(async (resolve, reject) => {
      try {
        const pairContract = new Contract(
          tokenPairAddress,
          pairTokensAbi,
          this.PROVIDER
        );

        const [token0, token1] = await Promise.all([
          pairContract.token0(),
          pairContract.token1(),
        ]);

        const reserves = await pairContract.getReserves();
        const reserve0 = reserves[0];
        const reserve1 = reserves[1];

        const token0Contract = new Contract(
          token0,
          ["function decimals() view returns (uint8)"],
          this.PROVIDER
        );
        const token1Contract = new Contract(
          token1,
          ["function decimals() view returns (uint8)"],
          this.PROVIDER
        );

        const [decimals0, decimals1] = await Promise.all([
          token0Contract.decimals(),
          token1Contract.decimals(),
        ]);

        let price;
        if (Number(decimals1) > Number(decimals0)) {
          price =
            Number(Number(reserve1) / 10 ** Number(decimals1)) /
            Number(Number(reserve0) / 10 ** Number(decimals0));
        } else {
          price =
            Number(Number(reserve0) / 10 ** Number(decimals0)) /
            Number(Number(reserve1) / 10 ** Number(decimals1));
        }
        // const price = reserve1
        //   .mul(ethers.BigNumber.from(10).pow(decimals0))
        //   .div(reserve0);
        const poolShare0 = reserve0.div(
          ethers.BigNumber.from(10).pow(decimals0)
        );
        const poolShare1 = reserve1.div(
          ethers.BigNumber.from(10).pow(decimals1)
        );

        const data = {
          price: price,
          poolShare0: poolShare0.toString(),
          poolShare1: poolShare1.toString(),
        };
        resolve(data);
      } catch (err) {
        reject(err);
      }
    });
    // const tokenPairAbi = [...]; // Replace with actual ABI
  }

  addLiquidity = async (params, callback = () => null) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          tokenA,
          tokenB,
          amountADesired,
          amountBDesired,
          amountAMin,
          amountBMin,
          toAddress,
          deadline,
        } = params;
        // Address of the Uniswap V2 Router contract
        const routerAddress = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";

        // Create a contract instance of the Uniswap V2 Router
        const routerContract = this.getContract(
          routerAbi,
          routerAddress,
          this.SIGNER
        );

        // Add liquidity
        // Approve tokens for spending by the router
        const tokenAContract = new Contract(tokenA, tokenAbi, this.SIGNER);
        const tokenBContract = new Contract(tokenB, tokenAbi, this.SIGNER);

        let tokenAAllowence = await tokenAContract.allowance(
          toAddress,
          routerAddress
        );
        let tokenBAllowence = await tokenBContract.allowance(
          toAddress,
          routerAddress
        );
        tokenAAllowence = tokenAAllowence.toString();
        tokenBAllowence = tokenBAllowence.toString();

        if (amountADesired > tokenAAllowence) {
          const trans = await tokenAContract.approve(
            routerAddress,
            amountADesired
          );
          await trans.wait();
        }
        if (amountBDesired > tokenBAllowence) {
          const trans = await tokenBContract.approve(
            routerAddress,
            amountBDesired
          );
          await trans.wait();
        }

        const tx = await routerContract.addLiquidity(
          Web3.utils.toChecksumAddress(tokenA),
          Web3.utils.toChecksumAddress(tokenB),
          amountADesired.toString(),
          amountBDesired.toString(),
          Math.floor(amountAMin).toString(),
          amountBMin.toString(),
          Web3.utils.toChecksumAddress(toAddress),
          deadline,
          {
            gasLimit: 300000,
          }
        );
        // tx.wait();
        let receipt = await tx.wait();
        if (receipt?.status) {
          resolve({ txHash: tx.hash, ...receipt });
          return { status: "success" };
        } else {
          reject("Transaction failed!");
          return { status: "failed" };
        }
      } catch (e) {
        reject("Transaction failed!");
        return { status: "failed" };
      }
    });
  };

  getTokenPrice = async (
    tokenAddress,
    userWallet,
    decimals = 0,
    callback = () => null
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const tokenContract = this.getContract(tokenAbi, tokenAddress);
        let tokenBalance = await tokenContract.balanceOf(userWallet);
        if (decimals && Number(decimals) != 0) {
          tokenBalance =
            Number(tokenBalance.toString()) / 10 ** Number(decimals);
        } else {
          tokenBalance = convertPriceToEther(tokenBalance);
        }
        resolve(tokenBalance);
      } catch (e) {
        console.log("e", e);
        reject("Get balance error");
        return { status: "failed" };
      }
    });
  };
  getBalance = async (userWallet, callback = () => null) => {
    return new Promise(async (resolve, reject) => {
      try {
        var balance = await this.PROVIDER.getBalance(userWallet); //Will give value in.
        balance = ethers.utils.formatEther(balance.toString());
        resolve(balance);
      } catch (e) {
        console.log("e", e);
        reject("Get balance error");
        return { status: "failed" };
      }
    });
  };

  /**
   * Get contract from abi and address
   *
   * @param {string} abi - ABI JSON
   * @param {string} address - Contract Address
   *
   * @returns {object} Contract
   */
  getContract = (abi, address) => {
    try {
      let signer = this.SIGNER;

      if (!this.SIGNER) {
        signer = this.PROVIDER.getSigner();
      }

      let contract = new Contract(address, abi, signer);
      return contract;
    } catch (error) {
      console.log("error", error);
      return null;
    }
  };

  stake = async (params, callback = () => null) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { tokenAmount, tokenAddress, decimals, reqId, userWallet } =
          params;

        // Create a contract instance of the Stake contract
        const stakeContract = this.getContract(
          this.stakeContractSetting?.abi,
          this.stakeContractSetting?.contractAddress,
          this.SIGNER
        );

        const tokenContract = new ethers.Contract(
          tokenAddress,
          tokenAbi,
          this.SIGNER
        );

        let tokenAllowence = await tokenContract.allowance(
          userWallet,
          this.stakeContractSetting?.contractAddress
        );
        tokenAllowence = tokenAllowence.toString();
        const tokenAmountWithDecimal =
          Number(tokenAmount) * 10 ** Number(decimals);
        if (Number(tokenAmountWithDecimal) > tokenAllowence) {
          const txn = await tokenContract.increaseAllowance(
            this.stakeContractSetting?.contractAddress,
            tokenAmountWithDecimal
          );
          await txn.wait();
        }
        const tx = await stakeContract.Stake(
          Web3.utils.toChecksumAddress(tokenAddress),
          tokenAmountWithDecimal,
          reqId
        );
        let receipt = await tx.wait();
        resolve({ txHash: tx.hash, ...receipt });
      } catch (e) {
        reject(e);
      }
    });
  };
  unstake = async (reqId, callback = () => null) => {
    return new Promise(async (resolve, reject) => {
      try {
        // Create a contract instance of the Stake contract
        const stakeContract = this.getContract(
          this.stakeContractSetting?.abi,
          this.stakeContractSetting?.contractAddress,
          this.SIGNER
        );

        const tx = await stakeContract.Unstake(Number(reqId));
        let receipt = await tx.wait();
        resolve({ txHash: tx.hash, ...receipt });
      } catch (e) {
        reject(e);
      }
    });
  };

  getTokenBalance = (usdtAddress, poolAddress) =>
    new Promise(async (resolve, reject) => {
      try {
        const tokenContract = new Contract(usdtAddress, usdtAbi, this.SIGNER);
        const tokenDecimals = await tokenContract.decimals();

        const balance = await tokenContract.balanceOf(poolAddress);

        resolve(ethers.utils.formatUnits(balance, tokenDecimals));
      } catch (error) {
        reject(error);
      }
    });

  checkAllowance = (params) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { tokenAddress, tradeContractAddress } = params;

        const tokenContract = new Contract(tokenAddress, usdtAbi, this.SIGNER);

        const tokenDecimals = await tokenContract.decimals();

        const marginAmountWei = ethers.utils.parseUnits(
          Number(params.marginAmount).toFixed(tokenDecimals),
          Number(tokenDecimals)
        );

        const allowance = await tokenContract.allowance(
          await this.SIGNER.getAddress(),
          tradeContractAddress
        );

        if (Number(allowance) < Number(marginAmountWei)) {
          const balance = await tokenContract.balanceOf(
            await this.SIGNER.getAddress()
          );

          const txn = await tokenContract.approve(
            tradeContractAddress,
            balance
          );
          await txn.wait();
        }

        resolve();
      } catch (e) {
        console.log(e, "<===err in openPosition");
        reject(e);
      }
    });
  };

  openPosition = (params, type) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          reqId,
          marginAmount,
          underlyingAssets,
          leverage,
          liquidationUnderlyingAssest,
          side,
          tokenAddress,
          tradeContractAddress,
        } = params;

        // Create a contract instance of the Leverage Trading contract
        const leverageTradingContract = this.getContract(
          JSON.parse(this.leverageTradingSetting?.abi),
          tradeContractAddress,
          this.SIGNER
        );

        const tokenContract = new Contract(tokenAddress, usdtAbi, this.SIGNER);
        const tokenDecimals = await tokenContract.decimals();

        const marginAmountWei = ethers.utils.parseUnits(
          Number(marginAmount).toFixed(tokenDecimals),
          tokenDecimals
        );

        const underlyingAssetsWei = ethers.utils.parseUnits(
          underlyingAssets.toFixed(18),
          18
        );
        const liquidationUnderlyingAssestWei = ethers.utils.parseUnits(
          Number(liquidationUnderlyingAssest).toFixed(tokenDecimals),
          tokenDecimals
        );

        let tx = null;
        if (type == "edit") {
          // tx = await leverageTradingContract.updatePostion(
          //   marginAmountWei,
          //   reqId,
          //   params.ticker
          // );
        } else {
          tx = await leverageTradingContract.createOrder(
            reqId,
            marginAmountWei, // deposit amount
            underlyingAssetsWei, // underlying Assets
            leverage, // leverage
            liquidationUnderlyingAssestWei, // Liquidation Underlying Asset,
            side, // Side
            0 // Order Type // 0 => Market, 1 => Limit
          );
        }

        let receipt = await tx.wait();
        resolve({ txHash: tx.hash, ...receipt });
      } catch (e) {
        console.log(e, "<===err in openPosition");
        reject(e);
      }
    });
  };

  closePosition = async (params, callback = () => null) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { amountToTransfer, reqId, decimals } = params;
        // Create a contract instance of the Leverage Trading contract
        const leverageTradingContract = this.getContract(
          JSON.parse(this.leverageTradingSetting?.abi),
          this.leverageTradingSetting?.contractAddress,
          this.SIGNER
        );

        // const transferTokens = parseInt(
        //   Number(amountToTransfer) * 10 ** Number(decimals)
        // );

        const transferTokens =
          Number(decimals) != 18
            ? Number(amountToTransfer) * 10 ** decimals
            : convertPriceToEther(amountToTransfer);
        const tx = await leverageTradingContract.ClosePositions(
          reqId,
          transferTokens
        );
        let receipt = await tx.wait();
        resolve({ txHash: tx.hash, ...receipt });
      } catch (e) {
        console.log(e, "error");
        reject(e);
      }
    });
  };
}

export default Web3Intraction;

export const convertPriceToEther = (price) => {
  return ethers.utils.parseEther(price?.toString())._hex;

  // return Web3.utils.toWei(Number(price).toFixed(8), "ether")
};
export const convertToHex = (value) => ethers.utils.hexlify(parseInt(value));

export const convertHexToString = (hex) => {
  return Web3.utils.hexToNumberString(hex);
};

export const convertNumberToHex = (number) => {
  return Web3.utils.numberToHex(Number(number));
};
export const convertToDecimal = (value, decimal) => {
  return ethers.utils.parseUnits(value, decimal);
};
export const formatEther = (value) => {
  return ethers.utils.formatEther(value);
};

export const convertToWei = (number) => Web3.utils.toWei(number);
export const convertFromWei = (number, unit) =>
  Web3.utils.fromWei(number, unit || "ether");

import * as CONST from "./actionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  transactions: [],
  total: 0,
  AUDLoading: false
};

const Transaction = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case CONST.GET_TRANSACTION:
      return {
        ...state,
        loading: action.payload.loading,
      };

    case CONST.GET_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactions: action.payload.data || [],
        total: action.payload.totalCount || 0,
        loading: false,
        error: "",
      };

    case CONST.GET_TRANSACTION_FAIL:
      return {
        ...state,
        transactions: [],
        loading: false,
        error: "",
      };
    case CONST.ADD_TRANSACTION:
      return {
        ...state,
        AUDLoading: true,
      };

    case CONST.ADD_TRANSACTION_SUCCESS:
      return {
        ...state,
        AUDLoading: false,
        error: "",
      };

    case CONST.ADD_TRANSACTION_FAIL:
      return {
        ...state,
        AUDLoading: false,
        error: "",
      };

    case CONST.ADD_INVESTMENT:
      return {
        ...state,
        AUDLoading: true,
      };

    case CONST.ADD_INVESTMENT_SUCCESS:
      return {
        ...state,
        AUDLoading: false,
        error: "",
      };

    case CONST.ADD_INVESTMENT_FAIL:
      return {
        ...state,
        AUDLoading: false,
        error: "",
      };
    default:
      return state;
  }
};

export default Transaction;

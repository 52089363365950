import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Alert
import Alert from "./Alert/reducer";

// Authentication
import Login from "./auth/login/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import Register from "./auth/register/reducer";
import Verify from "./auth/verify/reducer";
import SendOtp from "./auth/sendOtp/reducer";
import Crypto from "./crypto/reducers";
import File from "./file/reducer";
import Settings from "./Settings/reducer";


import Transaction from "./transaction/reducer";
import Stake from "./stake/reducer";
import Order from "./order/reducer";
import Expert from "./expert/reducer";
import Blogs from "./blogs/reducer";
import Bot from "./bot/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Alert,
  Login,
  ForgetPassword,
  Profile,
  Register,
  Verify,
  SendOtp,
  Crypto,
  File,
  Settings,
  Transaction,
  Stake,
  Expert,
  Order,
  Blogs,
  Bot
});

export default rootReducer;

import React, { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import Modal from "react-bootstrap/Modal";
import toastr from "toastr";

//store
import { addBlog, getBlogs, putBlog, uploadFileRequest } from "store/actions";

//Component
import { SubmitButton } from "Component/InputElements";

export default function Bloguploadmodal(props) {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.Blogs);
  const { user } = useSelector((state) => state.Login);

  const [droppedImage, setDroppedImage] = useState(null);
  const [fields, setFields] = useState({
    blogImage: null,
    title: "",
    content: "",
  });

  const uploadFileSuccess = (response) => {
    const { _id } = response.data;

    setFields((pre) => ({ ...pre, blogImage: _id }));
  };

  const onUploadDocFile = (file) => {
    const ext = file.name.split(".").pop();
    //checking extension
    if (!["png", "jpg", "jpeg"].includes(ext)) {
      toastr.error("Only .png,.jpg,.jpeg  extension allow!!");
      return;
    }

    if (file?.size >= 5242880) {
      toastr.error("Maximum upload file size: 5MB");
      return;
    }

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setDroppedImage(event.target.result);
      };
      reader.readAsDataURL(file);
      dispatch(uploadFileRequest({ image: file }, uploadFileSuccess));
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFields((pre) => ({
      ...pre,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!props.editData && !fields.blogImage)
      return toastr.error("Image Required!");
    const callback = (success, res) => {
      if (!success) return toastr.error(res.message);
      else {
        props.onhide();
        dispatch(
          getBlogs({
            userId: user?._id,
          })
        );
        setFields((pre) => ({
          blogImage: null,
          title: "",
          content: "",
        }));
        setDroppedImage(null);
      }
    };
    props.editData
      ? dispatch(
          putBlog(
            {
              _id: fields._id,
              title: fields.title,
              content: fields.content,
            },
            callback
          )
        )
      : dispatch(addBlog(fields, callback));
  };

  useEffect(() => {
    if (props.editData) {
      setFields((pre) => ({
        ...pre,
        _id: props.editData._id,
        title: props.editData.title,
        content: props.editData.content,
      }));
    } else {
      setDroppedImage(null);

      setFields((pre) => ({
        ...pre,
        blogImage: null,
        title: "",
        content: "",
      }));
    }
  }, [props.editData]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add_card_mod blog_modal"
    >
      <Modal.Body>
        <div className="modal_connect position-relative">
          <a
            href="javascript:void(0)"
            className="close-modal"
            onClick={props.onhide}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 2.25C6.624 2.25 2.25 6.624 2.25 12C2.25 17.376 6.624 21.75 12 21.75C17.376 21.75 21.75 17.376 21.75 12C21.75 6.624 17.376 2.25 12 2.25ZM12 3.75C16.5653 3.75 20.25 7.43475 20.25 12C20.25 16.5653 16.5653 20.25 12 20.25C7.43475 20.25 3.75 16.5653 3.75 12C3.75 7.43475 7.43475 3.75 12 3.75ZM9.165 8.085L8.085 9.165L10.923 12L8.0865 14.835L9.1665 15.915L12 13.0778L14.835 15.9127L15.915 14.835L13.0778 12L15.9127 9.165L14.835 8.085L12 10.923L9.165 8.0865V8.085Z"
                fill="#EA3943"
              />
            </svg>
          </a>

          <div className="blog_modal_inner">
            <Form onSubmit={handleSubmit}>
              <h6>Post Upload</h6>
              <Row>
                {!props.editData && (
                  <Col lg={12}>
                    <Dropzone onDrop={(file) => onUploadDocFile(file[0])}>
                      {({ getRootProps, getInputProps }) => (
                        <section className="dropzone_drop">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />

                            <div className="wraapingdroping">
                              <div className="drop_img">
                                {droppedImage ? (
                                  <img src={droppedImage} alt="Dropped" />
                                ) : (
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M5 16.2499C5.19891 16.2499 5.38968 16.3289 5.53033 16.4696C5.67098 16.6102 5.75 16.801 5.75 16.9999V18.9999C5.75 19.1379 5.862 19.2499 6 19.2499H18C18.0663 19.2499 18.1299 19.2236 18.1768 19.1767C18.2237 19.1298 18.25 19.0662 18.25 18.9999V16.9999C18.25 16.801 18.329 16.6102 18.4697 16.4696C18.6103 16.3289 18.8011 16.2499 19 16.2499C19.1989 16.2499 19.3897 16.3289 19.5303 16.4696C19.671 16.6102 19.75 16.801 19.75 16.9999V18.9999C19.75 19.464 19.5656 19.9092 19.2374 20.2373C18.9092 20.5655 18.4641 20.7499 18 20.7499H6C5.53587 20.7499 5.09075 20.5655 4.76256 20.2373C4.43437 19.9092 4.25 19.464 4.25 18.9999V16.9999C4.25 16.801 4.32902 16.6102 4.46967 16.4696C4.61032 16.3289 4.80109 16.2499 5 16.2499ZM10.738 16.1269C10.4898 16.1271 10.2506 16.0343 10.0675 15.8667C9.88441 15.6992 9.77076 15.4691 9.749 15.2219C9.59578 13.4694 9.56904 11.7082 9.669 9.95191C9.42186 9.93808 9.17485 9.92208 8.928 9.90391L7.438 9.79491C7.30678 9.78529 7.1803 9.74177 7.07094 9.66861C6.96158 9.59545 6.87309 9.49514 6.81413 9.37752C6.75516 9.2599 6.72775 9.12898 6.73456 8.99758C6.74138 8.86618 6.78219 8.7388 6.853 8.62791C7.91489 6.96578 9.2832 5.52077 10.885 4.36991L11.482 3.94091C11.633 3.83249 11.8141 3.77417 12 3.77417C12.1859 3.77417 12.367 3.83249 12.518 3.94091L13.115 4.37091C14.7167 5.5215 16.085 6.96616 17.147 8.62791C17.2178 8.7388 17.2586 8.86618 17.2654 8.99758C17.2723 9.12898 17.2448 9.2599 17.1859 9.37752C17.1269 9.49514 17.0384 9.59545 16.9291 9.66861C16.8197 9.74177 16.6932 9.78529 16.562 9.79491L15.072 9.90391C14.826 9.92191 14.579 9.93791 14.332 9.95091C14.432 11.7079 14.404 13.4689 14.25 15.2209C14.2285 15.4681 14.1151 15.6983 13.9322 15.866C13.7493 16.0338 13.5102 16.1268 13.262 16.1269H10.738Z"
                                        fill="white"
                                      />
                                    </svg>
                                    <p>Drop Your Post Image Here</p>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </Col>
                )}
                <Col lg={12} className="mt-2">
                  <FormGroup>
                    <Label for="titleid">Title</Label>
                    <Input
                      type="text"
                      name="title"
                      id="titleid"
                      value={fields.title}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg={12}>
                  <FormGroup>
                    <Label for="blogid">Post</Label>
                    <Input
                      type="textarea"
                      name="content"
                      id="blogid"
                      value={fields.content}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                </Col>
                <div className="button_done text-end">
                  <SubmitButton
                    btnText={"Done"}
                    className="btn-purchanse"
                    disabled={loading}
                  />
                  {/* <Button >Done</Button> */}
                </div>
              </Row>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

import React, { useState } from "react";
import toastr from "toastr";
import { Fade } from "react-reveal";
import Settingtab from "./settingtab";

import { LuPencil } from "react-icons/lu";
import { AiFillCamera } from "react-icons/ai";

import { useSelector, useDispatch } from "react-redux";
import { editProfile, uploadFileRequest } from "store/actions";

export default function Dashsetting() {
  const dispatch = useDispatch();
  const { user } = useSelector(({ Login }) => Login);

  const userbyDefault = "/images/editprofile.png";
  const [file, setFile] = useState(userbyDefault);
  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const uploadFileSuccess = (name) => (response) => {
    const { _id } = response.data;
    if (name === "profileImage") {
      dispatch(editProfile({ profileImage: _id }, () => {}));
    }
    if (name === "bannerImage") {
      dispatch(editProfile({ bannerImage: _id }, () => {}));
    }
  };

  const onUploadDocFile = (name, file) => {
    const ext = file.name.split(".").pop();

    //checking extension
    if (!["png", "jpg", "jpeg"].includes(ext)) {
      toastr.error("Only .png,.jpg,.jpeg  extension allow!!");
      return;
    }

    if (file?.size >= 5242880) {
      toastr.error("Maximum upload file size: 5MB");
      return;
    }

    if (file) {
      dispatch(uploadFileRequest({ image: file }, uploadFileSuccess(name)));
    }
  };

  return (
    <Fade>
      <section className="dashetting">
        <div className="setting_head_wrap">
          <div className="seeting_head">
            <h5 className="heading-comp">Profile</h5>
          </div>
        </div>

        <div className="setting-wrap">
          <div className="cover_img_here position-relative">
            <div className="cover_wrap">
              <img src={user?.bannerImage?.link} alt="" className="img-fluid" />
            </div>

            <label for="upload1" className="edit_cover">
              <AiFillCamera /> Edit Cover
            </label>
            <input
              type="file"
              onChange={(e) =>
                onUploadDocFile("bannerImage", e.target.files[0])
              }
              id="upload1"
              style={{ display: "none" }}
            />
          </div>

          <div className="profile_edit_part">
            <div className="edit_left">
              <div className="user_img_prfile position-relative">
                <img
                  src={user?.profileImage?.link || file}
                  alt="User Profile"
                />

                <label for="upload2">
                  <LuPencil />
                </label>
                <input
                  type="file"
                  onChange={(e) =>
                    onUploadDocFile("profileImage", e.target.files[0])
                  }
                  id="upload2"
                  style={{ display: "none" }}
                />
              </div>

              <div className="user_img_content">
                <h4>{user?.username}</h4>
                <p>{user?.email}</p>
                <p>{user?.countryCode + " " + user?.mobileNumber}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="setting_tab mt-4">
          <Settingtab  user={user}/>
        </div>
      </section>
    </Fade>
  );
}

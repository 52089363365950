import React from "react";
import Headerlogin from "Component/Header/Headerlogin";
import Staking from "Pages/Profile/Staking";

export default function Stakingtheme() {
  return (
    <>
       <Headerlogin heading="Staking" />
       <Staking/> 
    </>
  );
}

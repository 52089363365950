import * as CONST from "./actionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  experts: [],
  total: 0,
};

const EXPERT = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case CONST.GET_EXPERT:
      return {
        ...state,
        loading: action.payload.loading,
      };

    case CONST.GET_EXPERT_SUCCESS:
      return {
        ...state,
        experts: action.payload.data || [],
        total: action.payload.totalCount || 0,
        loading: false,
        error: "",
      };

    case CONST.GET_EXPERT_FAIL:
      return {
        ...state,
        experts: [],
        loading: false,
        error: "",
      };
    
    default:
      return state;
  }
};

export default EXPERT;

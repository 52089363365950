import { call, put, takeEvery } from "redux-saga/effects"

// Customer Redux States
import { UPLOAD_FILE, REMOVE_FILE, UPLOAD_FILE_REQUEST } from "./actionTypes"
import { uploadFileFail, uploadFileSuccess } from "./actions"

import { postFile, postRemoveFile } from "helpers/backend_helper"

function* onAddFile({ payload: { data, callback } }) {
  try {
    const response = yield call(postFile, data)

    if (response.status == "failure") {
      return yield put(uploadFileFail(response.message))
    }
    yield put(uploadFileSuccess(response))
    callback && callback(response)

  } catch (error) {
    yield put(uploadFileFail(error))
  }
}

function* onRemoveFile({ payload }) {
  try {
    const response = yield call(postRemoveFile, payload)

    if (response.status == "failure") {
      return yield put(uploadFileFail(response.message))
    }
  } catch (error) {
    yield put(uploadFileFail(error))
  }
}

function* fileSaga() {
  yield takeEvery(UPLOAD_FILE_REQUEST, onAddFile)
  yield takeEvery(REMOVE_FILE, onRemoveFile)
}

export default fileSaga

import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const Pagenotfound = () => {
    const navigate = useNavigate();
  return (
    <section className='errorpage'>
      <Container>
          <div className="erro404page">
               <h1 className='text-white'>Page not found</h1>
               <Button className="big-add-token" onClick={() => navigate("/")}><p>Back to Home</p></Button>
          </div>
      </Container>    
    </section>
  )
}

export default Pagenotfound
import React from "react";
import Verifyfeild from "Pages/Verifyfeild";

export default function Verifyfeildtheme() {
  return (
    <>
     <Verifyfeild/>
    </>
  );
}

import React from "react";
import { Container } from "react-bootstrap";
import Tradeexpertschart from "./Tradeexpertschart";

const Tradeexperts = () => {
  return (
    <section className="trade_new my-3">
      <Container fluid>
        
        <Tradeexpertschart heading="Trading Experts Profiles"/>
      </Container>
    </section>
  );
};

export default Tradeexperts;

import React from "react";
import { FundsContext } from "utils/funds";

export default function useFunds() {
  const context = React.useContext(FundsContext);

  if (context === undefined) {
    throw new Error(
      "useFunds hook must be used with a WalletProvider component"
    );
  }

  return context;
}

import * as TYPES from "./actionTypes";

/* Get  Settings */
export const getSettings = (data) => ({
  type: TYPES.GET_SETTINGS,
  payload: data,
});

export const getSettingsSuccess = (data) => ({
  type: TYPES.GET_SETTINGS_SUCCESS,
  payload: data,
});

export const getSettingsFail = (error) => ({
  type: TYPES.GET_SETTINGS_FAIL,
  payload: error,
});


import { call, put, takeEvery } from "redux-saga/effects";

import * as CONST from "./actionTypes";
import * as act from "./actions";
import * as apiCall from "helpers/backend_helper";

function* fetchBlogs({ payload }) {
  try {
    const response = yield call(apiCall.getBlogs, payload);
    if (response.status == "failure") {
      return yield put(act.getBlogsFail(response.message));
    }

    yield put(act.getBlogsSuccess(response));
  } catch (error) {
    yield put(act.getBlogsFail(error));
  }
}

function* onAddNewBlog({ payload: { item, callback } }) {
  try {
    const response = yield call(apiCall.postBlog, item);

    if (response.status == "failure") {
      window.scrollTo(0, 0);
      callback && callback(false, response);

      return yield put(act.addBlogFail(response.message));
    }

    callback && callback(true, response);

    yield put(act.addBlogSuccess());
  } catch (error) {
    yield put(act.addBlogFail("Internal Error!"));
  }
}

function* fetchBlog({ payload }) {
  try {
    const response = yield call(apiCall.getBlog, payload);

    if (response.status == "failure") {
      return yield put(act.getBlogFail(response.message));
    }

    yield put(act.getBlogSuccess(response.data));
  } catch (error) {
    yield put(act.getBlogFail(error));
  }
}

function* onPutBlog({ payload: { data, callback } }) {
  try {
    const response = yield call(apiCall.putBlog, data);

    if (response.status == "failure") {
    callback && callback(false, response);

      return yield put(act.putBlogFail(response.message));
    }

    callback && callback(true, response);

    yield put(act.putBlogSuccess());
  } catch (error) {
    yield put(act.putBlogFail("Internal Error!"));
  }
}

function* onDeleteBlog({ payload: { data, callback } }) {
  try {
    const response = yield call(apiCall.deleteBlog, data);

    if (response.status == "failure") {
    callback && callback(false, response);

      return yield put(act.deleteBlogFail(response.message));
    }
    callback && callback(true, response);

    yield put(act.deleteBlogSuccess());
  } catch (error) {
    yield put(act.deleteBlogFail("Internal Error!"));
  }
}

function* BlogsSaga() {
  yield takeEvery(CONST.GET_BLOGS, fetchBlogs);
  yield takeEvery(CONST.ADD_BLOG, onAddNewBlog);
  yield takeEvery(CONST.GET_BLOG, fetchBlog);
  yield takeEvery(CONST.GET_BLOG, fetchBlog);
  yield takeEvery(CONST.PUT_BLOG, onPutBlog);
  yield takeEvery(CONST.DELETE_BLOG, onDeleteBlog);
}

export default BlogsSaga;

import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import { sendOtp, verifyOtp } from "store/actions";

const Verifyfeild = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.Register);

  const [userDetails, setUserDetails] = useState({});
  const [emailOtp, setEmailOtp] = useState("");
  const [mobileOtp, setMobileOtp] = useState("");
  const [isVerify, setisVerify] = useState({
    email: false,
    mobile: false,
  });

  useEffect(() => {
    setUserDetails(user);
  }, [user]);

  const verifyEmail = (e) => {
    e.preventDefault();
    if (!userDetails) {
      return navigate("/sign-up");
    }
    if (emailOtp == "") {
      return toastr.error("Email Otp is required!");
    }
    if (userDetails && userDetails.emailVerified) {
      return toastr.error("Email already verified");
    }
    const callback = (response) => {
      if (response.status == "success") {
        setisVerify((pre) => ({
          ...pre,
          email: true,
        }));
        toastr.success("Email verified successfully!");
        if (response.data.emailVerified && response.data.mobileVerified) {
          return navigate("/sign-in");
        }
        return;
      } else {
        return toastr.error(response.message);
      }
    };
    dispatch(
      verifyOtp(
        { type: "email", email: userDetails.email, OTP: emailOtp },
        callback
      )
    );
  };
  const verifyMobile = (e) => {
    e.preventDefault();
    if (!userDetails) {
      return navigate("/sign-up");
    }
    if (mobileOtp == "") {
      return toastr.error("Mobile Otp is required!");
    }
    if (userDetails && userDetails.mobileVerified) {
      return toastr.error("Mobile number already verified");
    }
    const callback = (response) => {
      if (response.status == "success") {
        setisVerify((pre) => ({
          ...pre,
          mobile: true,
        }));
        toastr.success("Mobile Number verified successfully!");
        if (response.data.emailVerified && response.data.mobileVerified) {
          return navigate("/sign-in");
        }
        return;
      } else {
        return toastr.error(response.message);
      }
    };
    dispatch(
      verifyOtp(
        {
          type: "number",
          countryCode: userDetails.countryCode,
          mobileNumber: userDetails.mobileNumber,
          OTP: mobileOtp,
          email: userDetails.email,
        },
        callback
      )
    );
  };
  const sendEmailOtp = (e) => {
    e.preventDefault();
    if (!userDetails) {
      return navigate("/sign-up");
    }
    const callback = (response) => {
      if (response.status == "success") {
        return toastr.success(
          `One time password send to your mail(${userDetails.email}) successfully`
        );
      } else {
        return toastr.error(response.message);
      }
    };
    dispatch(sendOtp({ type: "email", email: userDetails.email }, callback));
  };
  const sendMobileOtp = (e) => {
    e.preventDefault();
    if (!userDetails) {
      return navigate("/sign-up");
    }
    const callback = (response) => {
      if (response.status == "success") {
        return toastr.success(
          `One time password send to your mobile(${userDetails.countryCode} ${userDetails.mobileNumber}) successfully`
        );
      } else {
        return toastr.error(response.message);
      }
    };
    dispatch(
      sendOtp(
        {
          type: "number",
          countryCode: userDetails.countryCode,
          mobileNumber: userDetails.mobileNumber,
          email: userDetails.email,
        },
        callback
      )
    );
  };
  return (
    <Fade>
      <section className="signup_main common_sign_main otp_verify">
        <Container>
          <div className="sign_up_inner otp_inner">
            <div className="logo_form mb-4">
              <img width={"150px"} src="/images/logo.png" alt="" className="img-fluid" />
            </div>
            <h2 className="text-center Signup_head">
              Email and Mobile Verification
            </h2>

            <Form className="signup_form">
              {!userDetails?.emailVerified && (
                <>
                  {!isVerify.email && (
                    <FormGroup className="position-relative d-flex align-items-center gap-2">
                      <span className="otp_input_wrapping position-relative">
                        <Input
                          type="number"
                          id="exampleEmail"
                          placeholder="Verify Email"
                          onChange={(e) => setEmailOtp(e.target.value)}
                        />
                        <div className="leftform_icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M4.16663 4.16663H15C15.663 4.16663 16.2989 4.43002 16.7677 4.89886C17.2366 5.3677 17.5 6.00358 17.5 6.66663V14.1666C17.5 14.8297 17.2366 15.4656 16.7677 15.9344C16.2989 16.4032 15.663 16.6666 15 16.6666H4.16663C3.50358 16.6666 2.8677 16.4032 2.39886 15.9344C1.93002 15.4656 1.66663 14.8297 1.66663 14.1666V6.66663C1.66663 6.00358 1.93002 5.3677 2.39886 4.89886C2.8677 4.43002 3.50358 4.16663 4.16663 4.16663ZM4.16663 4.99996C3.74996 4.99996 3.38329 5.14163 3.09996 5.39163L9.58329 9.58329L16.0666 5.39163C15.7833 5.14163 15.4166 4.99996 15 4.99996H4.16663ZM9.58329 10.5916L2.60829 6.06663C2.54163 6.24996 2.49996 6.45829 2.49996 6.66663V14.1666C2.49996 14.6087 2.67555 15.0326 2.98811 15.3451C3.30068 15.6577 3.7246 15.8333 4.16663 15.8333H15C15.442 15.8333 15.8659 15.6577 16.1785 15.3451C16.491 15.0326 16.6666 14.6087 16.6666 14.1666V6.66663C16.6666 6.45829 16.625 6.24996 16.5583 6.06663L9.58329 10.5916Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </span>

                      <p className="wraaping_otp_button d-flex align-items-center gap-2 m-0">
                        <Button
                          className="btn_common w-100"
                          onClick={verifyEmail}
                        >
                          Verify
                        </Button>
                        <span className="dont_have_account">
                          <p className="m-0">
                            <Link to="" onClick={sendEmailOtp}>
                              Resend
                            </Link>
                          </p>
                        </span>
                      </p>
                    </FormGroup>
                  )}
                </>
              )}
              {!userDetails?.mobileVerified && (
              <>
              {
                !isVerify.mobile &&   <FormGroup className="position-relative d-flex align-items-center gap-2">
                <span className="otp_input_wrapping position-relative">
                  <Input
                    type="number"
                    id="exampleEmail"
                    placeholder="Verify Mobile Number"
                    onChange={(e) => setMobileOtp(e.target.value)}
                  />
                  <div className="leftform_icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="white"
                        d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56a.977.977 0 0 0-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02c-.37-1.11-.56-2.3-.56-3.53c0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99C3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z"
                      />
                    </svg>
                  </div>
                </span>

                <p className="wraaping_otp_button d-flex align-items-center gap-2 m-0">
                  <Button className="btn_common w-100" onClick={verifyMobile}>
                    Verify
                  </Button>
                  <span className="dont_have_account">
                    <p className="m-0">
                      <Link to="" onClick={sendMobileOtp}>
                        Resend
                      </Link>
                    </p>
                  </span>
                </p>
              </FormGroup>
              }
              </>
              )}
            </Form>
          </div>
        </Container>
      </section>
    </Fade>
  );
};

export default Verifyfeild;

import { convertPriceToEther } from "../utils/web3Intraction";
import { ethers } from "ethers";
import toastr from "toastr";
import axios from "axios";

export const WETH_TOKEN_ADDRESS = "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6";
export const WETH_MAINNET_TOKEN_ADDRESS =
  "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";

// Uniswap V2 Factory contract address
export const factoryAddress = "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f";

///socket url for bit finex
export const BIT_FINEX_SOCKET = "wss://api-pub.bitfinex.com/ws/2";

export const BLOCKCHAIN_CURRENCY_CODE = {
  ethereum: "ETH",
  polygon: "MATIC",
  BNB: "BNB",
};

export const BLOCKCHAIN_ENUM = [
  { label: "Ethereum", value: "ethereum" },
  { label: "Polygon", value: "polygon" },
  { label: "Binance Smart Chain", value: "BNB" },
];

export const ETHERSCAN = {
  testnet: {
    ethereum: "https://goerli.etherscan.io/",
    BNB: "https://testnet.bscscan.com/",
    polygon: "https://mumbai.polygonscan.com/",
  },
  mainnet: {
    ethereum: "https://etherscan.io/",
    BNB: "https://bscscan.com/",
    polygon: "https://polygonscan.com/",
  },
};

export const COMPILER_VERSION = "v0.8.17%2bcommit.8df45f5f";

export const getNetworkUrl = (type, settings) => {
  if (settings?.blockchain?.length === 0) {
    return null;
  }

  let blockchain = settings?.blockchain.filter((ele) => {
    return ele.status === true && ele.type === type;
  });

  if (blockchain?.length === 0) {
    return null;
  }

  blockchain = blockchain[0];

  if (blockchain?.networkUrl?.length === 0) {
    // CONFIGURE_SETTING_FIRST
    return null;
  }

  let blockchainNetworkUrl = blockchain?.networkUrl.filter((ele) => {
    return ele.type === settings?.blockchainNetworkMode;
  });

  blockchainNetworkUrl = blockchainNetworkUrl[0];

  if (!blockchainNetworkUrl.url) {
    // CONFIGURE_SETTING_FIRST
    return null;
  }

  return blockchainNetworkUrl;
};

export const getContractDetails = (type, settings, contractType) => {
  if (
    settings[contractType] &&
    settings[contractType].contractAddress.length === 0
  ) {
    return null;
  }

  let contractAddress = settings[contractType].contractAddress.filter((ele) => {
    return ele.type === type;
  });

  if (contractAddress.length === 0) {
    return null;
  }

  contractAddress = contractAddress[0];

  if (!contractAddress[settings.blockchainNetworkMode]) {
    // CONFIGURE_SETTING_FIRST
    return null;
  }
  return {
    abi: settings[contractType].abi,
    contractAddress: contractAddress[settings.blockchainNetworkMode],
  };
};

export const downloadTxtFile = (downloadText) => {
  if (downloadText?.length > 0) {
    const element = document.createElement("a");
    const file = new Blob([downloadText], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "Wallet-details.txt";
    document.body.appendChild(element);
    element.click();
  }
};

export const ACTIVITY_TYPE = {
  minted: "Minted",
  transfer: "Transfer",
  admin_comission: "Admin Comission",
  bids: "Bid",
  comission: "Royality",
};

export const copyToClipboard = (text) => {
  var textarea = document.createElement("textarea");
  textarea.textContent = text;
  textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
  document.body.appendChild(textarea);
  textarea.select();
  try {
    document.execCommand("copy");
    toastr.success("Address copy successfully!"); // Security exception may be thrown by some browsers.
  } catch (ex) {
    // return prompt("Copy to clipboard: Ctrl+C, Enter", text);
  } finally {
    document.body.removeChild(textarea);
  }
};

export const CHAINBYID = {
  137: "POLYGON",
  80001: "MUMBAI",
};

export const SendTokenAbi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export const isAddressValid = (address) => {
  try {
    let isAddress = ethers.utils.isAddress(address);
    return isAddress;
  } catch (error) {
    return null;
  }
};

export const isNumberValid = (e) => {
  ["+", "e", "E", "-"].includes(e.key) && e.preventDefault();
};
export const isNumberValidForMPIN = (e) => {
  ![
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "Backspace",
    "F12",
    "Enter",
  ].includes(e.key) && e.preventDefault();
};

export function requestAndShowPermission() {
  let permissions = Notification.permission;
  if (permissions === "granted") {
  } else if (permissions == "denied") {
    // We need to ask the user for permission
    Notification.requestPermission().then(function (permission) {
      // console.log("permiss", permission);
    });
  }
}

export const sendTypes = {
  MATIC: "balance",
  PPT: "PPT_balance",
};

export let customABI = {
  inputs: [
    {
      internalType: "address",
      name: "spender",
      type: "address",
    },
    {
      internalType: "uint256",
      name: "addedValue",
      type: "uint256",
    },
  ],
  name: "increaseAllowance",
  outputs: [
    {
      internalType: "bool",
      name: "",
      type: "bool",
    },
  ],
  stateMutability: "nonpayable",
  type: "function",
};

export const levelObj = {
  1: "singlelevel_one_bar",
  2: "singlelevel_two_bar",
  3: "singlelevel_three_bar",
  4: "singlelevel_four_bar",
  5: "singlelevel_five_bar",
};

export const levelFilter = {
  All: {
    minValue: 1,
    maxValue: 49,
  },
  Schooner: {
    minValue: 10,
    maxValue: 19,
  },
  Brigantine: {
    minValue: 20,
    maxValue: 29,
  },
  Galleon: {
    minValue: 30,
    maxValue: 39,
  },
  Sloop: {
    minValue: 1,
    maxValue: 9,
  },
  Frigate: {
    minValue: 40,
    maxValue: 49,
  },
};

export function startCountdown(seconds) {
  let counter = seconds;
  const interval = setInterval(() => {
    counter--;
    if (counter < 0) {
      clearInterval(interval);
    }
    return counter;
  }, 1000);
  return counter;
}

export const barObj = {
  1: "singlelevel_one_line",
  2: "singlelevel_two_line",
  3: "singlelevel_three_line",
  4: "singlelevel_four_line",
  5: "singlelevel_five_line",
  Sloop: "singlelevel_one_line",
  Schooner: "singlelevel_two_line",
  Brigantine: "singlelevel_three_line",
  Galleon: "singlelevel_four_line",
  Frigate: "singlelevel_five_line",
};

export const stringShortner = (string, limit) => {
  try {
    if (string.length <= limit) {
      return string;
    }
    return string.slice(0, limit) + "...";
  } catch (e) {
    return string;
  }
};

// ABI of the Uniswap V2 Factory contract
export const factoryAbi = [
  {
    inputs: [
      { internalType: "address", name: "_feeToSetter", type: "address" },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "token0",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "token1",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "pair",
        type: "address",
      },
      { indexed: false, internalType: "uint256", name: "", type: "uint256" },
    ],
    name: "PairCreated",
    type: "event",
  },
  {
    constant: true,
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "allPairs",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "allPairsLength",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "tokenA", type: "address" },
      { internalType: "address", name: "tokenB", type: "address" },
    ],
    name: "createPair",
    outputs: [{ internalType: "address", name: "pair", type: "address" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "feeTo",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "feeToSetter",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" },
    ],
    name: "getPair",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ internalType: "address", name: "_feeTo", type: "address" }],
    name: "setFeeTo",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "_feeToSetter", type: "address" },
    ],
    name: "setFeeToSetter",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
]; // ABI definition here

export const pairTokensAbi = [
  {
    inputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount0",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount1",
        type: "uint256",
      },
      { indexed: true, internalType: "address", name: "to", type: "address" },
    ],
    name: "Burn",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount0",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount1",
        type: "uint256",
      },
    ],
    name: "Mint",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount0In",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount1In",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount0Out",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount1Out",
        type: "uint256",
      },
      { indexed: true, internalType: "address", name: "to", type: "address" },
    ],
    name: "Swap",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint112",
        name: "reserve0",
        type: "uint112",
      },
      {
        indexed: false,
        internalType: "uint112",
        name: "reserve1",
        type: "uint112",
      },
    ],
    name: "Sync",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    constant: true,
    inputs: [],
    name: "DOMAIN_SEPARATOR",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "MINIMUM_LIQUIDITY",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "PERMIT_TYPEHASH",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" },
    ],
    name: "allowance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "value", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ internalType: "address", name: "to", type: "address" }],
    name: "burn",
    outputs: [
      { internalType: "uint256", name: "amount0", type: "uint256" },
      { internalType: "uint256", name: "amount1", type: "uint256" },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "factory",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "getReserves",
    outputs: [
      { internalType: "uint112", name: "_reserve0", type: "uint112" },
      { internalType: "uint112", name: "_reserve1", type: "uint112" },
      { internalType: "uint32", name: "_blockTimestampLast", type: "uint32" },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "_token0", type: "address" },
      { internalType: "address", name: "_token1", type: "address" },
    ],
    name: "initialize",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "kLast",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ internalType: "address", name: "to", type: "address" }],
    name: "mint",
    outputs: [{ internalType: "uint256", name: "liquidity", type: "uint256" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "nonces",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "value", type: "uint256" },
      { internalType: "uint256", name: "deadline", type: "uint256" },
      { internalType: "uint8", name: "v", type: "uint8" },
      { internalType: "bytes32", name: "r", type: "bytes32" },
      { internalType: "bytes32", name: "s", type: "bytes32" },
    ],
    name: "permit",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "price0CumulativeLast",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "price1CumulativeLast",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ internalType: "address", name: "to", type: "address" }],
    name: "skim",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "uint256", name: "amount0Out", type: "uint256" },
      { internalType: "uint256", name: "amount1Out", type: "uint256" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "bytes", name: "data", type: "bytes" },
    ],
    name: "swap",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [],
    name: "sync",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "token0",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "token1",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "value", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "value", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const UNISWAP_ROUTER_ADDRESS =
  "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";

export const calculateTransferToken = (itemDetails, NowTick) => {
  const marginAmount = itemDetails.tokenAmount;
  const leverageUsed = itemDetails.tokenAmount * itemDetails.leverage;
  const totalPaisaJoMilaHai = itemDetails.collateral * NowTick; // (USDT hoga)
  const exchangeKaPaise = leverageUsed - marginAmount; // (USDT hoga)
  let closePrice = totalPaisaJoMilaHai - exchangeKaPaise; // User ko dena hai // (USDT hoga)

  if (itemDetails.position === "short") {
    const openpositionAmount =
      itemDetails.currentTicker * itemDetails.collateral;
    const closepositionAmount = NowTick * itemDetails.collateral;
    const currentClosing = openpositionAmount - closepositionAmount;
    closePrice = marginAmount + currentClosing;
  }

  return closePrice - itemDetails.tokenAmount;
};

export const converCryptoToDollar = async (
  amount,
  symbol,
  toFixed,
  currency = "USD"
) => {
  let calculatedAmount = 0;
  try {
    if (amount && symbol) {
      const response = await axios.get(
        `https://min-api.cryptocompare.com/data/price?fsym=${symbol}&tsyms=${currency}`
      );
      calculatedAmount = response.data[currency]
        ? response.data[currency] * amount
        : 0;
      return calculatedAmount
        ? Number(calculatedAmount).toFixed(toFixed || 2)
        : 0;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

export const convertCryptoToCrypto = (
  fromSymbol = "ETH",
  toSymbol = "USD",
  callback
) =>
  new Promise(async (resolve) => {
    try {
      const blockchain = {
        ethereum: "ETH",
        ARB: "ARB",
      };
      if (fromSymbol && toSymbol) {
        const response = await axios.get(
          `https://min-api.cryptocompare.com/data/price?fsym=${
            blockchain[fromSymbol] || fromSymbol
          }&tsyms=${toSymbol}`
        );

        const price = Number(response.data[toSymbol] || 1);

        resolve(price);

        callback && callback(null, price);
      } else {
        resolve(1);
        callback && callback(null, 1);
      }
    } catch (error) {
      console.log(error, "<= err in converCryptoToDollar");
      resolve(1);
      callback && callback(error, 1);
    }
  });

export const getUniqueValues = (arr, key) => {
  const uniqueValues = [];

  const unique = arr.filter((element) => {
    const isDuplicate = uniqueValues.includes(element[key]);

    if (!isDuplicate) {
      uniqueValues.push(element[key]);

      return true;
    }

    return false;
  });

  return unique;
};

export const isEmailValid = (email) => {
  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (email.match(validRegex)) {
    return true;
  } else {
    return false;
  }
};

//Slider values
export const LEVERAGE_MARKS = {
  1: {
    style: {
      color: "#fff",
      opacity: 0.5,
    },
    label: <strong>1X</strong>,
  },
  25: {
    style: {
      color: "#fff",
      opacity: 0.5,
    },
    label: <strong>25X</strong>,
  },
  50: {
    style: {
      color: "#fff",
      opacity: 0.5,
    },
    label: <strong>50X</strong>,
  },
  75: {
    style: {
      color: "#fff",
      opacity: 0.5,
    },
    label: <strong>75X</strong>,
  },
  100: {
    style: {
      color: "#fff",
      opacity: 0.5,
    },
    label: <strong>100X</strong>,
  },
};
export function checkPassword(str) {
  var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(str);
}

export function formatPosNegCurrency(amount) {
  // Check if the amount is negative
  if (amount < 0) {
      return "- $" + Math.abs(amount).toFixed(2);
  } else {
      return "$" + amount.toFixed(2);
  }
}
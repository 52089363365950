
import * as CONST from "./actionTypes";


/* Get items */
export const getBlogs = (data) => ({
  type: CONST.GET_BLOGS,
  payload: data,
});

export const getBlogsSuccess = (items) => ({
  type: CONST.GET_BLOGS_SUCCESS,
  payload: items,
});

export const getBlogsFail = (error) => ({
  type: CONST.GET_BLOGS_FAIL,
  payload: error,
});
/* Get Blogs END */

/* Add Post */
export const addBlog = (item, callback) => ({
  type: CONST.ADD_BLOG,
  payload: { item, callback },
});

export const addBlogFail = (error) => ({
  type: CONST.ADD_BLOG_FAIL,
  payload: error,
});

export const addBlogSuccess = () => ({
  type: CONST.ADD_BLOG_SUCCESS,
});
/* Add Post END */

/* Get Post */
export const getBlog = (id) => ({
  type: CONST.GET_BLOG,
  payload: id,
});

export const getBlogFail = (error) => ({
  type: CONST.GET_BLOG_FAIL,
  payload: error,
});

export const getBlogSuccess = (item) => ({
  type: CONST.GET_BLOG_SUCCESS,
  payload: item,
});
/* Get Post END */

/* Update Post */
export const putBlog = (data, callback) => ({
  type: CONST.PUT_BLOG,
  payload: { data, callback },
});

export const putBlogFail = (error) => ({
  type: CONST.PUT_BLOG_FAIL,
  payload: error,
});

export const putBlogSuccess = () => ({
  type: CONST.PUT_BLOG_SUCCESS,
});
/* Update Post END */

/* Delete Post */
export const deleteBlog = (data, callback) => ({
  type: CONST.DELETE_BLOG,
  payload: { data, callback },
});

export const deleteBlogFail = (error) => ({
  type: CONST.DELETE_BLOG_FAIL,
  payload: error,
});

export const deleteBlogSuccess = () => ({
  type: CONST.DELETE_BLOG_SUCCESS,
});
import React from "react";
import Signin from "Pages/Signin";

export default function Signintheme() {
  return (
    <>
     <Signin/>
    </>
  );
}

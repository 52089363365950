import {
  VERIFY_OTP,
  VERIFY_OTP_SUCCESSFUL,
  VERIFY_OTP_FAILED
} from "./actionTypes"

const initialState = {
  message: null,
  loading: false,
}

const account = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_OTP:
      return {
        ...state,
        user: null,
        loading: true,
      }

    case VERIFY_OTP_SUCCESSFUL:
      return {
        ...state,
        loading: false,
      }

    case VERIFY_OTP_FAILED:
      return {
        ...state,
        user: null,
        loading: false,
      }

    default:
      return state
  }
}

export default account

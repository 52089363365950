import React from "react";
import Headerlogin from "Component/Header/Headerlogin";
import Dashsetting from "Pages/Profile/Setting";


export default function Settingtheme() {
  return (
    <>
      <Headerlogin heading="Setting" />
      <Dashsetting/>
    </>
  );
}

import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import * as TYPES from "./actionTypes";
import * as ACTIONS from "store/actions";
import * as API from "helpers/backend_helper";

import toastr from "toastr";

import { updateToken } from "helpers/api_helper";

function* loginUser({ payload: { user, navigate } }) {
  try {
    const response = yield call(API.postLogin, user);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(ACTIONS.apiError(response.message));
    }

    if (response.data.tfa_otp_enabled) {
      navigate("/sign-in/validate", { state: user });
    } else {
      localStorage.setItem("authUser", JSON.stringify(response));
      localStorage.setItem("userData", JSON.stringify(response));
      updateToken(response.data.Token);
      yield put(ACTIONS.loginSuccess(response));
      yield put(ACTIONS.registerUserSuccessful(response));
      // if (response.type === "loginOTP") {
      //   navigate("/otp");
      // } else {
      //   navigate("/otp-verify");
      // }
      navigate("/");
    }
  } catch (error) {
    yield put(ACTIONS.apiError(error));
  }
}

function* loginWithOtp({ payload: { user, navigate } }) {
  try {
    const response = yield call(API.postLoginWithOtp, user);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(ACTIONS.apiError(response.message));
    }
    localStorage.setItem("authUser", JSON.stringify(response));
    updateToken(response.data.Token);
    yield put(ACTIONS.loginSuccess(response));
    navigate("/dashboard");
  } catch (error) {
    yield put(ACTIONS.apiError(error));
  }
}

function* currentUser() {
  try {
    const response = yield call(API.getCurrentUser);

    if (response.status == "failure") {
      return yield put(ACTIONS.currentUserFail(response.message));
    }

    localStorage.setItem("userData", JSON.stringify(response));
    yield put(ACTIONS.currentUserSuccess(response));
  } catch (error) {
    yield put(ACTIONS.currentUserFail("Server Error"));
  }
}

function* logoutUser({ payload: { history, role } }) {
  try {
    localStorage.removeItem("authUser");
    localStorage.removeItem("userData");
    yield put(ACTIONS.logoutUserSuccess());
    history.push("/login");
  } catch (error) {
    yield put(ACTIONS.apiError(error));
  }
}

function* getTfaOtp({ payload: { callback } }) {
  try {
    const response = yield call(API.getTfaOtp);

    callback && callback(response);
  } catch (error) {
    console.log("error", error);
  }
}

function* postTfaVerifyOtp({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postTfaVerifyOtp, data);

    if (response.status === "success") {
      yield put(ACTIONS.currentUser());
    }

    callback && callback(response);
  } catch (error) {
    console.log("error", error);
  }
}

function* postTfaValidateOtp({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postTfaValidateOtp, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(ACTIONS.apiError(response.message));
    }

    localStorage.setItem("authUser", JSON.stringify(response));
    localStorage.setItem("userData", JSON.stringify(response));
    updateToken(response.data.Token);
    yield put(ACTIONS.loginSuccess(response));
    yield put(ACTIONS.registerUserSuccessful(response));

    callback && callback(response);
  } catch (error) {
    yield put(ACTIONS.apiError(error));
  }
}

function* postTfaDisableOtp({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postTfaDisableOtp, data);

    if (response.status === "success") {
      yield put(ACTIONS.currentUser());
    }

    callback && callback(response);
  } catch (error) {
    console.log("error", error);
  }
}

function* loginWithWallet({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postLoginWithWallet, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      callback && callback(false);
      return yield put(ACTIONS.apiError(response.message));
    }

    // if (response.data.tfa_otp_enabled) {
    //   navigate("/sign-in/validate", { state: data });
    // } else {
      localStorage.setItem("authUser", JSON.stringify(response));
      localStorage.setItem("userData", JSON.stringify(response));
      updateToken(response.data.Token);
      yield put(ACTIONS.loginSuccess(response));
      yield put(ACTIONS.registerUserSuccessful(response));
      // if (response.type === "loginOTP") {
      //   navigate("/otp");
      // } else {
      //   navigate("/otp-verify");
      // }
      callback && callback(true,response);
    // }
  } catch (error) {
    yield put(ACTIONS.apiError(error));
    callback && callback(false);
  }
}


function* authSaga() {
  yield takeEvery(TYPES.LOGIN_USER, loginUser);
  yield takeEvery(TYPES.LOGIN_OTP, loginWithOtp);
  yield takeEvery(TYPES.GET_CURRENT_USER, currentUser);
  yield takeEvery(TYPES.LOGOUT_USER, logoutUser);
  yield takeEvery(TYPES.GET_TFA_OTP, getTfaOtp);
  yield takeEvery(TYPES.POST_TFA_VERIFY_OTP, postTfaVerifyOtp);
  yield takeEvery(TYPES.POST_TFA_VALIDATE_OTP, postTfaValidateOtp);
  yield takeEvery(TYPES.POST_TFA_DISABLE_OTP, postTfaDisableOtp);
  yield takeEvery(TYPES.LOGIN_WALLET_REQUEST, loginWithWallet);
}

export default authSaga;

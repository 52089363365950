import { formatPosNegCurrency } from "helpers/constants";
import React from "react";
import { Container, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Leaderboard = () => {
  const { leaderboard } = useSelector(state => state.Order)
  const navigate = useNavigate();
  return (
    <section className="leader_board">
      <Container>
        <div className="leader_inner">
          <div className="leader_header d-flex justify-content-between align-items-center">
            <div className="leader_head d-flex align-items-center">
              <span className="me-2">
                <img src="/images/troffy.png" alt="" className="img-fluid" />
              </span>
              <h3>Leaderboard</h3>
            </div>

            <Button className="see_all_cta" onClick={() => navigate("/leaderboard")}>See All</Button>
          </div>

          <ul className="leader_border_lsit py-3 px-0 m-0">
            {leaderboard.length > 0 && leaderboard.map((item, idx) => {
              return (
                <li key={idx}>
                  <div className="leadertb-wrap d-flex align-items-center justify-content-between">
                    <div className="leaderboard_left d-flex align-items-center">
                      <div className="couter_uumber me-3">{idx + 1}</div>
                      <p>{item?.userId?.username}</p>
                    </div>

                    <div className="wallet_increse d-flex align-items-center">
                      <p>Profit:</p>
                      <div  title={formatPosNegCurrency(parseFloat(item.PNL))} className={`percentage_box ms-2 ${Number(item.PNL) > 0 && "income-positive"}`}>{formatPosNegCurrency(parseFloat(item.PNL))}</div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </Container>
    </section>
  );
};

export default Leaderboard;

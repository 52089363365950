//Dependencies
import React, { useEffect, useState } from "react";
import toastr from "toastr";
import { Slider } from "antd";
import { Button, Form } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

//Component and common functionality
import Transacsubmit from "Component/Modals/Transacsubmitmmod";
import useWallet from "Hooks/wallet";
import useExitPrompt from "Hooks/useExitPrompt";
import Web3Intraction from "utils/web3Intraction1";
import { getNetworkUrl, LEVERAGE_MARKS } from "helpers/constants";
import { getTokenCurrentPrice, openOrder } from "store/actions";

var W3CWebSocket = require("websocket").w3cwebsocket;

//  https://api.coingecko.com/api/v3/simple/price?ids=DAI&vs_currencies=USD
const Buycelltab = (props) => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const query = searchParams.get("type");

  const wallet = useWallet();
  const dispatch = useDispatch();
  const naviagte = useNavigate();

  //selector
  const { user } = useSelector((state) => state.Login);
  const { orderDetails } = useSelector((state) => state.Bot);

  //Manage all states
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
  const [tokenBalance, setTokenBalance] = useState(0);

  const [Transacsubmitmmod, setTransacsubmitmmod] = useState(false);
  const [leverage, setLeverage] = useState(1);
  const [currentTokenPrice, setCurrentTokenPrice] = useState(0);
  const [tab, setTab] = useState("long");
  const [openOrderLoading, setOpenOrderLoading] = useState(false);
  const [fields, setfields] = useState({
    quantity: 0,
    collateral: 0,
    leverage: 1,
    liquidationPrice: 0,
    contractSize: 0,
  });

  useEffect(() => {
    return () => {
      setShowExitPrompt(false);
    };
  }, []);

  const getTokenValue = async () => {
    try {
      const networkUrl = getNetworkUrl("ethereum", props.settings);
      await wallet.switchNetwork(networkUrl?.chainId || "5");
    } catch (error) {
      // return toastr.error("User reject request!");
    }
    const web3Intraction = new Web3Intraction(
      "ethereum",
      wallet.provider,
      props.settings
    );
    if (props.tradeTokenDetails && wallet.account) {
      const tokenBalance = await web3Intraction.getTokenPrice(
        props.tradeTokenDetails.networkMode == "testnet"
          ? props.tradeTokenDetails.testnetContractAddress
          : props.tradeTokenDetails.contractAddress,
        wallet.account,
        props.tradeTokenDetails.decimals
      );
      setTokenBalance(tokenBalance);
    }
  };

  //get token balance
  useEffect(() => {
    if (props.settings && props.tradeTokenDetails) {
      getTokenValue();
    }
  }, [props.tradeTokenDetails, props.settings, wallet]);

  //Handle values
  const handleChange = (name) => (event) => {
    const { value } = event.target;

    setfields((prev) => ({ ...prev, [name]: value }));
  };
  const handleLeverage = (newValue) => {
    setLeverage(newValue);
  };

  useEffect(() => {
    const entryPrice = currentTokenPrice;
    const contractSize = (Number(fields.quantity) * leverage) / entryPrice;
    const totalValueOfTrade = contractSize * entryPrice;

    const im = (contractSize * entryPrice) / leverage;
    const imr = im / totalValueOfTrade;
    // const mmr = 0.5;

    let liquidationPrice = entryPrice * (1 - imr);

    if (tab == "short") {
      liquidationPrice = entryPrice * (1 + imr);
    }

    // console.log(
    //   "fsdf",
    //   JSON.stringify({
    //     entryPrice,
    //     contractSize,
    //     totalValueOfTrade,
    //     im,
    //     imr,
    //     liquidationPrice,
    //   })
    // );

    setfields((prevState) => ({
      ...prevState,
      liquidationPrice: liquidationPrice || 0,
      contractSize,
    }));
  }, [tab, fields.quantity, currentTokenPrice, leverage]);

  // Curent token price socket
  useEffect(() => {
    const client = new W3CWebSocket("wss://api-pub.bitfinex.com/ws/2");

    client.onmessage = async (msg) => {
      const resp = JSON.parse(msg.data.toString());
      // console.log("resp", resp);

      if (resp[1] && resp[1].length == 10) {
        const currentPrice = resp[1][6];
        setCurrentTokenPrice(currentPrice);
      }
    };

    let msg = JSON.stringify({
      event: "subscribe",
      channel: "ticker",
      symbol: `t${
        props.tradeTokenDetails &&
        props.tradeTokenDetails.bitFinexSymbol &&
        props.tradeTokenDetails?.bitFinexSymbol
      }`,
    });

    client.onopen = () => {
      client.send(msg, "msg");
    };

    return () => {
      client.close();
    };
  }, [props.tradeTokenDetails]);

  useEffect(() => {
    if (orderDetails && query) {
      setLeverage(orderDetails.leverage);
      setTab(orderDetails.position);
      setfields((pre) => ({
        ...pre,
        ...orderDetails,
        quantity: orderDetails.tokenAmount,
        tokenId: orderDetails.tokenId?._id,
        userId: orderDetails.userId?._id,
      }));
    }
  }, [JSON.stringify(orderDetails), query]);

  //open order
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!wallet.account) return toastr.error("Connect Wallet!");

    if (fields.quantity <= 0) {
      return toastr.error("Amount should be greater then zero!");
    }

    if (Number(fields.stopLoss) > Number(currentTokenPrice) && tab == "long") {
      return toastr.error("Stop loss should be less then current price");
    }

    if (Number(currentTokenPrice) > Number(fields.stopLoss) && tab == "short") {
      return toastr.error("Stop loss should be greator then current price");
    }

    setOpenOrderLoading(true);
    setShowExitPrompt(true);

    if (props.tradeTokenDetails) {
      const callBack = async (response) => {
        // return
        if (response.data.length == 10) {
          try {
            const web3Intraction = new Web3Intraction(
              "ethereum",
              wallet.provider,
              props.settings
            );

            const params = {
              reqId: Math.floor(100000 + Math.random() * 900000),
              marginAmount: fields.quantity,
              underlyingAssets: fields.contractSize,
              leverage,
              liquidationUnderlyingAssest: fields.liquidationPrice,
              side: tab === "long" ? 0 : 1,
              tokenAddress:
                props.tradeTokenDetails?.networkMode == "testnet"
                  ? props.tradeTokenDetails?.testnetContractAddress
                  : props.tradeTokenDetails?.contractAddress,
              tradeContractAddress:
                props.tradeTokenDetails?.tradeContractAddress,
            };

            await web3Intraction.checkAllowance(params);
            const openPositionResp = await web3Intraction.openPosition(params);

            if (openPositionResp.status) {
              const callback = async (response) => {
                if (response.status == "success") {
                  setOpenOrderLoading(false);
                  setShowExitPrompt(false);
                  toastr.success("Open Position Successfully!");
                  naviagte("/positions");
                } else {
                  setShowExitPrompt(false);
                  setOpenOrderLoading(false);
                  return toastr.error(response.message);
                }
              };

              dispatch(
                openOrder(
                  {
                    reqId: params.reqId,
                    tokenAmount: fields.quantity,
                    leverage: leverage,
                    collateral: fields.contractSize,
                    liquidationPrice: fields.liquidationPrice,
                    currentTicker: currentTokenPrice,
                    tokenAddress:
                      props.tradeTokenDetails.networkMode == "testnet"
                        ? props.tradeTokenDetails.testnetContractAddress
                        : props.tradeTokenDetails.contractAddress,
                    stopLoss: fields.stopLoss,
                    position: params.side === 0 ? "long" : "short",
                    userId: user._id,
                    tokenId: props.tradeTokenDetails._id,
                    status: "open",
                    orderType: "market",
                  },
                  callback
                )
              );
            } else {
              console.log("else");
              setOpenOrderLoading(false);
              setShowExitPrompt(false);
              return toastr.error("Something went wrong");
            }
          } catch (error) {
            console.log("error", error);
            setOpenOrderLoading(false);
            setShowExitPrompt(false);
            return toastr.error("Something went wrong!");
          }
        } else {
          setShowExitPrompt(false);
          setOpenOrderLoading(false);
        }
      };

      dispatch(
        getTokenCurrentPrice(props.tradeTokenDetails.bitFinexSymbol, callBack)
      );
    } else {
      setShowExitPrompt(false);
      setOpenOrderLoading(false);
      return toastr.error("Something went wrong!");
    }
  };

  return (
    <>
      <Transacsubmit
        show={Transacsubmitmmod}
        onhide={() => setTransacsubmitmmod(false)}
      />

      <section className="buycelltab">
        <div className="buybtn_wrapping p-1 bg-dark w-100 rounded d-flex align-items-center justify-content-between">
          <Button
            className={tab === "long" && "active"}
            onClick={() => setTab("long")}
          >
            Long {props?.tradeTokenDetails?.symbol}
          </Button>

          <Button
            className={tab === "short" && "active"}
            onClick={() => setTab("short")}
          >
            Short {props?.tradeTokenDetails?.symbol}
          </Button>
        </div>

        <Form className="position-relative" onSubmit={handleSubmit}>
          <div className="price-input_wrap bottom_dropdown">
            <div className="right_dropdown w-100 d-flex align-items-center justify-content-between">
              <div className="left_chainn">
                <div className="ac_input">
                  <input
                    type="number"
                    placeholder="0"
                    min={props.tradeTokenDetails?.minTokenAmount || 20}
                    step="any"
                    name="quantity"
                    value={fields.quantity}
                    onChange={handleChange("quantity")}
                  />
                </div>

                <div className="ac_token d-flex align-items-center">
                  <p className="m-0 text-white">USDT</p>
                </div>
              </div>
            </div>
          </div>

          <div className="poolinfo_here">
            <ul>
              <li>
                <p>{props.tradeTokenDetails?.symbol} Current Price</p>

                <p>{Number(currentTokenPrice)} USDT</p>
              </li>

              <li>
                <p>Wallet Balance</p>

                <p>{Number(tokenBalance).toFixed(2)} USDT</p>
              </li>
            </ul>
          </div>

          <div className="custom_range">
            <Slider
              marks={LEVERAGE_MARKS}
              name="leverage"
              trackBg="f00"
              defaultValue={1}
              min={1}
              max={100}
              onChange={handleLeverage}
              value={leverage}
            />

            <div className="poolinfo_here p-3 rounded bg-dark">
              <ul>
                <li>
                  <p>Leverage Price</p>
                  {/* <p>Balance: {parseFloat(Number(balance).toFixed(4))} Eth</p> */}
                </li>

                <li>
                  <div className="ac_input">
                    <input
                      type="number"
                      readOnly
                      placeholder="0"
                      value={Number(fields.contractSize).toFixed(8)}
                      min={0}
                      name="amount"
                    />
                  </div>
                  <div className="ac_token d-flex align-items-center">
                    {/* <div className="ac_img me-1">
                      <img
                        src="https://w7.pngwing.com/pngs/268/1013/png-transparent-ethereum-eth-hd-logo-thumbnail.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div> */}
                    <p>{props.tradeTokenDetails?.symbol}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="poolinfo_here">
            <ul>
              <li>
                <p>Leverage used</p>

                <p>{Number(fields.quantity) * Number(leverage)} USDT</p>
              </li>

              <li>
                <p>Liquidation Price</p>

                <p>{Number(fields.liquidationPrice).toFixed(8)} USDT</p>
              </li>
            </ul>

            <div className="poolinfo_here p-3 rounded bg-dark">
              <ul>
                <li>
                  <p>Stop Loss</p>

                  <div className="ac_input stop_Loss">
                    <input
                      type="number"
                      placeholder="0"
                      value={fields.stopLoss || 0}
                      min={tab === "long" ? 0 : currentTokenPrice}
                      max={tab === "long" ? currentTokenPrice : undefined}
                      onChange={handleChange("stopLoss")}
                      name="stopLoss"
                      step="any"
                    />
                  </div>
                </li>
              </ul>
            </div>

            <div className="buyselll_btn">
              <Button
                type="submit"
                className="connect_wallet_cta w-100"
                disabled={openOrderLoading}
              >
                {openOrderLoading ? (
                  <div className="d-flex justify-content-center">
                    {openOrderLoading && <Spinner style={{ color: "#fff" }} />}
                  </div>
                ) : (
                  "Open Position"
                )}
              </Button>
            </div>
          </div>

          <div className="buyselll_btn"></div>
        </Form>
      </section>
    </>
  );
};

export default Buycelltab;

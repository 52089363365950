import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import useWallet from "Hooks/wallet";
import Web3Intraction from "utils/web3Intraction1";

import { getTradeTokenRequest } from "store/actions";
import toastr from "toastr";

const Walletbalance = (props) => {
  const dispatch = useDispatch();

  const wallet = useWallet();
  const { user } = useSelector((state) => state.Login);
  const { IncomeOutcome } = useSelector((state) => state.Order);
  const { settings } = useSelector((state) => state.Settings);
  const { tradeTokens } = useSelector((state) => state.Crypto);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
  });
  const [balance, setBalance] = useState(0);
  const [tokenBalance, settokenBalance] = useState(0);

  useEffect(() => {
    dispatch(getTradeTokenRequest(filters));
  }, [filters]);

  useEffect(() => {
    if (tradeTokens.length && settings) {
      const web3Intraction = new Web3Intraction(
        "ethereum",
        wallet.provider,
        settings
      );

      const timeout = setTimeout(async () => {
        let _bal = 0;

        for (let i = 0; i < tradeTokens.length; i++) {
          const tok = tradeTokens[i];
          const usdtAddress =
            tok.networkMode == "testnet"
              ? tok.testnetContractAddress
              : tok.contractAddress;

          const poolAddress = tok.tradeContractAddress;

          _bal += Number(
            await web3Intraction.getTokenBalance(usdtAddress, poolAddress)
          );
        }

        settokenBalance(_bal);
      }, 100);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [JSON.stringify(tradeTokens), JSON.stringify(settings)]);

  //Get account balance
  useEffect(() => {
    if (settings) {
      getBalance();
    }
  }, [settings, wallet]);

  const getBalance = async () => {
    try {
      if (wallet.account) {
        const web3Intraction = new Web3Intraction(
          "ethereum",
          wallet.provider,
          settings
        );
        const amount = await web3Intraction.getBalance(wallet.account);
        setBalance(amount);
      }
    } catch (error) {
      setBalance(0);
    }
  };

  return (
    <section className="wallet_balnce_table">
      <Container>
        <div className="wallet_balce_top">
          <div className="balance_left">
            <h5>Total Balance </h5>
            <div className="available_blance mt-4">
              <p>Available Balance</p>
            </div>
            <div className="balce_wrap">
              <h3>{(balance && Number(balance).toFixed(8)) || 0}</h3>
              <span>ETH</span>
            </div>
            {/* <p>$20,974.23 </p> */}

            <h5>Total Volume </h5>
            <div className="available_blance mt-4">
              <p>Total Volume Traded on the platform</p>
            </div>
            <div className="balce_wrap">
              <h3>{(user && Number(user.volume || 0).toFixed(2)) || 0}</h3>
              <span>USDT</span>
            </div>

            <div className="available_blance mt-4">
              <p>Total Value Locked</p>
            </div>
            <div className="balce_wrap">
              <h3>{Number(tokenBalance || 0).toFixed(4) || 0}</h3>
              <span>USDT</span>
            </div>
          </div>

          <div className="income_div">
            <div className="income_bar">
              <img src="/images/incomebar.png" alt="" className="img-fluid" />
            </div>
            <div className="bottom_content_bar mt-4 d-flex gap-3">
              <div className="income_icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="44"
                  height="44"
                  viewBox="0 0 44 44"
                  fill="none"
                >
                  <rect
                    opacity="0.2"
                    width="44"
                    height="44"
                    rx="8"
                    fill="#1FCB4F"
                  />
                  <path
                    d="M14.9999 28.25C14.8099 28.25 14.6199 28.18 14.4699 28.03C14.1799 27.74 14.1799 27.26 14.4699 26.97L28.4699 12.97C28.7599 12.68 29.2399 12.68 29.5299 12.97C29.8199 13.26 29.8199 13.74 29.5299 14.03L15.5299 28.03C15.3799 28.18 15.1899 28.25 14.9999 28.25Z"
                    fill="#1FCB4F"
                  />
                  <path
                    d="M25.27 28.25H15C14.59 28.25 14.25 27.91 14.25 27.5V17.23C14.25 16.82 14.59 16.48 15 16.48C15.41 16.48 15.75 16.82 15.75 17.23V26.75H25.27C25.68 26.75 26.02 27.09 26.02 27.5C26.02 27.91 25.68 28.25 25.27 28.25Z"
                    fill="#1FCB4F"
                  />
                  <path
                    d="M30.5 32.75H13.5C13.09 32.75 12.75 32.41 12.75 32C12.75 31.59 13.09 31.25 13.5 31.25H30.5C30.91 31.25 31.25 31.59 31.25 32C31.25 32.41 30.91 32.75 30.5 32.75Z"
                    fill="#1FCB4F"
                  />
                </svg>
              </div>
              <div className="income_content text-start">
                <h5>
                  {(IncomeOutcome?.income && IncomeOutcome.income.toFixed(8)) ||
                    0}
                </h5>
                <p>Income</p>
              </div>
            </div>
          </div>

          <div className="income_div">
            <div className="income_bar">
              <img src="/images/outcomebar.png" alt="" className="img-fluid" />
            </div>
            <div className="bottom_content_bar mt-4 d-flex gap-3">
              <div className="income_icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="44"
                  height="44"
                  viewBox="0 0 44 44"
                  fill="none"
                >
                  <rect
                    opacity="0.2"
                    width="44"
                    height="44"
                    rx="8"
                    fill="#F46D22"
                  />
                  <path
                    d="M13.5 32.75H30.5C30.91 32.75 31.25 32.41 31.25 32C31.25 31.59 30.91 31.25 30.5 31.25H13.5C13.09 31.25 12.75 31.59 12.75 32C12.75 32.41 13.09 32.75 13.5 32.75Z"
                    fill="#F46D22"
                  />
                  <path
                    d="M15.0001 28.25C15.1901 28.25 15.3801 28.18 15.5301 28.03L29.5301 14.03C29.8201 13.74 29.8201 13.26 29.5301 12.97C29.2401 12.68 28.7601 12.68 28.4701 12.97L14.4701 26.97C14.1801 27.26 14.1801 27.74 14.4701 28.03C14.6201 28.18 14.8101 28.25 15.0001 28.25Z"
                    fill="#F46D22"
                  />
                  <path
                    d="M29 24.52C29.41 24.52 29.75 24.18 29.75 23.77V13.5C29.75 13.09 29.41 12.75 29 12.75H18.73C18.32 12.75 17.98 13.09 17.98 13.5C17.98 13.91 18.32 14.25 18.73 14.25H28.25V23.77C28.25 24.18 28.59 24.52 29 24.52Z"
                    fill="#F46D22"
                  />
                </svg>
              </div>
              <div className="income_content text-start">
                <h5>
                  {(IncomeOutcome.outcome &&
                    IncomeOutcome.outcome.toFixed(8)) ||
                    0}
                </h5>
                <p>Outcome</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Walletbalance;

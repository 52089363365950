import React, { useEffect, useState } from "react";
import Slider from "react-slick";

function AssetsSlider({ tokenList }) {
  const [sliderSettings, setsliderSettings] = useState({
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  });

  useEffect(() => {
    if (tokenList.length) {
      setsliderSettings((pre) => ({
        ...pre,
        slidesToShow: tokenList.length > 3 ? 3 : tokenList.length,
      }));
    }
  }, [JSON.stringify(tokenList.length)]);

  return (
    <div className="assets_card mt-4">
      <ul>
        <Slider {...sliderSettings}>
          {tokenList.map((data, idx) => {
            return (
              <li key={idx} className="my-2">
                <div className="card_wrap">
                  <div className="card_top d-flex align-items-center justify-content-between">
                    <div className="chain_wrap d-flex align-items-center">
                      <div className="assetschain_icon me-2">
                        <img src={data.icon} alt="" className="img-fluid" />
                      </div>
                      <h5>{data.name}</h5>
                    </div>
                    <div className="chain_price">
                      <p className="col_green d-flex align-items-center">
                        {/* {chainmovprice} */}
                        <span className="me-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="15"
                            viewBox="0 0 14 15"
                            fill="none"
                          >
                            <path
                              d="M10.7577 9.75411C10.6793 9.83248 10.5721 9.88198 10.4483 9.88198C10.2091 9.88198 10.0111 9.68399 10.0111 9.44475V4.87449L5.44085 4.87449C5.20161 4.87449 5.00362 4.6765 5.00362 4.43726C5.00362 4.19802 5.20161 4.00003 5.44085 4.00003L10.4483 4.00003C10.6876 4.00003 10.8856 4.19802 10.8856 4.43726L10.8856 9.44475C10.8856 9.5685 10.8361 9.67574 10.7577 9.75411Z"
                              fill="#1FCB4F"
                            />
                            <path
                              d="M10.6875 4.81668L3.74543 11.7587C3.57632 11.9278 3.29583 11.9278 3.12672 11.7587C2.9576 11.5896 2.9576 11.3091 3.12672 11.14L10.0687 4.19796C10.2379 4.02885 10.5183 4.02885 10.6875 4.19796C10.8566 4.36708 10.8566 4.64757 10.6875 4.81668Z"
                              fill="#1FCB4F"
                            />
                          </svg>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="card_bottom d-flex align-items-center justify-content-between mt-3">
                    <h4>{data.balance || 0}</h4>
                    <p>${data.balanceInUsd || 0}</p>
                  </div>
                </div>
              </li>
            );
          })}
        </Slider>
      </ul>
    </div>
  );
}

export default React.memo(AssetsSlider);

import React, { useEffect, useState } from "react";
import Header from "../Component/Header/Header";
import Home from "Pages/Home/home";
import Invest from "Pages/Home/invest";
import Monitoring from "Pages/Home/monitoring";
import Footer from "Component/Footer/Footer";
import { useSelector } from "react-redux";


export default function Hometheme() {
  const { settings } = useSelector(state => state.Settings)
  const [settingData, setSettingData] = useState(settings);

  useEffect(() => {
    setSettingData(settings)
  }, [settings])
  return (
    <>
      <Header />
      <Home settingData = {settingData}/>
      <Invest settingData = {settingData} />
      <Monitoring settingData = {settingData} />
      <Footer />
    </>
  );
}

import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";

//store
import { getBlog } from "store/actions";

//component
import { Loading } from "Component/InputElements";

 function Detailsmod(props) {
  const dispatch = useDispatch();
  const { blog, loading } = useSelector((state) => state.Blogs);

  useEffect(() => {
    if (props.detail?._id) {
      dispatch(getBlog(props.detail._id));
    }
  }, [props.detail?._id]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add_card_mod blog_modal"
    >
      <Modal.Body>
        <div className="modal_connect position-relative">
          <a
            href="javascript:void(0)"
            className="close-modal"
            onClick={props.onhide}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 2.25C6.624 2.25 2.25 6.624 2.25 12C2.25 17.376 6.624 21.75 12 21.75C17.376 21.75 21.75 17.376 21.75 12C21.75 6.624 17.376 2.25 12 2.25ZM12 3.75C16.5653 3.75 20.25 7.43475 20.25 12C20.25 16.5653 16.5653 20.25 12 20.25C7.43475 20.25 3.75 16.5653 3.75 12C3.75 7.43475 7.43475 3.75 12 3.75ZM9.165 8.085L8.085 9.165L10.923 12L8.0865 14.835L9.1665 15.915L12 13.0778L14.835 15.9127L15.915 14.835L13.0778 12L15.9127 9.165L14.835 8.085L12 10.923L9.165 8.0865V8.085Z"
                fill="#EA3943"
              />
            </svg>
          </a>

          {loading ? (
            <Loading />
          ) : (
            <div className="details_wrap">
              <div className="details_img">
                <img src={blog?.blogImage?.link || "/images/memone.png"} className="img-fluid" alt="" />
              </div>
              <div className="details_content mt-4">
                <h5>{blog?.title}</h5>
                <p>{blog?.content}</p>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}


export default React.memo(Detailsmod)
export const EDIT_PROFILE = "EDIT_PROFILE"
export const PROFILE_SUCCESS = "PROFILE_SUCCESS"
export const PROFILE_ERROR = "PROFILE_ERROR"

export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL"

export const VALIDATE_WALLET = "VALIDATE_WALLET"
export const VALIDATE_WALLET_SUCCESS = "VALIDATE_WALLET_SUCCESS"
export const VALIDATE_WALLET_FAIL = "VALIDATE_WALLET_FAIL"

import { UPLOAD_FILE, REMOVE_FILE, API_FAIL, UPLOAD_FILE_REQUEST, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAIL } from "./actionTypes"

const INIT_STATE = {
  loading: false,
  error: "",
}

const File = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPLOAD_FILE_REQUEST:
      return {
        ...state,
        error: "",
        loading: true
      }
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false
      }
    case UPLOAD_FILE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    case REMOVE_FILE:
      return {
        ...state,
        error: "",
      }

    case API_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    default:
      return state
  }
}

export default File

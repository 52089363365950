import React from "react";
import Headerlogin from "Component/Header/Headerlogin";
import Stocks from "Pages/Profile/Stocks";

export default function Stockstheme() {
  return (
    <>
      <Headerlogin heading="Stocks" />
      <Stocks />
    </>
  );
}

import {
  SEND_OTP,
  SEND_OTP_SUCCESSFUL,
  SEND_OTP_FAILED
} from "./actionTypes"

export const sendOtp = (user, callback) => {
  return {
    type: SEND_OTP,
    payload: { user, callback },
  }
}

export const sendOtpSuccessful = user => {
  return {
    type: SEND_OTP_SUCCESSFUL,
    payload: user,
  }
}

export const sendOtpFailed = user => {
  return {
    type: SEND_OTP_FAILED,
    payload: user,
  }
}
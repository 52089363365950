import { call, put, takeLatest } from "redux-saga/effects";

import * as TYPES from "./actionTypes";
import * as ACTION from "./actions";
import * as API from "helpers/backend_helper";

function* getSettings({ payload }) {
  try {
    const response = yield call(API.getSettingsApi, payload);
    if (response.status == "failure") {
      return yield put(ACTION.getSettingsFail(response.message));
    }

    yield put(ACTION.getSettingsSuccess(response));
  } catch (error) {
    yield put(ACTION.getSettingsFail(error));
  }
}

function* settingsSaga() {
  yield takeLatest(TYPES.GET_SETTINGS, getSettings);
}

export default settingsSaga;

import * as TYPES from "./actionTypes"

const initialState = {
  message: null,
  loading: false,
  orders: [],
  totalOrders: 0,
  leaderboard: [],
  IncomeOutcome: {},
  leaderboardCount: 0,
}

const order = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.OPEN_ORDER:
      return {
        ...state,
        loading: true,
      }

    case TYPES.OPEN_ORDER_SUCCESSFUL:
      return {
        ...state,
        loading: false
      }

    case TYPES.OPEN_ORDER_FAILED:
      return {
        ...state,
        loading: false
      }
    case TYPES.UPDATE_ORDER:
      return {
        ...state,
        loading: true,
      }

    case TYPES.UPDATE_ORDER_SUCCESSFUL:
      return {
        ...state,
        loading: false
      }

    case TYPES.UPDATE_ORDER_FAILED:
      return {
        ...state,
        loading: false
      }
    /* Get orders List */
    case TYPES.GET_ORDERS:
      return {
        ...state,
        loading: true,
      }

    case TYPES.GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload.data || [],
        totalOrders: action.payload.totalCount || 0,
        loading: false,
        error: "",
      }

    case TYPES.GET_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    /* Get orders List END */
    
    /* Get Leaderboard */
    case TYPES.GET_LEADERBOARD:
      return {
        ...state,
        loading: true,
      }

    case TYPES.GET_LEADERBOARD_SUCCESS:
      return {
        ...state,
        leaderboard: action.payload.data || [],
        IncomeOutcome: action.payload.IncomeOutcome || {},
        leaderboardCount: action.payload.totalCount || 0,
        loading: false,
        error: "",
      }

    case TYPES.GET_LEADERBOARD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    /* Get Leaderboard END */

    default:
      return state
  }
}

export default order

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import toastr from "toastr";
import {
  FormGroup,
  Label,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

///helper
import useWallet from "Hooks/wallet";
import useExitPrompt from "Hooks/useExitPrompt";

///store
import {
  editBotRequest,
  getTradeTokenRequest,
  makeBotRequest,
} from "store/actions";

//Component
import BotForm from "Component/Bot/BotForm";
import Web3Intraction from "utils/web3Intraction1";

const Bot = ({ funds }) => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const query = searchParams.get("type");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const wallet = useWallet();

  //selector
  const { user } = useSelector((state) => state.Login);
  const { loading, orderDetails } = useSelector((state) => state.Bot);
  const { tradeTokens } = useSelector((state) => state.Crypto);
  const { settings } = useSelector((state) => state.Settings);

  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [fields, setFields] = useState({
    position: "long",
    tokenAmount: 0,
    stopLoss: 0,
    targetPrice: 0,
    buyPrice: 0,
    leverage: 1,
    collateral: "",
    currentTicker: "",
    userId: "",
    tokenId: "",
    type: "GTT",
    status: "pending",
    balance: "",
  });
  const [botLoading, setBotLoading] = useState(false);

  useEffect(() => {
    return () => {
      setShowExitPrompt(false);
    };
  }, []);

  useEffect(() => {
    dispatch(
      getTradeTokenRequest({
        page: 1,
        limit: 10,
      })
    );

    return () => {
      funds.selectToken(null);
      handleClear();
    };
  }, []);

  useEffect(() => {
    const entryPrice = funds?.bitFinexValue;
    const contractSize = (fields.tokenAmount * fields.leverage) / entryPrice;
    const totalValueOfTrade = contractSize * entryPrice;

    const im = (contractSize * entryPrice) / fields.leverage;
    const imr = im / totalValueOfTrade;
    // const mmr = 0.5;

    let liquidationPrice = entryPrice * (1 - imr);

    if (fields.position == "short") {
      liquidationPrice = entryPrice * (1 + imr);
    }

    setFields((prevState) => ({
      ...prevState,
      liquidationPrice,
      collateral: contractSize,
    }));
  }, [
    fields.position,
    fields.tokenAmount,
    funds.bitFinexValue,
    fields.leverage,
  ]);

  useEffect(() => {
    if (settings && wallet.account && wallet.provider && funds.selectToken) {
      getTokenValue();
    }
  }, [settings, wallet, funds.selectToken]);

  useEffect(() => {
    if (orderDetails && query) {
      if (query === "copy") {
        funds.selectToken(orderDetails.tokenId);
      }
      setFields((pre) => ({
        ...pre,
        ...orderDetails,
        tokenId: orderDetails.tokenId?._id,
        userId: orderDetails.userId?._id,
      }));
    }
  }, [JSON.stringify(orderDetails), query]);

  const handleChange = (e) => {
    if (loading || botLoading) return;

    const { name, value } = e.target;
    setFields((pre) => ({
      ...pre,
      [name]: value,
    }));
  };

  const validateFeilds = () => {
    if (fields.tokenAmount <= 0) {
      return toastr.error("Qty should be greater then zero!");
    }

    if (!wallet.account) {
      return toastr.error("Please connect metamask first!");
    }

    if (Number(fields.tokenAmount) > Number(tokenBalance)) {
      return toastr.error("You don't have enought token to open GTT position!");
    }

    // if (Number(fields.collateral) > Number(balance)) {
    //   return toastr.error("you don't have enought balance for taking GTT!");
    // }

    if (!fields.stopLoss || !fields.buyPrice || !fields.targetPrice) {
      return toastr.error("All Price Fields Required!");
    }

    if (fields.position === "long") {
      if (Number(fields.buyPrice) >= Number(funds.bitFinexValue)) {
        return toastr.error(
          "Buy Amount must be less then or equal to token current amount!"
        );
      }

      if (Number(fields.stopLoss) < Number(funds.buyPrice)) {
        return toastr.error(
          "Stop Loss Amount must be less then token current amount!"
        );
      }

      if (Number(fields.targetPrice) > Number(funds.buyPrice)) {
        return toastr.error(
          "Target Amount must be less then or equal to token current amount!"
        );
      }
    } else if (fields.position === "short") {
      if (Number(fields.buyPrice) <= Number(funds.bitFinexValue)) {
        return toastr.error(
          "Buy Amount must be less then or equal to token current amount!"
        );
      }

      if (Number(fields.stopLoss) > Number(funds.buyPrice)) {
        return toastr.error(
          "Stop Loss Amount must be less then token current amount!"
        );
      }

      if (Number(fields.targetPrice) < Number(funds.buyPrice)) {
        return toastr.error(
          "Target Amount must be less then or equal to token current amount!"
        );
      }
    }

    return null;
  };

  const getTokenValue = async () => {
    const web3Intraction = new Web3Intraction(
      "ethereum",
      wallet.provider,
      settings
    );
    if (funds.selectedToken && wallet.account) {
      const tokenBalance = await web3Intraction.getTokenPrice(
        funds.selectedToken.networkMode == "testnet"
          ? funds.selectedToken.testnetContractAddress
          : funds.selectedToken.contractAddress,
        wallet.account,
        funds.selectedToken.decimals
      );
      setTokenBalance(tokenBalance);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let error = validateFeilds();
    if (error) return;

    try {
      const web3Intraction = new Web3Intraction(
        "ethereum",
        wallet.provider,
        settings
      );

      if (!funds.selectedToken && !web3Intraction)
        return toastr.error("Something went wrong!");

      // return

      setBotLoading(true);
      setShowExitPrompt(true);
      const checkAllowanceData = {
        marginAmount: fields.tokenAmount,
        tokenAddress:
          funds.selectedToken?.networkMode == "testnet"
            ? funds.selectedToken?.testnetContractAddress
            : funds.selectedToken?.contractAddress,
        tradeContractAddress: funds.selectedToken?.tradeContractAddress,
      };

      await web3Intraction.checkAllowance(checkAllowanceData);

      const callback = async (response) => {
        setBotLoading(false);
        setShowExitPrompt(false);

        if (response.status == "success") {
          getTokenValue();
          toastr.success("GTT Position Successfully!");
          navigate("/positions");
          return;
        } else {
          toastr.error(response.message);
          return;
        }
      };

      const data = {
        position: fields.position,
        leverage: fields.leverage,
        tokenAmount: fields.tokenAmount,
        buyPrice: fields.buyPrice,
        targetPrice: fields.targetPrice,
        stopLoss: fields.stopLoss,
        type: "GTT",
        collateral: fields.collateral,
        liquidationPrice: fields.liquidationPrice,
        currentTicker: funds.bitFinexValue,
        userId: user._id,
        tokenId: funds.selectedToken._id,
        tokenAddress:
          funds.selectedToken?.networkMode == "testnet"
            ? funds.selectedToken?.testnetContractAddress
            : funds.selectedToken?.contractAddress,
        orderType: "market",
        status: "pending",
      };

      if (query === "edit") {
        if (!orderDetails) {
          setBotLoading(false);
          setShowExitPrompt(false);
          toastr.error("Failed to fetch old data");
          return;
        }

        data._id = orderDetails._id;
        dispatch(editBotRequest(data, callback));
      } else {
        data.reqId = Math.floor(100000 + Math.random() * 900000);
        dispatch(makeBotRequest(data, callback));
      }
    } catch (error) {
      console.log("err in submit func", error);
      setBotLoading(false);
      setShowExitPrompt(false);
      return toastr.error("Something went wrong!");
    }
  };

  const handleClear = (e, type) => {
    e && e.preventDefault();
    if (loading || botLoading) return;

    if (type === "cancel") {
      navigate("/bot");
    }

    setFields((pre) => ({
      ...pre,
      tokenAmount: 0,
      stopLoss: 0,
      targetPrice: 0,
      buyPrice: 0,
      leverage: 1,
      collateral: "",
      currentTicker: "",
      userId: "",
      tokenId: "",
      type: "GTT",
      status: "open",
      position: "long",
    }));
  };

  return (
    <section className="botSection">
      <div className="bot_header d-flex align-items-center justify-content-between px-4 py-3 border-bottom border-light border-opacity-25">
        <div className="bot_header_left d-flex align-items-center gap-3">
          <h4>{funds.selectedToken?.name || ""}</h4>

          {funds.selectedToken && <p>{funds.bitFinexValue || "--"} USDT</p>}
        </div>

        <div className="bot_header_right">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 24 24"
            >
              <path
                fill="#fff"
                d="M12 17q.425 0 .713-.288T13 16v-4q0-.425-.288-.713T12 11q-.425 0-.713.288T11 12v4q0 .425.288.713T12 17Zm0-8q.425 0 .713-.288T13 8q0-.425-.288-.713T12 7q-.425 0-.713.288T11 8q0 .425.288.713T12 9Zm0 13q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Zm0-2q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 3.35 2.325 5.675T12 20Zm0-8Z"
              />
            </svg>
          </span>
        </div>
      </div>

      <div className="trasaction_types d-flex align-items-start gap-5 px-4 py-3 border-bottom border-light border-opacity-25">
        <div className="trasaction_type">
          <h6 className="tra_head">Transaction type</h6>

          <div className="bot_dropdown">
            <UncontrolledDropdown>
              <DropdownToggle>
                <div className="drop_wrap d-flex align-items-center">
                  <div className="chain_icon">
                    {funds.selectedToken?.icon && (
                      <img
                        src={funds.selectedToken?.icon}
                        alt=""
                        className="img-fluid"
                      />
                    )}
                  </div>
                  {funds.selectedToken?.name || "Select Token"}
                  <span className="ms-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#ccc"
                        d="m8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z"
                      />
                    </svg>
                  </span>
                </div>
              </DropdownToggle>
              {query === "edit" && fields._id ? (
                ""
              ) : (
                <DropdownMenu>
                  {tradeTokens &&
                    tradeTokens.map((token, idx) => (
                      <DropdownItem key={idx}>
                        <div
                          className="d-flex"
                          onClick={() => funds.selectToken(token)}
                        >
                          <div className="chain_icon">
                            <img
                              src={token.icon}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          {token.name}
                        </div>
                      </DropdownItem>
                    ))}
                </DropdownMenu>
              )}
            </UncontrolledDropdown>
          </div>

          {funds.selectedToken && (
            <FormGroup tag="fieldset">
              <div className="radio_common-btn d-flex mt-3 gap-3">
                {[
                  { value: "long", label: "Long" },
                  { value: "short", label: "Short" },
                ].map((data, index) => (
                  <FormGroup check key={index}>
                    <Label check>
                      <Input
                        type="radio"
                        name="radio1"
                        checked={fields.position === data.value}
                        onClick={(e) =>
                          handleChange({
                            target: {
                              name: "position",
                              value: data.value,
                            },
                          })
                        }
                      />{" "}
                      {data.label}
                    </Label>
                  </FormGroup>
                ))}
              </div>
            </FormGroup>
          )}
        </div>
        <div className="trigger_type">
          <h6 className="tra_head">Trigger type</h6>

          <p className="text-white">
            The trigger price is the point at which your buy or sell order is
            made available to the exchange servers for processing.
          </p>
        </div>
      </div>

      {funds.selectedToken && (
        <>
          <BotForm
            fields={fields}
            handleChange={handleChange}
            balance={tokenBalance}
            handleSubmit={handleSubmit}
            botLoading={loading || botLoading}
            query={query}
            handleClear={handleClear}
            symbol={funds.selectedToken?.symbol}
            minTokenAmount={funds.selectedToken?.minTokenAmount}
            currentPrice={funds.bitFinexValue}
          />
        </>
      )}
    </section>
  );
};

export default Bot;

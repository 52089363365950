// LOGIN
export const POST_LOGIN = "/user/login";
export const POST_LOGIN_WITH_WALLET = "/user/login-with-wallet";
export const POST_LOGIN_OTP = "/user/loginwithotp";
export const GET_CURRENT_USER = "/user/profile";
export const CHANGE_PASSWORD = "/user/changepassword";
export const PUT_PROFILE = "/user/updateProfile";

export const VALIDATE_WALLET = "/user/validateWallet";

// SIGNUP
export const POST_REGISTER = "/user/register";

// two factor
export const GET_TFA_OTP = "/user/tfa/generate";
export const POST_TFA_VERIFY_OTP = "/user/tfa/verify";
export const POST_TFA_VALIDATE_OTP = "/user/tfa/validate";
export const POST_TFA_DISABLE_OTP = "/user/tfa/disable";

//verify OTP
export const POST_VERIFY_OTP = "/user/verifyotp";

//Send OTP
export const POST_SEND_OTP = "/user/sendotp";

//Forget Password
export const FORGET_PASSWORD = "/user/forgotpassword";

//Get All Pairs
export const GET_ALL_PAIRS_LIST = "/pair/user/getAllPairs";

//Trade tokens
export const GET_TRADE_TOKENS = "/tradeToken/list";
export const GET_TRADE_DETAILS = "/tradeToken/view";
export const GET_TOKEN_CURRENT_PRICE = "/tradeToken/token-current-price";

// File
export const POST_FILE = "/file/add";
export const POST_REMOVE_FILE = "/file/remove";
// Settings
export const GET_SETTINGS = "/setting/view";

//Transactions
export const GET_TRANSACTIONS = "/transaction/list/history";
export const ADD_TRANSACTION = "/transaction/add/history";
export const ADD_INVESTMENT = "/transaction/add/investment";

export const GET_TOP_TRADE_PAIRS = "/pair/user/topTradePairs";
export const GET_PAIR_DETAILS = "/pair/user/view";

export const ADD_STAKE_AMOUNT = "/stake/user/add";
export const UNSTAKE_AMOUNT = "/stake/user/unstake";
export const GET_STAKE_TOKENS = "/stakeToken/list";
export const GET_STAKE_LIST = "/stake/user/list";

export const OPEN_ORDER = "/order/user/open-order";
export const UPDATE_ORDER = "/order/user/update-order";
export const GET_ORDERS = "/order/user/list";
export const GET_LEADERBOARD = "/order/user/leaderborad";

//Experts
export const GET_EXPERTS = "/user/expert/list";

//Post
export const ADD_BLOG = "/blog/add";
export const GET_BLOGS = "/blog/list";
export const DELETE_BLOG = "/blog/remove";
export const GET_BLOG = "/blog/view/";
export const UPDATE_BLOG = "/blog/update";

///get candleList

export const GET_CANDLES_LIST = "/tradeToken/candle/list";

///Bot Apis
export const MAKE_BOT_REQUEST = "/bot/user/request";
export const EDIT_BOT_REQUEST = "/bot/user/edit-request";
export const CANCEL_BOT_REQUEST = "/bot/user/cancel-request";
export const GET_BOT_REQUESTS = "/bot/user/request-list";

import axios from "axios"
import authHeader from "./jwt-token-access/auth-token-header"

import store from "store"
import { showAlert } from "store/actions"

//apply base url for axios
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const API_VERSION = process.env.REACT_APP_API_VERSION
export const SOCKET_URL = process.env.REACT_APP_API_SOCKET_URL

const axiosApi = axios.create({
  baseURL: API_BASE_URL + API_VERSION,
})

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
)

export const updateToken = (token) => {
  axiosApi.defaults.headers.common["Authorization"] = "Bearer " + token
}

const obj = JSON.parse(localStorage.getItem("authUser"))

updateToken(obj?.data?.Token ? obj.data.Token : null)

const LogoutUser = () => {
  localStorage.clear()
  window.location.replace("/")
}

export async function get(url, config) {
  if (!config) {
    config = authHeader()
  }

  return await axiosApi.get(url, { ...config }).then((response) => {
    if (response.data.status === "failure") {
      if (response.data.isInvalidToken) {
        return LogoutUser()
      } else if (response.data.message === "Invalid Login Credential!") {
        LogoutUser()
        return { status: "failure" }
      } else if (response.data.message === "ACCESS_DENIED") {
        store.dispatch(showAlert())
      }
    }

    return response.data
  })
}

export async function post(url, data, config) {
  if (!config) {
    config = authHeader()
  }

  return axiosApi.post(url, data, { ...config }).then((response) => {
    if (response.data.status === "failure") {
      if (response.data.message === "INVALID_TOKEN") {
        LogoutUser()
        return { status: "failure" }
      } else if (response.data.message === "ACCESS_DENIED") {
        store.dispatch(showAlert())
      }
    }

    return response.data
  })
}

export async function put(url, data, config) {
  if (!config) {
    config = authHeader()
  }

  return axiosApi.put(url, { ...data }, { ...config }).then((response) => {
    if (response.data.status === "failure") {
      if (response.data.message === "INVALID_TOKEN") {
        LogoutUser()
        return { status: "failure" }
      } else if (response.data.message === "ACCESS_DENIED") {
        store.dispatch(showAlert())
      }
    }

    return response.data
  })
}

export async function del(url, data, config) {
  if (!config) {
    config = authHeader()
  }
  return await axiosApi.delete(url, { data: data }, { ...config }).then((response) => {
    if (response.data.status === "failure") {
      if (response.data.message === "INVALID_TOKEN") {
        LogoutUser()
        return { status: "failure" }
      } else if (response.data.message === "ACCESS_DENIED") {
        store.dispatch(showAlert())
      }
    }

    return response.data
  })
}

import React, { useEffect, useState } from "react";
import { Row, Col, Spinner } from "reactstrap";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getTransaction } from "store/actions";
import CustomPagination from "Component/CustomPagination";

const MyPool = () => {
  const { loading, total, transactions } = useSelector(
    ({ Transaction }) => Transaction
  );
  const { user } = useSelector(({ Login }) => Login);
  const dispatch = useDispatch();
  const [mypool, setmypool] = useState(true);
  const [filters, setFiters] = useState({
    page: 1,
    limit: 10,
    fields: [
      {
        fieldName: "transType",
        fieldValue: "add_liquidity",
      },
    ],
  });
  useEffect(() => {
    if (user) {
      dispatch(getTransaction({ ...filters, user: user?._id }));
    }
  }, [user, filters]);

  const onPageChange = (page) => {
    setFiters((prev) => ({ ...prev, page }));
  };
  const totalPages = Math.ceil(total / filters?.limit);

  return (
    <Fade>
      <>
        {mypool ? (
          <>
            <div className="Liquidity-main">
              <div className="KYC-table">
                <div className="d-flex justify-content-center">
                  {loading && <Spinner style={{ color: "#fff" }} />}
                </div>
                {!loading && (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Token A Amount</th>
                        <th>Token B Amount</th>
                        <th>Transaction Hash</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(transactions || []).map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td>
                              <Link
                                to=""
                                className="linked"
                                // onClick={() => setmypool(false)}
                              >
                                <div className="name_itmes">
                                  <img
                                    src={`${item?.tokenA?.icon}`}
                                    alt=""
                                    width="30px"
                                    className="img-fluid"
                                  />
                                  -
                                  <img
                                    src={`${item?.tokenB?.icon}`}
                                    width="30px"
                                    alt=""
                                    className="img-fluid"
                                  />
                                  <p>
                                    {item?.tokenA?.symbol}-{" "}
                                    {item?.tokenB?.symbol}
                                  </p>
                                </div>
                              </Link>
                            </td>
                            <td>{item?.tokenA?.tokenAmount}</td>
                            <td>{item?.tokenB?.tokenAmount}</td>
                            <td title={item?.transactionHash}>
                              {" "}
                              <a
                                target="_blank"
                                href={`${item?.explorerUrl}tx/${item?.transactionHash}`}
                              >
                                {item?.transactionHash?.slice(0, 30) + "..."}
                              </a>
                            </td>
                            <td>{item?.receiptStatus}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
                <div className="d-flex justify-content-end">
                  <CustomPagination
                    currentPage={filters?.page}
                    totalPages={totalPages}
                    onPageChange={(page) => onPageChange(page)}
                    maxPagesToShow={5}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <Fade>
              <div className="BTC_wrap">
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <div className="btc_wrap_box">
                      <div className="my_pool_btc">
                        <div className="mt-pool-left">
                          <img
                            src="/images/yellowbitcoin.png"
                            alt=""
                            className="img-fluid"
                          />
                          <h3>BTC</h3>
                        </div>
                        <span className="slash">/</span>
                        <div className="mt-pool-right">
                          <img
                            src="/images/pooltool.png"
                            alt=""
                            className="img-fluid"
                          />

                          <h3>MU</h3>
                        </div>
                      </div>
                      <p className="code-pool">
                        oxtervdhbdudgfy54fdbd4b14fd4b4f
                      </p>

                      <ul className="pool_liq_list">
                        <li>
                          <p>Liquidity</p>
                          <p>
                            <strong>$49,984</strong>
                          </p>
                        </li>
                        <li>
                          <p>Volume (24H)</p>
                          <p>
                            <strong>$0</strong>
                          </p>
                        </li>
                        <li>
                          <p>Fees (24H)</p>
                          <p>
                            <strong>$0</strong>
                          </p>
                        </li>
                        <li>
                          <p>APR</p>
                          <p>
                            <strong>0%</strong>
                          </p>
                        </li>
                      </ul>

                      <ul className="pool_liq_list pool_Share_list  ">
                        <li>
                          <p>Your Pool share</p>
                          <p>
                            <strong>99.99999%</strong>
                          </p>
                        </li>
                        <li>
                          <p>Your Pool Tokens</p>
                          <p>
                            <strong>0.15811</strong>
                          </p>
                        </li>
                        <li>
                          <p>Pooled USD</p>
                          <p>
                            <strong>25,000</strong>
                          </p>
                        </li>
                        <li>
                          <p>Pooled MU</p>
                          <p>
                            <strong>1,0000,000</strong>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </Fade>
          </>
        )}
      </>
    </Fade>
  );
};

export default MyPool;

import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "socket";
import toastr from "toastr";

// importing all the themes
import Hometheme from "../themes/home-theme";
import Signup from "themes/signup-theme";
import Signintheme from "themes/signin-theme";
import TfaVerifyTheme from "themes/tfa-verify-theme";
// import Kyctheme from "themes/kyc-theme";
import OTPtheme from "themes/otp-theme";
import Verifyfeildtheme from "themes/verifyfeild-theme";
// import Myprofile from "themes/myprofile-theme";

import Dashboardpagetheme from "themes/dashboard-theme";
import Liquditytheme from "themes/liqudity-theme";
import Wallettheme from "themes/wallettheme";
// import Tradetheme from "themes/trade-theme";
// import Tradelistingtheme from "themes/tradelisting-theme";
import Leveragetradetheme from "themes/leveragetrade-theme";
import Tradeexpertstheme from "themes/tradeexperts-theme";
import Stockstheme from "themes/stocks-theme";
import StocksViewtheme from "themes/stocks-view-theme";
import Expertsprofiletheme from "themes/expertsprofile-theme";
import Buyplatformtheme from "themes/buyplatform-theme";
import Blogtheme from "themes/blog-theme";
import Tradelistileverage from "themes/tradelistileverage-theme";
import Buyselltheme from "themes/buysell-theme";
import Stakingtheme from "themes/staking-theme";
import Settingtheme from "themes/setting-theme";
// import OTCtradetheme from "themes/OTCtrade-theme";
// import Accounttheme from "themes/accounttheme";
// import Pricealertheme from "themes/pricealertheme";
// import Privacytheme from "themes/privacy-theme";

import OTCtradetheme from "themes/OTCtrade-theme";
import PositionTheme from "themes/positiontheme";
import Bottheme from "themes/bot-theme";
import Leaderboardtheme from "themes/leaderboardtheme";

import { Private as PrivateRoute, Auth as AuthRoute } from "Component/Route";
import { getOrders, getSettings, currentUser } from "store/actions";

// profile routes
import ForgetPassword from "Pages/ForgetPassword";
import Pagenotfound from "Pages/404page";

const MyRouts = () => {
  const dispatch = useDispatch();

  const { user } = useSelector(({ Login }) => Login);

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    if (user?._id) {
      dispatch(currentUser());
    }
  }, [user?._id]);

  //Listen socket on auto open, close(on stop loss and trigger price) order
  useEffect(() => {
    if (user?._id && socket) {
      const filters = {
        page: 1,
        limit: 10,
        orderBy: "date_created",
        order: -1,
        user: user?._id,
      };
      socket.on("connect", (res) => {
        console.log("yes it connected in position", socket.id);
        socket.emit("manageStableConnection", {
          userId: user?._id,
          socketId: socket.id,
        });
      });
      socket.on("OpenScheduledOrder", (res) => {
        console.log("Open Scheduled Order Successfully");
        dispatch(getOrders({ ...filters }));
        toastr.success("Open Scheduled Order Successfully!");
      });
      socket.on("CloseOnStopLoss", (res) => {
        console.log("Order closed successfully. Stop Loss hit!");
        dispatch(getOrders({ ...filters }));
        toastr.success("Order closed successfully. Stop Loss hit!");
      });
      socket.on("CloseOnStopLossByAdmin", (res) => {
        console.log("Order closed successfully. Stop Loss goes 90%!");
        dispatch(getOrders({ ...filters }));
        toastr.success("Order closed successfully. Stop Loss goes 90%!");
      });
      socket.on("CloseOnTriggerPrice", (res) => {
        console.log("Order closed successfully. Target hit!");
        dispatch(getOrders({ ...filters }));
        toastr.success("Order closed successfully. Target hit!");
      });
      socket.on("user-disconnected", (e) => {
        console.log("User Disconnected", e);
        socket.connect();
      });
      socket.on("disconnect", (e) => {
        console.log("Disconnected", e);
        socket.connect();
      });

      return () => {
        socket.on("disconnect", (e) => {
          console.log("Disconnected", e);
        });
      };
    }
  }, [socket, user]);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthRoute />}>
          <Route path="/" element={<Hometheme />} />
          <Route path="/sign-up" element={<Signup />} />
          <Route path="/sign-in" element={<Signintheme />} />
          <Route path="/sign-in/validate" element={<TfaVerifyTheme />} />

          {/* <Route path="/kyc" element={<Kyctheme />} /> */}
          <Route path="/otp" element={<OTPtheme />} />
          <Route path="/otp-verify" element={<Verifyfeildtheme />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboardpagetheme />} />
          <Route path="/wallet" element={<Wallettheme />} />
          {/* <Route path="/trade" element={<Tradetheme />} />
            <Route path="/trade-listing" element={<Tradelistingtheme />} /> */}
          <Route path="/liquidity-pool" element={<Liquditytheme />} />
          <Route path="/leverage-trade" element={<Leveragetradetheme />} />
          <Route path="/bot" element={<Bottheme />} />
          <Route path="/trading-experts" element={<Tradeexpertstheme />} />
          <Route path="/stocks" element={<Stockstheme />} />
          <Route path="/stocks/:symbol" element={<StocksViewtheme />} />

          <Route path="/expert-profile/:id" element={<Expertsprofiletheme />} />

          <Route path="/buy-platform" element={<Buyplatformtheme />} />
          <Route path="/buy-token" element={<Buyselltheme />} />

          <Route path="/blog" element={<Blogtheme />} />
          <Route path="/leverage/:id" element={<Tradelistileverage />} />
          <Route path="/staking" element={<Stakingtheme />} />

          <Route path="/profile" element={<Settingtheme />} />
          <Route path="/positions" element={<PositionTheme />} />
          <Route path="/leaderboard" element={<Leaderboardtheme />} />
          <Route path="/otc-trade" element={<OTCtradetheme />} />
        </Route>

        {/* <Route path="/myaccount" element={<Accounttheme />} />
              <Route path="/price-alert" element={<Pricealertheme />} />

              <Route path="/privacy-policy" element={<Privacytheme />} /> */}

        <Route path="*" element={<Pagenotfound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MyRouts;

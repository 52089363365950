import React from "react";

import Signuppage from "Pages/Signup";

export default function Signup() {
  return (
    <>
      <Signuppage />
    </>
  );
}

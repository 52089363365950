import React, { useState, useEffect } from "react";
import { Container, Table, Spinner, Button } from "react-bootstrap";
import CustomPagination from "Component/CustomPagination";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import {
  getTradeTokenRequest,
  getOrders,
  setRequestDetail,
} from "store/actions";

const InvestmentList = ({ id }) => {
  const { totalOrders, orders, loading } = useSelector(({ Order }) => Order);
  const { tradeTokens } = useSelector(({ Crypto }) => Crypto);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [newOrders, setNewOrders] = useState(orders);

  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    orderBy: "date_created",
    order: -1,
    userId: id,
    isInvestment: "yes",
  });

  //set orders into new state
  useEffect(() => {
    setNewOrders(orders);
  }, [orders]);

  //get order and token data
  useEffect(() => {
    if (id) {
      dispatch(getOrders({ ...filters }));
      dispatch(getTradeTokenRequest());
    }
  }, [id, filters]);

  //pagination
  const onPageChange = (page) => {
    setFilters((prev) => ({ ...prev, page }));
  };

  const totalPages = Math.ceil(totalOrders / filters?.limit);

  //get token details using contract address
  function getTokenDetailsByContractAddress(tokensList, contractAddress) {
    try {
      for (const token of tokensList) {
        if (
          token &&
          token.contractAddress &&
          (token.contractAddress === contractAddress ||
            token.testnetContractAddress === contractAddress)
        ) {
          return token;
        }
      }
      return null; // Return null if the contractAddress is not found in the list
    } catch (e) {
      return null;
    }
  }

  const handleCopyData = (data) => {
    if (data.type === "GTT") {
      navigate("/bot?type=copy");
      dispatch(setRequestDetail(data));
    }
    else{
      navigate(`/leverage/${data.tokenId?._id}?type=copy`);
      dispatch(setRequestDetail(data));
    }
  };

  return (
    <section className="trade_new my-3">
      <Container fluid>
        {/* <Searchpair searchKeyword={searchKeyword} /> */}
        <div className="shared_head text-start mb-3 mt-3">
          <h3 className="card_heading">Position</h3>
          {/* <p  className="buy_show">Shows latest Tokens in Leverage with useful information</p> */}
        </div>
        <section className="Opendertable-table pairprice_chart mt-3">
          <div className="pairshared_table">
            <div className="d-flex justify-content-center">
              {loading && <Spinner style={{ color: "#fff" }} />}
            </div>
            <Table responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Invested Amount</th>
                  <th>Leverage</th>
                  <th>Total Amount</th>
                  <th>Open Price</th>
                  <th>Position</th>
                  <th>P&L</th>
                  <th>Status</th>
                  <th>Created Date</th>
                  <th>Action</th>
                  {/* <th>Price USD</th> */}
                </tr>
              </thead>
              <tbody>
                {!newOrders.length ? (
                  <tr>
                    <td className="text-center mt-3 text-white" colSpan={12}>
                      No Data Found
                    </td>
                  </tr>
                ) : (
                  newOrders.map((item) => {
                    return (
                      <tr
                        key={item?._id}
                        class={`${item.status == "open" && "open-position"}`}
                      >
                        <td>
                          {
                            getTokenDetailsByContractAddress(
                              tradeTokens,
                              item?.tokenAddress
                            )?.name
                          }
                        </td>

                        <td>{item?.tokenAmount}</td>
                        <td>{item?.leverage}</td>
                        <td>
                          {Number(item?.tokenAmount) * Number(item?.leverage) +
                            Number(item?.tokenAmount)}
                        </td>
                        <td>{item?.currentTicker}</td>
                        <td>{item?.position}</td>
                        <td class={`${item?.PNL < 0 ? "danger" : "success"}`}>
                          {(item?.PNL && Number(item?.PNL).toFixed(3)) || 0}
                        </td>
                        <td>
                          <span className="success-position">
                            {item?.status}
                          </span>
                        </td>
                        <td>
                          {item?.date_created
                            ? moment(item?.date_created).format("YYYY-MM-DD")
                            : ""}
                        </td>
                        <td>
                          <Button
                            className="connect_wallet_cta"
                            onClick={(e) => handleCopyData(item)}
                          >
                            Copy Investment
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
            <div className="d-flex justify-content-end">
              <CustomPagination
                currentPage={filters?.page}
                totalPages={totalPages}
                onPageChange={(page) => onPageChange(page)}
                maxPagesToShow={5}
              />
            </div>
          </div>
        </section>
      </Container>
    </section>
  );
};

export default InvestmentList;

import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";
import * as API from "helpers/backend_helper";

function* registerUser({ payload: { user, callback } }) {
  try {
    const response = yield call(API.postRegister, user);

    callback && callback(response);
    if (response.status == "failure") {
      window.scrollTo(0, 0);
      return yield put(ACTIONS.registerUserFailed(response.message));
    }
    
    yield put(ACTIONS.registerUserSuccessful(response));
  } catch (error) {
    yield put(ACTIONS.registerUserFailed("Internal Server Error"));
  }
}

export function* watchUserRegister() {
  yield takeEvery(TYPES.REGISTER_USER, registerUser);
}

function* accountSaga() {
  yield all([fork(watchUserRegister)]);
}

export default accountSaga;

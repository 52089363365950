import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import toast from "toastr";
import * as ethers from "ethers";
import Onboard from "@web3-onboard/core";

// Onboard wallets
import injectedModule from "@web3-onboard/injected-wallets";
import walletConnectModule from "@web3-onboard/walletconnect";
import coinbaseWalletModule from "@web3-onboard/coinbase";
import ledgerModule from "@web3-onboard/ledger";
import trezorModule from "@web3-onboard/trezor";
import gnosisModule from "@web3-onboard/gnosis";

const walletConnectOptions = {
  projectId: process.env.REACT_APP_ONBOARD_PROJECTID,
  dappUrl: window.location.origin,
};

const injected = injectedModule();
const walletConnect = walletConnectModule(walletConnectOptions);
const coinbaseWalletSdk = coinbaseWalletModule({ darkMode: true });
const ledger = ledgerModule(walletConnectOptions);
const trezor = trezorModule({
  email: "<EMAIL_CONTACT>",
  appUrl: "<APP_URL>",
});
const gnosis = gnosisModule();

export const WalletContext = React.createContext({
  isActive: false,
  account: "",
  balance: 0,
  connect: () => {},
  disconnect: () => {},
  chainId: "",
  walletType: "",
  provider: {},
  switchNetwork: () => {},
  chainDetail: {},
});

const customTheme = {
  "--w3o-background-color": "#1A1D26",
  "--w3o-foreground-color": "#242835",
  "--w3o-text-color": "#EFF1FC",
  "--w3o-border-color": "#33394B",
  "--w3o-action-color": "#929bed",
  "--w3o-border-radius": "16px",
  "--w3o-font-family": "inherit",

  //  " --account-center-position":"top",
};

export const WalletProvider = ({ children }) => {
  const { settings } = useSelector((state) => state.Settings || {});

  const [onboard, setonboard] = useState();
  const [chainDetail, setchainDetail] = useState();
  const [walletType, setwalletType] = useState();
  const [currentWallet, setcurrentWallet] = useState();
  const [isActive, setIsActive] = useState(false);
  const [account, setaccount] = useState("");
  const [chainId, setchainId] = useState();
  const [balance, setbalance] = useState(0);
  const [provider, setprovider] = useState("");
  const [signature, setsignature] = useState("");

  useEffect(() => {
    // console.log(, "<====is mobile")
    if (!onboard && settings && JSON.stringify(settings) !== "{}") {
      const _onboard = Onboard({
        theme: customTheme,
        wallets: [
          injected,
          walletConnect,
          coinbaseWalletSdk,
          ledger,
          trezor,
          gnosis,
        ],
        appMetadata: {
          name: "Leverage It",
          icon: "/images/logo.png",
          description: "Leverage It",
        },

        chains: settings.blockchain.map((item) => {
          const network = item.networkUrl.find(
            (nt) => nt.type === settings.blockchainNetworkMode
          );

          return {
            id: Number(network.chainId),
            token: "ETH",
            label: network.nativeCurrency?.name || "ETH",
            rpcUrl: network.url,
          };
        }),
        notify: {
          enabled: false,
        },
        accountCenter: {
          desktop: {
            enabled: false,
          },
          mobile: {
            enabled: false,
          },
        },
      });

      setonboard(_onboard);
      setchainDetail(
        settings.blockchain.map((item) => {
          const network = item.networkUrl.find(
            (nt) => nt.type === settings.blockchainNetworkMode
          );
          return {
            id: network.chainId,
            token: network.nativeCurrency?.symbol,
            label: network.nativeCurrency?.name,
            rpcUrl: network.url,
          };
        })
      );
    }
  }, [onboard, settings]);

  const fetchWalletBalance = useCallback(async () => {
    if (!currentWallet) return;
    try{
      if (provider) {
        // Do something
        const _provider = new ethers.providers.Web3Provider(provider);
        const balance = await _provider.getBalance(currentWallet[0].address);
        const balanceInEth = ethers.utils.formatEther(balance);
        setbalance(balanceInEth || 0);
      } else {
        console.log("");
      }

    }catch(e){
      console.log("e");

    }
  }, [JSON.stringify(currentWallet), provider]);

  const getWalletBalance = useCallback(
    async (networkUrl, address, callback) => {
      if (!address || !networkUrl) return;
      try {
        const _provider = new ethers.providers.JsonRpcProvider(networkUrl.url);
        const balance = await _provider.getBalance(address);
        const balanceInEth = ethers.utils.formatEther(balance);
        callback && callback(balanceInEth);
        return balanceInEth;
      } catch (error) {
        console.log(error, "<=======error");
        callback && callback(0);
        return 0;
      }
      // Do something
    },
    []
  );

  const switchNetwork = useCallback(
    (chainId) =>
      new Promise(async (resolve, reject) => {
        try {
          const result = await onboard.setChain({
            chainId: Number(chainId),
          });

          if (result) {
            resolve();
          } else {
            reject("User rejected the request");
          }
        } catch (error) {
          reject(error);
        }
      }),
    [onboard]
  );

  const getSign = (provider, account) => {
    return new Promise(async (resolve, reject) => {
      if (localStorage.getItem("signatureToken")) {
        setsignature(localStorage.getItem("signatureToken"));
        resolve();
      } else {
        try {
          let res = await getSignature(provider, account[0].address);
          resolve(res);
          setsignature(res);
        } catch (error) {
          console.log(error, "<===err");
          reject(error);
        }
      }
    });
  };

  // Connect to Wallet
  const connect = useCallback(
    async (_walletType, chainId) => {
      try {
        if (!onboard) return;

        let autoSelect = {
          autoSelect: { label: _walletType, disableModals: true },
        };

        const walletres = await onboard.connectWallet(
          _walletType ? autoSelect : null
        );

        if (chainId) {
          try {
            switchNetwork(chainId);
          } catch (error) {
            toast.error("Error occurred, please contact support");
          }
        }

        return walletres;
      } catch (error) {
        setwalletType("");
        console.log("connsest");
        console.log("Error while wallet connect", error);
      }
    },
    [onboard, switchNetwork]
  );

  const connectSuccess = (wallet) => {
    try {
      console.log("walletwalletwalletwallet",wallet)
      const { accounts, chains, provider: _provider } = wallet;
      setwalletType(wallet.label?.toLowerCase());
      setaccount(accounts[0]?.address);
      setbalance(accounts[0]?.balance?.ETH || 0);
      setIsActive(true);
      setchainId(Number(chains?.[0]?.id));
      setprovider(_provider);
    } catch (err) {
      console.log("err", err);
    }
  };

  // Disconnect from Metamask wallet
  const disconnect = useCallback(async () => {
    setaccount("");
    setbalance("");
    setIsActive(false);
    const [primaryWallet] = await onboard.state.get().wallets;
    if (!primaryWallet) return;
    await onboard.disconnectWallet({ label: primaryWallet.label });
  }, [onboard]);

  const values = useMemo(
    () => ({
      isActive,
      account,
      balance,
      connect,
      connectSuccess,
      fetchWalletBalance,
      disconnect,
      chainId,
      walletType,
      signature,
      provider,
      getSign,
      switchNetwork,
      getWalletBalance,
      chainDetail,
    }),
    [
      isActive,
      account,
      balance,
      chainId,
      walletType,
      fetchWalletBalance,
      onboard,
      signature,
      getSign,
      provider,
      switchNetwork,
      getWalletBalance,
      chainDetail,
      connect,
      disconnect,
    ]
  );

  return (
    <WalletContext.Provider value={values}>{children}</WalletContext.Provider>
  );
};

export const getSignature = async (provider, address) => {
  try {
    provider = new ethers.providers.Web3Provider(provider);
    const signer = provider.getSigner();

    if (provider) {
      address = await signer.getAddress();
    }

    let message = `Welcome to LeverageIT!

Click to sign in and accept the LeverageIT Terms of Service: COMING SOON

Your authentication status will reset after 24 hours.

Wallet address:
${address}`;

    const signature = await signer.signMessage(message);

    // const expectedAddress = await signer.getAddress();

    // console.log("ISSUING SIGNATURE");
    // console.log("ADDR:    ", expectedAddress);
    // console.log();

    // Approach 1
    // const actualAddress = ethers.utils.verifyMessage(message, signature);

    // console.log("APPROACH 1");
    // console.log("EXPECTED ADDR: ", expectedAddress);
    // console.log("ACTUAL ADDR:   ", actualAddress);
    // console.log();

    return signature;
  } catch (err) {
    console.log("error", err);
    // throw new Error(err);
    return err;
  }
};

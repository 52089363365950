import React, { useState } from "react";
import { Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Form, FormGroup, Input } from "reactstrap";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import toastr from "toastr";
import { userForgetPassword } from "store/actions";

const ForgetPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [fields, setFields] = useState({
        email: ""
    })

    const handleChange = (name) => (e) => {
        setFields(prev => ({ ...prev, [name]: e.target.value }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (fields.email == "") {
            return toastr.error("Email is required!")
        }
        dispatch(userForgetPassword(fields, () => {
            console.log("Please check your inbox!")
        }));
    }

    return (
        <Fade>
            <section className="signup_main common_sign_main sign_in">
                <Container>
                    <div className="sign_up_inner">
                        <div className="logo_form mb-4">
                            <img width={"150px"} src="/images/logo.png" alt="" className="img-fluid" />
                        </div>
                        <h2 className="text-center Signup_head">Forget Password</h2>

                        <Form className="signup_form">
                            <FormGroup className="position-relative">
                                <Input
                                    type="email"
                                    id="exampleEmail"
                                    placeholder="Email Id"
                                    onChange={handleChange("email")}
                                />
                                <div className="leftform_icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                    >
                                        <path
                                            d="M4.16663 4.16663H15C15.663 4.16663 16.2989 4.43002 16.7677 4.89886C17.2366 5.3677 17.5 6.00358 17.5 6.66663V14.1666C17.5 14.8297 17.2366 15.4656 16.7677 15.9344C16.2989 16.4032 15.663 16.6666 15 16.6666H4.16663C3.50358 16.6666 2.8677 16.4032 2.39886 15.9344C1.93002 15.4656 1.66663 14.8297 1.66663 14.1666V6.66663C1.66663 6.00358 1.93002 5.3677 2.39886 4.89886C2.8677 4.43002 3.50358 4.16663 4.16663 4.16663ZM4.16663 4.99996C3.74996 4.99996 3.38329 5.14163 3.09996 5.39163L9.58329 9.58329L16.0666 5.39163C15.7833 5.14163 15.4166 4.99996 15 4.99996H4.16663ZM9.58329 10.5916L2.60829 6.06663C2.54163 6.24996 2.49996 6.45829 2.49996 6.66663V14.1666C2.49996 14.6087 2.67555 15.0326 2.98811 15.3451C3.30068 15.6577 3.7246 15.8333 4.16663 15.8333H15C15.442 15.8333 15.8659 15.6577 16.1785 15.3451C16.491 15.0326 16.6666 14.6087 16.6666 14.1666V6.66663C16.6666 6.45829 16.625 6.24996 16.5583 6.06663L9.58329 10.5916Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                            </FormGroup>

                            <Link to="/sign-in" className="forgot_password">
                                Login
                            </Link>
                        </Form>

                        <Button className="btn_common w-100 text-uppercase" onClick={handleSubmit}>Submit</Button>
                    </div>
                </Container>
            </section>
        </Fade>
    );
};

export default ForgetPassword;

import React, { useState } from "react";
import { Row } from "react-bootstrap";
import BlogCard from "./BlogCard";
import Detailsmod from "Component/Modals/Detailsmod";
import { Loading } from "Component/InputElements";

function CardWrapper({
  blogs,
  enableActions,
  handleFormPopup,
  removeBlog,
  loading,
}) {
  const [detailopen, setDetailopen] = useState({
    isOpen: false,
    _id: null,
  });
  const detailsclick = (_id) => {
    setDetailopen((pre) => ({
      ...pre,
      isOpen: !pre.isOpen,
      _id,
    }));
  };

  return (
    <div className="posts_tabocontent">
      <Detailsmod
        show={detailopen.isOpen}
        detail={detailopen}
        onhide={() => detailsclick()}
      />

      <Row>
        {loading ? (
          <Loading />
        ) : blogs.length ? (
          blogs.map((data, index) => (
            <BlogCard
              key={index}
              data={data}
              index={index}
              detailsclick={detailsclick}
              enableActions={enableActions}
              handleFormPopup={handleFormPopup}
              removeBlog={removeBlog}
            />
          ))
        ) : (
          <p className="text-center text-white">No Post Found</p>
        )}
      </Row>
    </div>
  );
}

export default CardWrapper;

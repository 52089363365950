import * as TYPES from "./actionTypes";

export const makeBotRequest = (user, callback) => {
  return {
    type: TYPES.MAKE_BOT_REQUEST,
    payload: { user, callback },
  };
};

export const makeBotRequestSuccess = (user) => {
  return {
    type: TYPES.MAKE_BOT_REQUEST_SUCCESS,
    payload: user,
  };
};

export const makeBotRequestFail = (user) => {
  return {
    type: TYPES.MAKE_BOT_REQUEST_FAIL,
    payload: user,
  };
};

export const editBotRequest = (user, callback) => {
  return {
    type: TYPES.EDIT_BOT_REQUEST,
    payload: { user, callback },
  };
};

export const editBotRequestSuccess = (user) => {
  return {
    type: TYPES.EDIT_BOT_REQUEST_SUCCESS,
    payload: user,
  };
};

export const editBotRequestFail = (user) => {
  return {
    type: TYPES.EDIT_BOT_REQUEST_FAIL,
    payload: user,
  };
};

export const cancelBotRequest = (user, callback) => {
  return {
    type: TYPES.CANCEL_BOT_REQUEST,
    payload: { user, callback },
  };
};

export const cancelBotRequestSuccess = (user) => {
  return {
    type: TYPES.CANCEL_BOT_REQUEST_SUCCESS,
    payload: user,
  };
};

export const cancelBotRequestFail = (user) => {
  return {
    type: TYPES.CANCEL_BOT_REQUEST_FAIL,
    payload: user,
  };
};

/* Get bots List */
export const getBotRequests = (data) => ({
  type: TYPES.GET_BOT_REQUESTS,
  payload: data,
});

export const getBotRequestsSuccess = (bots) => ({
  type: TYPES.GET_BOT_REQUESTS_SUCCESS,
  payload: bots,
});

export const getBotRequestsFail = (error) => ({
  type: TYPES.GET_BOT_REQUESTS_FAIL,
  payload: error,
});
/* Get bots List END */


export const setRequestDetail = (data) => ({
  type: TYPES.SET_REQUEST_DETAIL,
  payload: data,
});
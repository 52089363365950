import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Row, Col } from "react-bootstrap";
import { FormGroup, Label, Input } from "reactstrap";
import toastr from "toastr";

///component
import { ClickButton } from "Component/InputElements";
import { getBotRequests, updateOrder } from "store/actions";

function Botviewmod(props) {
  const { detail } = props;
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.Bot);

  const cancelOrder = async (e) => {
    e.preventDefault();
    if (loading || !detail) return;

    try {
      const callback = async (response) => {
        if (response.status == "failure") {
          toastr.error(response.message);
        } else {
          props.onhide();
          dispatch(
            getBotRequests({
              tokenId: props.funds.selectedToken._id,
              limit: 20,
            })
          );
          toastr.success("Order Cancelled");
          return;
        }
      };

      dispatch(
        updateOrder(
          {
            _id: detail._id,
            status: "cancel",
            PNL: 0,
            latestPrice: 0,
          },
          callback
        )
      );
    } catch (error) {
      console.log("error", error);
      return toastr.error("Something went wrong!");
    }
  };

  return (
    <Modal {...props} size="md" centered className="Botviewmod">
      <Modal.Body>
        <Button
          onClick={props.onhide}
          variant="transparent"
          className="border-0 p-0 closeBtn position-absolute"
          style={{ top: "10px", right: "10px" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
          >
            <path
              d="M8 18.75L12.5 14.25L17 18.75L18.75 17L14.25 12.5L18.75 8L17 6.25L12.5 10.75L8 6.25L6.25 8L10.75 12.5L6.25 17L8 18.75ZM12.5 25C10.7708 25 9.14583 24.6717 7.625 24.015C6.10417 23.3583 4.78125 22.4679 3.65625 21.3437C2.53125 20.2187 1.64083 18.8958 0.985 17.375C0.329167 15.8542 0.000833333 14.2292 0 12.5C0 10.7708 0.328333 9.14583 0.985 7.625C1.64167 6.10417 2.53208 4.78125 3.65625 3.65625C4.78125 2.53125 6.10417 1.64083 7.625 0.985C9.14583 0.329167 10.7708 0.000833333 12.5 0C14.2292 0 15.8542 0.328333 17.375 0.985C18.8958 1.64167 20.2187 2.53208 21.3437 3.65625C22.4687 4.78125 23.3596 6.10417 24.0162 7.625C24.6729 9.14583 25.0008 10.7708 25 12.5C25 14.2292 24.6717 15.8542 24.015 17.375C23.3583 18.8958 22.4679 20.2187 21.3437 21.3437C20.2187 22.4687 18.8958 23.3596 17.375 24.0162C15.8542 24.6729 14.2292 25.0008 12.5 25Z"
              fill="white"
            ></path>
          </svg>
        </Button>

        <div className="modal_view">
          <div className="trigger_part d-flex align-items-start gap-4 px-4 py-3 border-bottom border-light border-opacity-25">
            <div className="trigger_left">
              <p className="trigger_head">Buy</p>

              <div className="trigger_price">
                <FormGroup className="position-relative">
                  <Label for="exampleEmail">Trigger Price</Label>
                  <Input
                    type="number"
                    name="triggervalue"
                    id="trigger"
                    placeholder="0.00"
                    value={detail?.buyPrice || 0}
                    onChange={(e) => e.preventDefault()}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="place_order">
              <p>
                Place Order{" "}
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#fff"
                      d="M16.15 13H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.15L13.3 8.15q-.3-.3-.288-.7t.288-.7q.3-.3.713-.313t.712.288L19.3 11.3q.15.15.213.325t.062.375q0 .2-.063.375t-.212.325l-4.575 4.575q-.3.3-.712.288t-.713-.313q-.275-.3-.288-.7t.288-.7L16.15 13Z"
                    />
                  </svg>
                </span>
              </p>
            </div>

            <div className="trigger_right">
              <div className="qty_box_wrap d-flex align-items-center justify-content-between gap-3">
                <div className="Qty_wrap">
                  <div className="trigger_price">
                    <FormGroup className="position-relative">
                      <Label for="exampleEmail">Qty.</Label>
                      <Input
                        type="number"
                        name="triggervalue"
                        id="trigger"
                        placeholder="0.00"
                        value={detail?.tokenAmount || 0}
                        onChange={(e) => e.preventDefault()}
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="Qty_wrap">
                  <div className="trigger_price">
                    <FormGroup className="position-relative">
                      <Label for="exampleEmail">Price</Label>
                      <Input
                        type="number"
                        name="triggervalue"
                        id="trigger"
                        placeholder="0.00"
                        value={detail?.collateral || 0}
                        onChange={(e) => e.preventDefault()}
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>

              <p className="text-white pt-2">
                {" "}
                Leverage: {detail?.leverage || 0}X
              </p>
            </div>
          </div>

          <div className="trigger_part d-flex align-items-start gap-4 px-4 py-3 border-bottom border-light border-opacity-25">
            <div className="trigger_left">
              <p className="trigger_head">Stoploss</p>

              <div className="trigger_price">
                <FormGroup className="position-relative">
                  <Label for="exampleEmail">Trigger Price</Label>
                  <Input
                    type="number"
                    name="triggervalue"
                    id="trigger"
                    placeholder="0.00"
                    value={detail?.stopLoss}
                    onChange={(e) => e.preventDefault()}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="place_order">
              <p>
                Place Order{" "}
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#fff"
                      d="M16.15 13H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.15L13.3 8.15q-.3-.3-.288-.7t.288-.7q.3-.3.713-.313t.712.288L19.3 11.3q.15.15.213.325t.062.375q0 .2-.063.375t-.212.325l-4.575 4.575q-.3.3-.712.288t-.713-.313q-.275-.3-.288-.7t.288-.7L16.15 13Z"
                    />
                  </svg>
                </span>
              </p>
            </div>

            <div className="trigger_right">
              <div className="qty_box_wrap d-flex align-items-center justify-content-between gap-3">
                <div className="Qty_wrap">
                  <div className="trigger_price">
                    <FormGroup className="position-relative">
                      <Label for="exampleEmail">Qty.</Label>
                      <Input
                        type="number"
                        name="triggervalue"
                        id="trigger"
                        placeholder="0.00"
                        value={detail?.tokenAmount || 0}
                        onChange={(e) => e.preventDefault()}
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="Qty_wrap">
                  <div className="trigger_price">
                    <FormGroup className="position-relative">
                      <Label for="exampleEmail">Price</Label>
                      <Input
                        type="number"
                        name="triggervalue"
                        id="trigger"
                        placeholder="0.00"
                        value={detail?.collateral || 0}
                        onChange={(e) => e.preventDefault()}
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="trigger_part d-flex align-items-start gap-4 px-4 py-3">
            <div className="trigger_left">
              <p className="trigger_head">Target</p>

              <div className="trigger_price">
                <FormGroup className="position-relative">
                  <Label for="exampleEmail">Trigger Price</Label>
                  <Input
                    type="number"
                    name="triggervalue"
                    id="trigger"
                    placeholder="0.00"
                    value={detail?.targetPrice || 0}
                    onChange={(e) => e.preventDefault()}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="place_order">
              <p>
                Place Order{" "}
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#fff"
                      d="M16.15 13H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.15L13.3 8.15q-.3-.3-.288-.7t.288-.7q.3-.3.713-.313t.712.288L19.3 11.3q.15.15.213.325t.062.375q0 .2-.063.375t-.212.325l-4.575 4.575q-.3.3-.712.288t-.713-.313q-.275-.3-.288-.7t.288-.7L16.15 13Z"
                    />
                  </svg>
                </span>
              </p>
            </div>

            <div className="trigger_right">
              <div className="qty_box_wrap d-flex align-items-center justify-content-between gap-3">
                <div className="Qty_wrap">
                  <div className="trigger_price">
                    <FormGroup className="position-relative">
                      <Label for="exampleEmail">Qty.</Label>
                      <Input
                        type="number"
                        name="triggervalue"
                        id="trigger"
                        placeholder="0.00"
                        value={detail?.tokenAmount || 0}
                        onChange={(e) => e.preventDefault()}
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="Qty_wrap">
                  <div className="trigger_price">
                    <FormGroup className="position-relative">
                      <Label for="exampleEmail">Price</Label>
                      <Input
                        type="number"
                        name="triggervalue"
                        id="trigger"
                        placeholder="0.00"
                        value={detail?.collateral || 0}
                        onChange={(e) => e.preventDefault()}
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {detail?.status === "pending" && (
          <div className="section_footer pt-4 border-top border-light border-opacity-25 px-4 py-3">
            <Row>
              <Col lg={6} md={6} sm={6}>
                <p className="fotte_para">
                  If you want to cancel your order then click on cancel order
                  button.
                </p>
              </Col>
              <Col lg={6} md={6} sm={6}>
                <div className="Btn_twice d-flex align-items-center justify-content-end gap-3">
                  <ClickButton
                    btnText={"Cancel Order"}
                    className="cancel_btn"
                    onClick={cancelOrder}
                    disabled={loading}
                  />
                  {/* <Button className="cancel_btn">Cancel Order</Button> */}
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default Botviewmod;

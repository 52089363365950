import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Hotpairs from "../Hotpairs";
import Searchpair from "../Searchpair";
import Buyplatformtable from "./Buyplatformtable";
import { useDispatch, useSelector } from "react-redux";
import { getTradeTokenRequest } from "store/actions";

const Buyplatform = () => {
  const dispatch = useDispatch();
  const { tradeTokens } = useSelector((state) => state.Crypto);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
  });
  useEffect(() => {
    dispatch(getTradeTokenRequest(filters));
  }, [filters]);
  return (
    <section className="trade_new my-3">
      <Container fluid>
        <Hotpairs tradeTokens={tradeTokens} />
        {/* <Searchpair/> */}
        <Buyplatformtable heading="Buy Platform Tokens" />
      </Container>
    </section>
  );
};

export default Buyplatform;

import React, { useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { socket } from "socket";
import TradingViewWidget, { Themes } from "react-tradingview-widget";

//component
import Buycelltab from "./Buycelltab";
// import RealtimeEmulation from "./light-graph/index";

///store
import { getCandlesList, getTradeDetails } from "store/actions";

const Leveragetrading = () => {
  const dispatch = useDispatch();
  const { tradeDetails } = useSelector((state) => state.Crypto);
  const { settings } = useSelector((state) => state.Settings);

  const { id } = useParams();
  useEffect(() => {
    if (!id) return;
    dispatch(getTradeDetails(id));
    dispatch(getCandlesList({ tradeId: id }));
  }, [id]);

  useEffect(() => {
    socket.on("connect", (res) => {
      console.log(res, "yes it connected");
    });
    socket.on("NewCandleAdded", (res) => {
      dispatch(getCandlesList({ tradeId: id }));
    });
  }, []);

  return (
    <>
      <div className="trade_fillter d-flex justify-content-between my-4">
        <div className="tradehead_side text-start">
          <h5>Trade Listing</h5>

          <p>Click Here to list your token pairs on the LeverageIT platform</p>
        </div>
      </div>

      <section className="Dashtableview comon_pad">
        <Row>
          <Col lg={8} md={6} sm={12}>
            {/* <div className="graph_wrapping"> */}
            <div
              className="single_graph_side"
              style={{ height: "calc(100vh - 0px)", width: "100%" }}
            >
              {/* <RealtimeEmulation symbol={tradeDetails?.symbol || ""}/> */}
              <TradingViewWidget
                symbol={tradeDetails?.tradingViewSymbol || tradeDetails?.symbol}
                theme={Themes.DARK}
                hide_side_toolbar={false}
                autosize
                interval={5}
                // range={5}
                timezone="Etc/UTC"
                style="1"
                locale="us"
              />
            </div>
            {/* </div> */}
          </Col>
          <Col lg={4} md={6} sm={12}>
            <Buycelltab
              tradeTokenDetails={tradeDetails || null}
              settings={settings}
            />
          </Col>
        </Row>
      </section>
    </>
  );
};

export default Leveragetrading;

import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

import { LuEye, LuEyeOff } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { editProfile, putChangePwd, postTfaDisableOtp } from "store/actions";
import { Spinner } from "react-bootstrap";
import toastr from "toastr";
import EnablettwoFAmodal from "Component/Modals/EnablettwoFAmodal";

function Settingtab(props) {
  const [state, setState] = useState({ tab: "tab4" });
  const [fields, setFields] = useState({
    username: "",
    countryCode: "",
    mobileNumber: "",
  });
  const [passwordFields, setPasswordFields] = useState({
    newPassword: "",
    oldPassword: "",
    confirmPassword: "",
  });
  const [secret, setSecret] = useState({
    otpauth_url: "",
    base32: "",
  });

  const [passwordType, setPasswordType] = useState("password");
  const [cpasswordType, setcPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [cpasswordInput, setcPasswordInput] = useState("");
  const [twofamod, setTwofamod] = useState(false);

  const dispatch = useDispatch();
  const { user, loading } = useSelector(({ Login, Profile }) => ({
    user: Login.user,
    loading: Profile.loading,
  }));

  useEffect(() => {
    if (user) {
      setFields({
        mobileNumber: user?.mobileNumber || "",
        countryCode: user?.countryCode || "",
        username: user?.username || "",
      });
    }
  }, [user]);

  const handleChange = (name) => (e) => {
    setFields((prev) => ({ ...prev, [name]: e.target.value }));
  };

  const handleChangePassword = (name) => (e) => {
    setPasswordFields((prev) => ({ ...prev, [name]: e.target.value }));
  };

  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };

  const chandlePasswordChange = (evnt) => {
    setcPasswordInput(evnt.target.value);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const ctogglePassword = () => {
    if (cpasswordType === "password") {
      setcPasswordType("text");
      return;
    }
    setcPasswordType("password");
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    dispatch(editProfile(fields));
  };

  const onSubmitPassword = (e) => {
    e.preventDefault();

    if (passwordFields.newPassword !== passwordFields.confirmPassword) {
      return toastr.error("New password not match with confirm password!");
    }
    dispatch(
      putChangePwd(passwordFields, (res) => {
        if (res.status === "failure") {
          toastr.error(res.message);
        } else {
          setPasswordFields({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
        }
      })
    );
  };

  const disableTfa = () => {
    dispatch(postTfaDisableOtp(), () => {
      toastr.warning("Two Factor Authentication Disabled");
    });
  };

  return (
    <>
      <EnablettwoFAmodal show={twofamod} onhide={() => setTwofamod(false)} />

      <section className="setting_tab">
        <Container fluid>
          <div className="settting_tabbing">
            <div className="tab-frame trade_frame">
              <div className="clearfix">
                {/* <input
                type="radio"
                name="tab"
                id="tab1"
                checked={state.tab == "tab1"}
                onClick={() => setState({ tab: "tab1" })}
              /> */}
                {/* <label for="tab1">Profile</label> */}

                <input
                  type="radio"
                  name="tab"
                  id="tab4"
                  checked={state.tab == "tab4"}
                  onClick={() => setState({ tab: "tab4" })}
                />
                <label for="tab4">My Account</label>

                <input
                  type="radio"
                  name="tab"
                  id="tab5"
                  checked={state.tab == "tab5"}
                  onClick={() => setState({ tab: "tab5" })}
                />
                <label for="tab5">Security</label>
              </div>

              {/* {state.tab == "tab1" && (
              <div className="setting_coomon mt-5">
                <Form className="setting_profile" onSubmit={onSubmitHandler}>
                  <Row>
                    <Col lg={5} md={5} sm={12}>
                      <div className="phonenumber_signup position-relative">
                        <FormGroup>
                          <Label>Mobile Number</Label>
                          <PhoneInput
                            country={"us"}
                            value={fields.countryCode}
                            onChange={(countryCode) =>
                              setFields((prev) => ({ ...prev, countryCode }))
                            }
                          />
                        </FormGroup>

                        <FormGroup>
                          <Input
                            type="tel"
                            name="number"
                            id="mobile_number"
                            placeholder=""
                            value={fields?.mobileNumber}
                            onChange={handleChange("mobileNumber")}
                          />
                        </FormGroup>
                      </div>
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                      <FormGroup className="position-relative">
                        <Label>Username</Label>
                        <Input
                          type="text"
                          name="username"
                          id="username"
                          value={fields?.username}
                          onChange={handleChange("username")}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Button type="submit" className="btn_common done_btn mt-5">
                      {loading && (
                        <Spinner className="me-2" size="sm" color="light" />
                      )}{" "}
                      Submit
                    </Button>
                  </Row>
                </Form>
              </div>
            )} */}

              {/* {state.tab == "tab2" && (
              <div className="setting_coomon settingkyc_update mt-5">
                <Row>
                  <Col lg={5}>
                    <div className="upload-kyc">
                      <div className="upload-btn-wrapper">
                        <button className="btnup">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 39 39"
                            fill="none"
                            opacity="0.4"
                          >
                            <path
                              d="M32.1329 4.81996C32.9851 4.81996 33.8024 5.1585 34.405 5.76111C35.0077 6.36372 35.3462 7.18103 35.3462 8.03324V30.5262C35.3462 31.3784 35.0077 32.1957 34.405 32.7983C33.8024 33.401 32.9851 33.7395 32.1329 33.7395H6.42666C5.57445 33.7395 4.75713 33.401 4.15453 32.7983C3.55192 32.1957 3.21338 31.3784 3.21338 30.5262V8.03324C3.21338 7.18103 3.55192 6.36372 4.15453 5.76111C4.75713 5.1585 5.57445 4.81996 6.42666 4.81996H32.1329ZM32.1329 8.03324H6.42666V30.5262H7.91923L22.8738 15.57C23.0603 15.3835 23.2818 15.2355 23.5254 15.1345C23.7691 15.0336 24.0303 14.9816 24.2941 14.9816C24.5579 14.9816 24.8191 15.0336 25.0628 15.1345C25.3065 15.2355 25.5279 15.3835 25.7144 15.57L32.1329 21.9885V8.03324ZM12.0499 11.2465C12.6891 11.2465 13.302 11.5004 13.754 11.9524C14.206 12.4043 14.4599 13.0173 14.4599 13.6565C14.4599 14.2956 14.206 14.9086 13.754 15.3606C13.302 15.8125 12.6891 16.0664 12.0499 16.0664C11.4107 16.0664 10.7978 15.8125 10.3458 15.3606C9.89385 14.9086 9.63994 14.2956 9.63994 13.6565C9.63994 13.0173 9.89385 12.4043 10.3458 11.9524C10.7978 11.5004 11.4107 11.2465 12.0499 11.2465Z"
                              fill="#fff"
                            />
                          </svg>
                        </button>
                        <input type="file" name="myfile" />
                        <p>
                          National identity Card <br></br> (Front)
                        </p>
                      </div>

                      <div className="upload-btn-wrapper">
                        <button className="btnup">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 39 39"
                            fill="none"
                            opacity="0.4"
                          >
                            <path
                              d="M32.1329 4.81996C32.9851 4.81996 33.8024 5.1585 34.405 5.76111C35.0077 6.36372 35.3462 7.18103 35.3462 8.03324V30.5262C35.3462 31.3784 35.0077 32.1957 34.405 32.7983C33.8024 33.401 32.9851 33.7395 32.1329 33.7395H6.42666C5.57445 33.7395 4.75713 33.401 4.15453 32.7983C3.55192 32.1957 3.21338 31.3784 3.21338 30.5262V8.03324C3.21338 7.18103 3.55192 6.36372 4.15453 5.76111C4.75713 5.1585 5.57445 4.81996 6.42666 4.81996H32.1329ZM32.1329 8.03324H6.42666V30.5262H7.91923L22.8738 15.57C23.0603 15.3835 23.2818 15.2355 23.5254 15.1345C23.7691 15.0336 24.0303 14.9816 24.2941 14.9816C24.5579 14.9816 24.8191 15.0336 25.0628 15.1345C25.3065 15.2355 25.5279 15.3835 25.7144 15.57L32.1329 21.9885V8.03324ZM12.0499 11.2465C12.6891 11.2465 13.302 11.5004 13.754 11.9524C14.206 12.4043 14.4599 13.0173 14.4599 13.6565C14.4599 14.2956 14.206 14.9086 13.754 15.3606C13.302 15.8125 12.6891 16.0664 12.0499 16.0664C11.4107 16.0664 10.7978 15.8125 10.3458 15.3606C9.89385 14.9086 9.63994 14.2956 9.63994 13.6565C9.63994 13.0173 9.89385 12.4043 10.3458 11.9524C10.7978 11.5004 11.4107 11.2465 12.0499 11.2465Z"
                              fill="#fff"
                            />
                          </svg>
                        </button>
                        <input type="file" name="myfile" />
                        <p>
                          National identity Card <br></br> (Back)
                        </p>
                      </div>
                    </div>
                    <Button className="btn_common w-100">Complete KyC</Button>
                  </Col>
                </Row>
              </div>
            )} */}

              {/* {state.tab == "tab3" && (
              <div className="setting_coomon setting_myactivity mt-4">
                <h5>Your Activity Details</h5>
                <div className="Otc_table">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Activity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[1, 2, 3].map(() => {
                        return (
                          <tr>
                            <td>1</td>
                            <td>June 23,2020 08:30pm</td>
                            <td>
                              <p className="colo_Change">
                                Update The Password In publishing and graphic
                                design, Lorem ipsum is a placeholder text
                                commonly
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            )} */}

              {state.tab == "tab4" && (
                <div className="setting_coomon chnage_password mt-3">
                  <div className="user_img_content">
                    <h4>{props.user?.username}</h4>

                    <p>{props.user?.email}</p>

                    <p>
                      {props.user?.countryCode + " " + props.user?.mobileNumber}
                    </p>
                  </div>

                  <div className="auth-social">
                    <Form
                      autoComplete="new-password"
                      className="form_sign_inout mt-2"
                    >
                      {/* <div className="py-2">
                        <label className="form-label m-0">
                          Other Login Options
                        </label>

                        <ul className="">
                          <li className="col-span-2">
                            <Button className="w-100 btn_common done_btn">
                              <img
                                src="/images/login-google-light.svg"
                                alt=""
                              />
                              <span className="mx-1">Google</span>
                            </Button>
                          </li>

                          <li className="col-span-2">
                            <Button className="w-100 btn_common done_btn">
                              <img
                                style={{ height: "18px", width: "18px" }}
                                src="/images/login-twitter-x-light.svg"
                                alt=""
                              />
                              <span className="mx-1">Twitter X</span>
                            </Button>
                          </li>

                          <li className="col-span-2">
                            <Button className="w-100 btn_common done_btn">
                              <img
                                src="/images/login-discord-light.svg"
                                alt=""
                              />
                              <span className="mx-1">Discord</span>
                            </Button>
                          </li>
                        </ul>
                      </div> */}
                    </Form>
                  </div>
                </div>
              )}

              {state.tab == "tab5" && (
                <div className="setting_coomon chnage_password mt-5">
                  <Form className="setting_profile" onSubmit={onSubmitPassword}>
                    <Row>
                      <Col lg={5} md={6} sm={12}>
                        <FormGroup className="position-relative">
                          <Label for="exampleEmail">Old Password</Label>

                          <Input
                            type="password"
                            name="oldPassword"
                            value={passwordFields?.oldPassword}
                            onChange={handleChangePassword("oldPassword")}
                          />
                        </FormGroup>

                        <FormGroup className="position-relative">
                          <Label for="exampleEmail">New Password</Label>

                          <Input
                            type={passwordType}
                            name="newPassword"
                            onChange={handleChangePassword("newPassword")}
                            value={passwordFields?.newPassword}
                          />

                          {passwordType === "password" ? (
                            <Button
                              className="eye_comon"
                              onClick={togglePassword}
                            >
                              <LuEyeOff color={"#565660"} />
                            </Button>
                          ) : (
                            <Button
                              className="eye_comon"
                              onClick={togglePassword}
                            >
                              <LuEye color={"#565660"} />
                            </Button>
                          )}
                        </FormGroup>

                        <FormGroup className="position-relative">
                          <Label for="exampleEmail">Confirm Password</Label>

                          <Input
                            type={passwordType}
                            name="confirmPassword"
                            onChange={handleChangePassword("confirmPassword")}
                            value={passwordFields?.confirmPassword}
                          />

                          {passwordType === "password" ? (
                            <Button
                              className="eye_comon"
                              onClick={togglePassword}
                            >
                              <LuEyeOff color={"#565660"} />
                            </Button>
                          ) : (
                            <Button
                              className="eye_comon"
                              onClick={togglePassword}
                            >
                              <LuEye color={"#565660"} />
                            </Button>
                          )}
                        </FormGroup>

                        <Button
                          type="submit"
                          className="btn_common done_btn mt-5"
                        >
                          {loading && (
                            <Spinner className="me-2" size="sm" color="light" />
                          )}
                          Submit
                        </Button>
                      </Col>

                      <Col lg={6} md={6} sm={12} className="offset-lg-1">
                        <div className="two_factor_enable">
                          <h6 className="text-white">
                            Two Factor Authentication
                          </h6>

                          <div className="d-flex justify-content-between align-items-start mt-4">
                            <div className="two_factor_left">
                              <h6>Secure your Account</h6>

                              <p>
                                Enable two-factor authentication to add an extra
                                layer of security
                              </p>
                            </div>

                            {!user?.tfa_otp_enabled && (
                              <Button
                                className="enable_twofa"
                                onClick={() => setTwofamod(true)}
                              >
                                Setup 2FA
                              </Button>
                            )}

                            {user?.tfa_otp_enabled && (
                              <Button className="" onClick={disableTfa}>
                                Disable 2FA
                              </Button>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              )}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Settingtab;

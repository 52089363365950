import React, { useState, useEffect } from "react";
import { Container, Table, Button, Spinner } from "react-bootstrap";
import Hotpairs from "../Hotpairs";
import CustomPagination from "Component/CustomPagination";
import { useDispatch, useSelector } from "react-redux";
import { getTradeTokenRequest, getOrders, updateOrder } from "store/actions";
import moment from "moment-timezone";
import toastr from "toastr";

import { calculateTransferToken } from "helpers/constants";
var W3CWebSocket = require("websocket").w3cwebsocket;

const Position = () => {
  const { totalOrders, orders } = useSelector(({ Order }) => Order);
  const { user, loading } = useSelector(({ Login }) => Login);
  const { tradeTokens } = useSelector(({ Crypto }) => Crypto);

  const dispatch = useDispatch();
  const [newOrders, setNewOrders] = useState(orders);
  const [closeOrderLoading, setCloseOrderLoading] = useState(false);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    orderBy: "date_created",
    order: -1,
    user: user?._id,
  });
  const [tokenCurrentPrices, settokenCurrentPrices] = useState({});

  //set orders into new state
  useEffect(() => {
    setNewOrders(orders);
  }, [orders]);

  //get order and token data
  useEffect(() => {
    if (user) {
      dispatch(getOrders({ ...filters }));
      dispatch(getTradeTokenRequest());
    }
  }, [user, filters]);

  //pagination
  const onPageChange = (page) => {
    setFilters((prev) => ({ ...prev, page }));
  };

  const totalPages = Math.ceil(totalOrders / filters?.limit);

  // Get live profit and loss using socket
  useEffect(() => {
    const _orders = orders.filter((order) => order.status === "open");

    const tradeIds = [];
    const symbolTradeId = {};
    const chainIdSymbol = {};

    const client = new W3CWebSocket("wss://api-pub.bitfinex.com/ws/2");

    client.onmessage = async (msg) => {
      const resp = JSON.parse(msg.data.toString());

      if (Array.isArray(resp)) {
        if (resp[1] && resp[1].length == 10) {
          const getCurrentPrice = resp[1][6];
          const getSymbolByChain = chainIdSymbol[resp[0]];
          const tradeId = symbolTradeId[getSymbolByChain];

          settokenCurrentPrices((prevState) => ({
            ...prevState,
            [tradeId]: getCurrentPrice,
          }));
        }
      } else if (resp.event === "subscribed") {
        chainIdSymbol[resp.chanId] = resp.symbol;
      }
    };

    _orders.forEach((order) => {
      if (!tradeIds.includes(order.tokenId._id)) {
        const symbol = `t${order.tokenId.bitFinexSymbol}`;
        let msg = JSON.stringify({
          event: "subscribe",
          channel: "ticker",
          symbol,
        });

        client.onopen = () => client.send(msg, "msg");

        symbolTradeId[symbol] = order.tokenId._id;
      }
    });

    return () => {
      if (client) {
        client.close();
      }
    };
  }, [JSON.stringify(orders)]);

  //cancel order
  const handleCancelOrder = (item) => async (e) => {
    e.preventDefault();

    setCloseOrderLoading(true);
    try {
      const callback = async (response) => {
        if (response.status == "success") {
          setCloseOrderLoading(false);
          dispatch(getOrders({ ...filters }));
          toastr.success("Close Position Successfully!");
          return;
        } else {
          setCloseOrderLoading(false);
          toastr.error(response.message);
          return;
        }
      };

      dispatch(
        updateOrder(
          { _id: item._id, status: "cancel", PNL: 0, latestPrice: 0 },
          callback
        )
      );
    } catch (error) {
      setCloseOrderLoading(false);
      return toastr.error("Something went wrong!");
    }
  };

  //close order
  const handleSubmit = (item) => async (e) => {
    e.preventDefault();
    setCloseOrderLoading(true);

    const callback = async (response) => {
      if (response.status == "success") {
        setCloseOrderLoading(false);
        dispatch(getOrders({ ...filters }));
        toastr.success("Close Position Successfully!");
        return;
      } else {
        setCloseOrderLoading(false);
        toastr.error(response.message);
        return;
      }
    };
    dispatch(
      updateOrder(
        {
          _id: item?._id,
          status: "close",
        },
        callback
      )
    );
  };

  const handleInvestment = (item) => async (e) => {
    e.preventDefault();
    if (loading) return;
    try {
      const callback = async (response) => {
        if (response.status == "success") {
          dispatch(getOrders({ ...filters }));
          toastr.success(
            ` Successfully ${
              item.isInvestment === "yes" ? "removed" : "added"
            } in investment`
          );
          return;
        } else {
          setCloseOrderLoading(false);
          toastr.error(response.message);
          return;
        }
      };
      dispatch(
        updateOrder(
          {
            _id: item?._id,
            isInvestment: item.isInvestment === "yes" ? "no" : "yes",
          },
          callback
        )
      );
    } catch (error) {
      return toastr.error("Something went wrong!");
    }
  };

  return (
    <section className="trade_new my-3">
      <Container fluid>
        <Hotpairs tradeTokens={tradeTokens} />
        {/* <Searchpair searchKeyword={searchKeyword} /> */}
        <div className="shared_head text-start mb-3 mt-3">
          <h3 className="card_heading">Position</h3>
          {/* <p  className="buy_show">Shows latest Tokens in Leverage with useful information</p> */}
        </div>
        <section className="Opendertable-table pairprice_chart mt-3">
          <div className="pairshared_table">
            <div className="d-flex justify-content-center">
              {closeOrderLoading && <Spinner style={{ color: "#fff" }} />}
            </div>

            <Table responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Invested Amount</th>
                  <th>Leverage</th>
                  <th>Total Amount</th>
                  <th>Position</th>
                  <th>Open Price</th>
                  <th>Latest Price</th>
                  <th>Stop Loss</th>
                  <th>P&L</th>
                  <th>Status</th>
                  <th>Created Date</th>
                  {/* <th>Price USD</th> */}
                  <th>Action</th>
                  {user?.role === "EXPERT" && <th>Investment</th>}
                </tr>
              </thead>

              <tbody>
                {(newOrders || []).map((item) => {
                  const _currentPrice = tokenCurrentPrices[item.tokenId._id];
                  const totalPNL = calculateTransferToken(item, _currentPrice);
                  const PNL = Number(
                    (item.status === "open" ? totalPNL : item.PNL) || 0
                  ).toFixed(3);

                  const latestPrice =
                    Number(
                      (item.status === "open"
                        ? _currentPrice
                        : item.latestPrice) || 0
                    ).toFixed(8) || 0;

                  return (
                    <tr
                      key={item._id}
                      className={`${item.status == "open" && "open-position"}`}
                    >
                      <td>{item.tokenId.name}</td>

                      <td>{item.tokenAmount}</td>
                      <td>{item.leverage}</td>

                      <td>
                        {Number(item.tokenAmount) * Number(item.leverage) +
                          Number(item.tokenAmount)}
                      </td>

                      <td>{item.position}</td>

                      <td>{item.currentTicker}</td>

                      <td
                        className={`${
                          latestPrice < item.currentTicker
                            ? "danger"
                            : "success"
                        }`}
                      >
                        {latestPrice}
                      </td>

                      <td>{item.stopLoss}</td>

                      <td className={`${PNL < 0 ? "danger" : "success"}`}>
                        {PNL}
                      </td>
                      <td>
                        <span className="success-position text-capitalize">
                          {item.status}
                        </span>
                      </td>

                      <td>
                        {item.date_created
                          ? moment(item.date_created).format("YYYY-MM-DD")
                          : ""}
                      </td>

                      <td>
                        {item.status == "open" ? (
                          <Button
                            className="connect_wallet_cta"
                            onClick={handleSubmit(item, "close")}
                            disabled={closeOrderLoading}
                          >
                            Close Order
                          </Button>
                        ) : item.status == "close" ? (
                          "Position Closed"
                        ) : item.status == "pending" ? (
                          <Button
                            className="connect_wallet_cta"
                            onClick={handleCancelOrder(item)}
                            disabled={closeOrderLoading}
                          >
                            Cancel Orders
                          </Button>
                        ) : item.status == "cancel" ? (
                          "Position Canceled"
                        ) : (
                          ""
                        )}
                      </td>

                      {user?.role === "EXPERT" && item.status == "close" && (
                        <td>
                          <div className="toggle_here d-flex">
                            <label className="switch me-3">
                              <input
                                type="checkbox"
                                checked={item.isInvestment === "yes"}
                                onClick={handleInvestment(item)}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <div className="d-flex justify-content-end">
              <CustomPagination
                currentPage={filters?.page}
                totalPages={totalPages}
                onPageChange={(page) => onPageChange(page)}
                maxPagesToShow={5}
              />
            </div>
          </div>
        </section>
      </Container>
    </section>
  );
};

export default Position;

import React from "react";
import Headerlogin from "Component/Header/Headerlogin";
import OTCtrade from "Pages/Profile/OTCtrade";

export default function OTCtradetheme() {
  return (
    <>
      <Headerlogin heading="Transactions" />
      <OTCtrade/>
    </>
  );
}

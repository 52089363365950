import React from "react";
import { Container, Row , Col } from "react-bootstrap";
import Bot from "./Bot";
import Botinfo from "./Botinfo";
import useFunds from "Hooks/useFunds";


const Botpage = () => {
  const funds = useFunds();

  return (
    <section className="trade_new my-3">
      <Container fluid>
          <Row>
              <Col lg={8} md={12} sm={12}>
                 <Bot funds={funds}/>  
              </Col>

              <Col lg={4} md={12} sm={12}>
                  <Botinfo funds={funds}/>
              </Col>

          </Row>
      </Container>
    </section>
  );
};

export default Botpage;

import React, { useEffect, useState } from "react";
import OTPInput, { ResendOTP } from "otp-input-react";
import { Button, FormGroup } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import { loginWithOtp, sendOtp, verifyOtp } from "store/actions";
import { Form } from "react-bootstrap";

export default function OTP() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.Login);

  const [userDetails, setUserDetails] = useState({});
  const [mobileOtp, setMobileOtp] = useState("");

  useEffect(() => {
    setUserDetails(user);
  }, [user])

  const verifyMobile = (e) => {
    e.preventDefault();
    if (!userDetails) {
      return navigate("/sign-in");
    }
    if (mobileOtp == "") {
      return toastr.error("Mobile Otp is required!");
    }
    if (mobileOtp.length != 6) {
      return toastr.error("Please enter 6 digit otp to verify!");
    }
    dispatch(loginWithOtp({ role: userDetails.role, countryCode: userDetails.countryCode, mobileNumber: userDetails.mobileNumber, OTP: mobileOtp, email: userDetails.email }, navigate));
  }

  const sendMobileOtp = (e) => {
    e.preventDefault();
    if (!userDetails) {
      return navigate("/sign-in");
    }
    const callback = (response) => {
      if (response.status == "success") {
        return toastr.success(`One time password send to your mobile(${userDetails.countryCode} ${userDetails.mobileNumber}) successfully`);
      } else {
        return toastr.error(response.message);
      }
    }
    dispatch(sendOtp({ type: "number", countryCode: userDetails.countryCode, mobileNumber: userDetails.mobileNumber, email: userDetails.email }, callback));
  }

  return (
    <section className="signup_main common_sign_main OTP_col">
      <div className="sign_up_inner">
        <div className="logo_form mb-4">
          <img width={"150px"} src="/images/logo.png" alt="" className="img-fluid" />
        </div>
        <h2 className="text-center Signup_head">OTP</h2>

        <div className="otp_common">
          <p className="enter_info">
            Enter the 6-digit code sent to your register number
          </p>
          <Form className="otp-signup">
            <FormGroup className="position-relative d-flex align-items-center gap-2">
              <OTPInput value={mobileOtp} onChange={setMobileOtp} autoFocus OTPLength={6} otpType="number" disabled={false} secure />
            </FormGroup>
          </Form>

          <div className="resend_Col">
            <Button className="btn_common w-100" onClick={verifyMobile}>
              Verify
            </Button>
            <a href="javascript:void(0)" onClick={sendMobileOtp} className="resend_code">Resend code</a>
          </div>

        </div>


      </div>
    </section>
  );
}

import Web3 from "web3";
import { Contract, ethers } from "ethers";

// Constant
import {
  getNetworkUrl,
  getContractDetails,
  factoryAddress,
  factoryAbi,
  pairTokensAbi,
  UNISWAP_ROUTER_ADDRESS,
} from "../helpers/constants";
import toastr from "toastr";
import {
  Fetcher,
  Percent,
  Route,
  Token,
  TokenAmount,
  Trade,
  TradeType,
} from "@uniswap/sdk";

import tokenAbi from "./tokenAbi.json";

class Web3Intraction {
  constructor(blockchain, provider, settings) {
    const networkUrl = getNetworkUrl(blockchain || "ethereum", settings);

    if (provider) {
      this.PROVIDER = new ethers.providers.Web3Provider(
        provider,
        networkUrl
          ? { name: networkUrl.chainName, chainId: Number(networkUrl.chainId) }
          : "any"
      );

      this.SIGNER = this.PROVIDER.getSigner();
    } else if (networkUrl) {
      this.PROVIDER = new ethers.providers.JsonRpcProvider(
        networkUrl.url,
        networkUrl
          ? { name: networkUrl.chainName, chainId: Number(networkUrl.chainId) }
          : "any"
      );

      this.SIGNER = this.PROVIDER;
    }

    this.routerContractSetting = getContractDetails(
      blockchain || "ethereum",
      settings,
      "routerContract"
    );

    this.factoryContractSetting = getContractDetails(
      blockchain || "ethereum",
      settings,
      "factoryContract"
    );

    if (networkUrl) {
      this.web3 = new Web3(networkUrl.url);
    }

    /* this.WEBSOCKET_PROVIDER = new providers.WebSocketProvider(
      networkUrl.websocketUrl
    ); */

    this.settings = settings;
    this.networkUrl = networkUrl;
  }

  getNetworkUrl() {
    return this.networkUrl;
  }
  getTransactionReceipt = (transactionHash, getToken) => {
    return new Promise(async (resolve, reject) => {
      this.contractInterval = setInterval(async () => {
        try {
          let receipt = await this.web3.eth.getTransactionReceipt(
            transactionHash
          );

          if (!!receipt) {
            if (getToken && !!receipt?.logs && !!receipt.logs[0]) {
              receipt.token_id = this.web3.utils.hexToNumberString(
                receipt.logs[0].topics[3]
              );
            }

            clearInterval(this.contractInterval);
            this.contractInterval = null;

            resolve(receipt);
            return;
          }
        } catch (error) {
          clearInterval(this.contractInterval);
          this.contractInterval = null;

          reject(error);
          return;
        }
      }, 15000);
    });
  };

  getTokenPairAddress = async (tokenAAddress, tokenBAddress) => {
    return new Promise(async (resolve, reject) => {
      try {
        const factoryContract = new Contract(
          factoryAddress,
          factoryAbi,
          this.PROVIDER
        );
        const pairAddress = await factoryContract.getPair(
          tokenAAddress,
          tokenBAddress
        );
        resolve(pairAddress);
      } catch (error) {
        reject(error);
      }
    });
  };

  calculateMinAmounts(
    reserveA,
    reserveB,
    amountADesired,
    amountBDesired,
    slippage
  ) {
    const exchangeRate = reserveB / reserveA;
    const amountBMin = amountADesired * exchangeRate * (1 - slippage);
    const amountAMin = (amountBDesired / exchangeRate) * (1 - slippage);
    return { amountAMin, amountBMin };
  }

  async getTokensPriceAndPoolShare(tokenPairAddress) {
    return new Promise(async (resolve, reject) => {
      try {
        const pairContract = new Contract(
          tokenPairAddress,
          pairTokensAbi,
          this.PROVIDER
        );

        const [token0, token1] = await Promise.all([
          pairContract.token0(),
          pairContract.token1(),
        ]);

        const reserves = await pairContract.getReserves();
        const reserve0 = reserves[0];
        const reserve1 = reserves[1];

        const token0Contract = new Contract(
          token0,
          ["function decimals() view returns (uint8)"],
          this.PROVIDER
        );
        const token1Contract = new Contract(
          token1,
          ["function decimals() view returns (uint8)"],
          this.PROVIDER
        );

        const [decimals0, decimals1] = await Promise.all([
          token0Contract.decimals(),
          token1Contract.decimals(),
        ]);

        const price = reserve1
          .mul(ethers.BigNumber.from(10).pow(decimals0))
          .div(reserve0);
        const poolShare0 = reserve0.div(
          ethers.BigNumber.from(10).pow(decimals0)
        );
        const poolShare1 = reserve1.div(
          ethers.BigNumber.from(10).pow(decimals1)
        );
        const data = {
          price: ethers.utils.formatUnits(price),
          poolShare0: poolShare0.toString(),
          poolShare1: poolShare1.toString(),
        };
        resolve(data);
      } catch (err) {
        reject(err);
      }
    });
    // const tokenPairAbi = [...]; // Replace with actual ABI
  }

  addLiquidity = async (params, callback = () => null) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { firstToken, secondToken, amountA, amountB, toAddress } = params;

        // Create a contract instance of the Uniswap V2 Router

        if (
          this.routerContractSetting &&
          this.routerContractSetting.abi &&
          this.routerContractSetting.contractAddress
        ) {
          const routerContract = this.getContract(
            this.routerContractSetting.abi,
            UNISWAP_ROUTER_ADDRESS
          );
          // Token addresses
          const tokenA = firstToken.contractAddress;
          const tokenB = secondToken.contractAddress;
          const pairContractAddress = await this.getTokenPairAddress(
            tokenA,
            tokenB
          );
          const pairDetails = await this.getTokensPriceAndPoolShare(
            pairContractAddress
          );
          const slippage = 0.01;

          // Amounts of tokens to provide
          const amountADesired = ethers.utils.parseUnits(
            amountA?.toString(),
            "18"
          );
          const amountBDesired = ethers.utils.parseUnits(
            amountB?.toString(),
            "18"
          );

          const Totalamount = pairDetails.price * amountADesired;
          // const { amountAMin, amountBMin } = this.calculateMinAmounts(reserveA, reserveB, amountA, amountB, slippage);
          const amountBMin = Totalamount * (1 - slippage);
          const amountAMin = amountADesired * (1 - slippage);

          // Account that will receive LP tokens
          const to = toAddress;

          // Deadline for the transaction (Unix timestamp)
          const deadline = Math.floor(Date.now() / 1000) + 60 * 10; // 10 minutes from now

          // Add liquidity
          const tx = await routerContract.addLiquidity(
            tokenA,
            tokenB,
            amountADesired.toString(),
            amountBDesired.toString(),
            amountAMin.toString(),
            amountBMin.toString(),
            to,
            deadline,
            {
              gasLimit: 300000,
            }
          );
          try {
            await tx.wait();
          } catch (err) {}
          let receipt = await this.getTransactionReceipt(tx.hash);
          if (receipt?.status) {
            resolve({ txHash: tx.hash, ...receipt });
            return;
          } else {
            reject("Transaction failed!");
            return;
          }
        } else {
          reject("Contract address missing!");
          return;
        }
      } catch (e) {
        reject("Error in add Liquidity", e);
        return;
      }
    });
  };

  /**
   *Approve Token Contract
   *
   * @param {object} token1 from token
   * @param {number} amount Amount
   *
   * @returns {Promise} Success for approved or Fail for error
   */

  approveToken = async (token1, amount) => {
    return new Promise(async (resolve, reject) => {
      try {
        // const account = this.SIGNER.connect(this.PROVIDER);

        //
        const wbnb = new ethers.Contract(
          token1.contractAddress,
          [
            "function approve(address spender, uint256 amount) external returns (bool)",
          ],
          this.SIGNER
        );

        let tx_receipt = await wbnb.approve(
          UNISWAP_ROUTER_ADDRESS,
          ethers.utils.parseUnits(amount.toString(), 18)
        );

        let receipt = await tx_receipt.wait();

        resolve(receipt);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   *Get Wallet Balance
   *
   * @param {String} userWallet connected wallet
   * @param {function} token1 from callback
   *
   * @returns {Promise} Success for approved or Fail for error
   */

  getBalance = async (userWallet, callback = () => null) => {
    return new Promise(async (resolve, reject) => {
      try {
        var balance = await this.PROVIDER.getBalance(userWallet); //Will give value in.
        balance = ethers.utils.formatEther(balance.toString());
        resolve(balance);
      } catch (e) {
        console.log("e", e);
        reject("Get balance error");
        return { status: "failed" };
      }
    });
  };

  /**
   *Check Token Pair for mempool
   *
   * @param {object} selectedCrypto selected Crypto
   * @param {object} token1 from token
   * @param {object} token2 to token
   *
   * @returns {Promise} Success for approved or Fail for error
   */

  checkTokenPairAndTrade = async (
    selectedCrypto = { chainId: 5 },
    token1,
    token2,
    amount,
    slippage
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        let Token1 = new Token(
          Number(selectedCrypto?.chainId || 5),
          token1.contractAddress,
          token1?.decimals || 18
        );
        let Token2 = new Token(
          Number(selectedCrypto?.chainId || 5),
          token2.contractAddress,
          token2?.decimals || 18
        );
        let pair = await Fetcher.fetchPairData(Token1, Token2, this.PROVIDER);
        const route = await new Route([pair], Token2); // a fully specified path from input token to output token

        let amountIn = convertPriceToEther(amount.toString()); //helper function to convert ETH to Wei
        amountIn = amountIn.toString();
        const slippageTolerance = new Percent(slippage, "10000"); // 50 bips, or 0.50% - Slippage tolerance

        const trade = new Trade( //information necessary to create a swap transaction.
          route,
          new TokenAmount(Token2, amountIn),
          TradeType.EXACT_INPUT
        );
        resolve({ pair, slippageTolerance, trade });
      } catch (error) {
        reject({ message: "Choosen tokens pair not found in mempool." });
      }
    });
  };

  getConverterPrice = (token1, token2, amount, decimals) => {
    return new Promise(async (resolve, reject) => {
      try {
        // const provider = await new ethers.getDefaultProvider(rpc_url);

        if (
          this.routerContractSetting &&
          this.routerContractSetting.abi &&
          this.routerContractSetting.contractAddress
        ) {
          const UNISWAP_ROUTER_CONTRACT = this.getContract(
            this.routerContractSetting.abi,
            UNISWAP_ROUTER_ADDRESS,
            "provider"
          );
          let addressArr = [token1.contractAddress, token2.contractAddress];

          const getCurrentTokenPrice =
            await UNISWAP_ROUTER_CONTRACT.getAmountsOut(
              Number(decimals) != 18
                ? Number(amount) * 10 ** decimals
                : convertPriceToEther(amount),
              addressArr
            );
          let value = getCurrentTokenPrice[1].toString();
          // let value = convertHexToString(getCurrentTokenPrice[1]);
          value =
            Number(decimals) != 18
              ? Number(value) / 10 ** decimals
              : convertFromWei(value);

          // value = parseFloat(expectedAmountBInEther) / Math.pow(10, decimalsB);
          resolve(value);
          return;
        } else {
          reject("Some error occur!");
          return;
        }
      } catch (e) {
        console.log(e, "<=====err in convert");
        reject(e);
        return;
      }
    });
  };

  getTokenPrice = async (tokenAddress, userWallet, callback = () => null) => {
    return new Promise(async (resolve, reject) => {
      try {
        const tokenContract = new ethers.Contract(
          tokenAddress,
          tokenAbi,
          this.SIGNER
        );

        let tokenBalance = await tokenContract.balanceOf(userWallet);
        tokenBalance = Web3.utils.fromWei(tokenBalance.toString());

        resolve(tokenBalance);
      } catch (e) {
        console.log("e", e);
        reject("Get balance error");
        return { status: "failed" };
      }
    });
  };

  buyTokenUsingSwap = async (
    token1,
    token2,
    amount,
    amountPay,
    selectedCrypto = { chainId: 5 },
    slippage = "50"
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          this.routerContractSetting &&
          this.routerContractSetting.abi &&
          this.routerContractSetting.contractAddress
        ) {
          const UNISWAP_ROUTER_CONTRACT = this.getContract(
            this.routerContractSetting.abi,
            UNISWAP_ROUTER_ADDRESS
          );
          // let approved = await this.approveToken(token1, amount);
          // if (!approved) {
          //   reject("Error in approve!");
          // }

          let { pair, slippageTolerance, trade } =
            await this.checkTokenPairAndTrade(
              selectedCrypto,
              token1,
              token2,
              amount,
              slippage
            );
          if (!pair) {
            reject("Choosen tokens pair not found in mempool.");
          }

          let amountOutMin = trade.minimumAmountOut(slippageTolerance).raw; // needs to be converted to e.g. hex
          let amountOutMinHex = convertBgtoHex(amountOutMin);

          const path = [token1.contractAddress, token2.contractAddress]; //An array of token addresses
          const deadline = Math.floor(Date.now() / 1000) + 60 * 20; // 20 minutes from the current Unix time
          // const value = trade.inputAmount.raw; // // needs to be converted to e.g. hex
          // const valueHex = convertBgtoHex(value); //convert to hex string

          let rawTxn = await UNISWAP_ROUTER_CONTRACT.swapExactETHForTokens(
            amountOutMinHex,
            path,
            this.SIGNER.getAddress(),
            deadline,
            {
              value: convertPriceToEther(Number(amountPay).toFixed(18)),
              gasLimit: 300000,
            }
          );
          let reciept = await rawTxn.wait();

          //Logs the information about the transaction it has been mined.
          if (reciept) {
            resolve({
              ...reciept,
              hash: rawTxn.hash,
              networkUrl: this.networkUrl,
            });
          } else {
            reject("Error submitting transaction");
          }
        }
      } catch (error) {
        console.log(error, "error aagye hai");
        error = error.reason || error.message || error.data || error;

        reject(error);
      }
    });
  };

  swapTokenToEth = async (
    tokenPay,
    tokenReciever,
    amountPay,
    amountRecieve,
    selectedCrypto = { chainId: 5 },
    slippage = "50"
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          this.routerContractSetting &&
          this.routerContractSetting.abi &&
          this.routerContractSetting.contractAddress
        ) {
          const UNISWAP_ROUTER_CONTRACT = this.getContract(
            this.routerContractSetting.abi,
            UNISWAP_ROUTER_ADDRESS
          );
          let approved = await this.approveToken(tokenPay, amountPay);
          if (!approved) {
            reject("Error in approve!");
          }

          let { pair, slippageTolerance, trade } =
            await this.checkTokenPairAndTrade(
              selectedCrypto,
              tokenPay,
              tokenReciever,
              amountPay,
              slippage
            );
          if (!pair) {
            reject("Choosen tokens pair not found in mempool.");
          }

          const path = [
            tokenPay.contractAddress,
            tokenReciever.contractAddress,
          ]; //An array of token addresses
          const deadline = Math.floor(Date.now() / 1000) + 60 * 20; // 20 minutes from the current Unix time
          // const value = trade.inputAmount.raw; // // needs to be converted to e.g. hex
          // const valueHex = convertBgtoHex(value); //convert to hex string

          let rawTxn = await UNISWAP_ROUTER_CONTRACT.swapExactTokensForETH(
            convertPriceToEther(amountPay),
            "0",
            path,
            this.SIGNER.getAddress(),
            deadline,
            {
              gasLimit: 300000,
            }
          );
          let reciept = await rawTxn.wait();

          //Logs the information about the transaction it has been mined.
          if (reciept) {
            resolve({
              ...reciept,
              hash: rawTxn.hash,
              networkUrl: this.networkUrl,
            });
          } else {
            reject("Error submitting transaction");
          }
        }
      } catch (error) {
        console.log(error, "error aagye hai");
        error = error.reason || error.message || error.data || error;

        reject(error);
      }
    });
  };

  /**
   * Get contract from abi and address
   *
   * @param {string} abi - ABI JSON
   * @param {string} address - Contract Address
   *
   * @returns {object} Contract
   */
  getContract = (abi, address) => {
    try {
      let signer = this.SIGNER;

      if (!this.SIGNER) {
        signer = this.PROVIDER.getSigner();
      }

      let contract = new Contract(address, abi, signer);
      return contract;
    } catch (error) {
      console.log("error", error);
      return null;
    }
  };
}

export default Web3Intraction;

export const convertPriceToEther = (price) => {
  return ethers.utils.parseEther(price?.toString());

  // return Web3.utils.toWei(Number(price).toFixed(8), "ether")
};
export const convertToHex = (value) => ethers.utils.hexlify(parseInt(value));

export const convertHexToString = (hex) => {
  return Web3.utils.hexToNumberString(hex);
};

export const convertNumberToHex = (number) => {
  return Web3.utils.numberToHex(Number(number));
};
export const convertToDecimal = (value, decimal) => {
  return ethers.utils.parseUnits(value, decimal);
};
export const formatEther = (value) => {
  return ethers.utils.formatEther(value);
};

export const convertToWei = (number) => Web3.utils.toWei(number);
export const convertFromWei = (number, unit) =>
  Web3.utils.fromWei(number, unit || "ether");

export const convertBgtoHex = (value) => {
  return ethers.BigNumber.from(value.toString()).toHexString();
};

export function setDecimals(number, decimals) {
  number = number.toString();
  let numberAbs = number.split(".")[0];
  let numberDecimals = number.split(".")[1] ? number.split(".")[1] : "";
  while (numberDecimals.length < decimals) {
    numberDecimals += "0";
  }
  return numberAbs + numberDecimals;
}

import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Row, Col } from "reactstrap";
import { ethers } from "ethers";
import { Fade } from "react-reveal";
import Selecttokenmod from "Component/Modals/Selecttokenmod";
import Createpoolmod from "Component/Modals/Createpoolmod";
import { useSelector, useDispatch } from "react-redux";
import { addTransaction, getAllPairsListRequest } from "store/actions";
import toastr from "toastr";
import Web3Intraction from "utils/web3Intraction1";
import useWallet from "Hooks/wallet";
import { Spinner } from "react-bootstrap";
import { getNetworkUrl } from "helpers/constants";

const LiqudityPool = (props) => {
  const { loading, pairsList } = useSelector(({ Crypto }) => Crypto);
  const { settings } = useSelector((state) => state.Settings);
  const { user } = useSelector(({ Login }) => Login);
  const wallet = useWallet();

  const [showTokenModal, setShowTokenModal] = useState({
    show: false,
    tokenModalNum: 1,
  });
  const [Createpool, setCreatepool] = useState(false);
  const [wthPrice, setWEthPrice] = useState();
  const [pairAddress, setPairAddress] = useState("");
  const [addLiquidityLoading, setAddLiquidityLoading] = useState(false);
  const [insufficientFunds, setInsufficientFunds] = useState(false);
  const [selectedTokenData, setSelectedTokenData] = useState({
    firstToken: null,
    secondToken: null,
  });
  const [tokenBalance, setTokenBalance] = useState({
    tokenABalance: 0,
    tokenBBalance: 0,
  });
  const [fields, setFields] = useState({
    firstInput: 0,
    secondInput: 0,
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllPairsListRequest());
  }, []);

  const handleShowTokenModal = async (show, modalNum) => {
    if (modalNum === 2 && !selectedTokenData?.firstToken) {
      toastr.error("Please select first token!");
      return;
    }
    if (modalNum === 1) {
      setSelectedTokenData((prev) => ({ ...prev, secondToken: null }));
    }
    setFields({
      firstInput: 0,
      secondInput: 0,
    });
    setShowTokenModal({
      show: show,
      tokenModalNum: modalNum,
    });
  };

  const onHideTokenModal = (show) => {
    setShowTokenModal({
      show: show,
      tokenModalNum: 0,
    });
  };

  const onChangeFieldsAmount = async (e) => {
    const { name, value } = e.target;
    if (
      selectedTokenData &&
      selectedTokenData.firstToken &&
      selectedTokenData.secondToken
    ) {
      /* Manage button insufficient balance */
      if (name == "firstInput") {
        if (Number(value) > Number(tokenBalance.tokenABalance)) {
          setInsufficientFunds(true);
        } else {
          setInsufficientFunds(false);
        }
      } else {
        if (Number(value) > Number(tokenBalance.tokenBBalance)) {
          setInsufficientFunds(true);
        } else {
          setInsufficientFunds(false);
        }
      }
      /* End Manage button insufficient balance */

      if (pairAddress != "0x0000000000000000000000000000000000000000") {
        let secondTokenValue;
        if (name == "firstInput") {
          if (Number(value) > Number(tokenBalance.tokenABalance)) {
            setInsufficientFunds(true);
          } else {
            setInsufficientFunds(false);
          }
          if (selectedTokenData.firstToken.token_type == "secondary") {
            secondTokenValue = Number(value * wthPrice).toFixed(18);
          } else {
            secondTokenValue = (1 / wthPrice) * value;
          }
          setFields((prev) => ({ ...prev, secondInput: secondTokenValue }));
        } else {
          if (Number(value) > Number(tokenBalance.tokenBBalance)) {
            setInsufficientFunds(true);
          } else {
            setInsufficientFunds(false);
          }
          if (selectedTokenData.secondToken.token_type == "primary") {
            secondTokenValue = (1 / wthPrice) * value;
          } else {
            secondTokenValue = Number(value * wthPrice).toFixed(18);
          }
          setFields((prev) => ({ ...prev, firstInput: secondTokenValue }));
        }
      }
      setFields((prev) => ({ ...prev, [name]: value }));
    } else {
      return toastr.error("Please select pair first");
    }
  };

  useEffect(() => {
    (async () => {
      if (settings) {
        try {
          const networkUrl = getNetworkUrl("ethereum", settings);
          await wallet.switchNetwork(networkUrl?.chainId || "5");
        } catch (error) {
          return toastr.error("User reject request!");
        }
        const web3Intraction = new Web3Intraction(
          "ethereum",
          wallet.provider,
          settings
        );
        /*Get tokens balance */
        if (
          selectedTokenData &&
          selectedTokenData.firstToken &&
          wallet.account
        ) {
          const tokenABalance = await web3Intraction.getTokenPrice(
            selectedTokenData.firstToken.contractAddress,
            wallet.account,
            selectedTokenData.firstToken.tokenDecimals
          );
          setTokenBalance((prev) => ({
            ...prev,
            tokenABalance: tokenABalance,
          }));
        }
        if (
          selectedTokenData &&
          selectedTokenData.secondToken &&
          wallet.account
        ) {
          const tokenBBalance = await web3Intraction.getTokenPrice(
            selectedTokenData.secondToken.contractAddress,
            wallet.account,
            selectedTokenData.secondToken.tokenDecimals
          );
          setTokenBalance((prev) => ({
            ...prev,
            tokenBBalance: tokenBBalance,
          }));
        }
        /*End Get tokens balance */

        //Get pair address and pool data with current pair prices
        if (
          selectedTokenData &&
          selectedTokenData.firstToken &&
          selectedTokenData.secondToken
        ) {
          const pairAddress = await web3Intraction.getTokenPairAddress(
            selectedTokenData.firstToken.contractAddress,
            selectedTokenData.secondToken.contractAddress
          );
          setPairAddress(pairAddress);
          if (pairAddress != "0x0000000000000000000000000000000000000000") {
            const poolData = await web3Intraction.getTokensPriceAndPoolShare(
              pairAddress
            );
            console.log(poolData, "poolData");
            setWEthPrice(poolData?.price);
          }
        }
      }
    })();
  }, [selectedTokenData, settings, wallet]);

  const onClickSupplyBtn = (e) => {
    e.preventDefault();
    if (!fields.firstInput) {
      toastr.error("Please enter first token amount.");
      return;
    }
    if (!fields.secondInput) {
      toastr.error("Please enter second token amount.");
      return;
    }
    if (!selectedTokenData?.firstToken || !selectedTokenData?.secondToken) {
      toastr.error("Please select tokens!");
      return;
    }
    if (fields.firstInput && fields.secondInput) {
      setCreatepool(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAddLiquidityLoading(true);
    try {
      if (settings && wallet.account && wthPrice != 0) {
        const web3Intraction = new Web3Intraction(
          "ethereum",
          wallet.provider,
          settings
        );
        if (
          selectedTokenData &&
          selectedTokenData.firstToken &&
          selectedTokenData.secondToken
        ) {
          let params = {};
          let response;
          if (fields.firstInput == 0 || fields.secondInput == 0) {
            setAddLiquidityLoading(false);
            return toastr.error("Inputs not to be empty!");
          }
          if (
            Number(fields.firstInput) > Number(tokenBalance?.tokenABalance) ||
            Number(fields.secondInput) > Number(tokenBalance?.tokenBBalance)
          ) {
            setAddLiquidityLoading(false);
            return toastr.error("You don't have enought balance!");
          }
          let deadline = Math.floor(Date.now() / 1000) + 60 * 10;
          // Amounts of tokens to provide
          let amountADesired = ethers.utils
            .parseUnits(
              fields.firstInput?.toString(),
              selectedTokenData.firstToken?.tokenDecimals
            )
            .toString();
          let amountBDesired = ethers.utils
            .parseUnits(
              fields.secondInput?.toString(),
              selectedTokenData.secondToken?.tokenDecimals
            )
            .toString();

          if (pairAddress != "0x0000000000000000000000000000000000000000") {
            const slippage = 0.01;
            const amountBMin = amountBDesired * (1 - slippage);
            const amountAMin = amountADesired * (1 - slippage);

            params.tokenA = selectedTokenData.firstToken?.contractAddress;
            params.tokenB = selectedTokenData.secondToken?.contractAddress;
            params.amountADesired = amountADesired;
            params.amountBDesired = amountBDesired;
            params.amountAMin = amountAMin;
            params.amountBMin = amountBMin;
            params.toAddress = wallet.account;
            params.deadline = deadline;
            response = await web3Intraction.addLiquidity(params);
          } else {
            params.tokenA = selectedTokenData.firstToken?.contractAddress;
            params.tokenB = selectedTokenData.secondToken?.contractAddress;
            params.amountADesired = amountADesired;
            params.amountBDesired = amountBDesired;
            params.amountAMin = amountADesired;
            params.amountBMin = amountBDesired;
            params.toAddress = wallet.account;
            params.deadline = deadline;
            response = await web3Intraction.addLiquidity(params);
          }

          if (response.status == 1) {
            setFields({
              firstInput: 0,
              secondInput: 0,
            });
            const netWorkUrl = web3Intraction.getNetworkUrl();
            const payload = {
              transactionHash: response.transactionHash,
              rpc_url: netWorkUrl.url,
              explorerUrl: netWorkUrl?.blockExplorerUrls?.[0],
              receiptStatus: response?.status ? "success" : "failed",
              blockchain: "ethereum",
              transType: "add_liquidity",
              user: user._id,
              tokenA: {
                ...selectedTokenData.firstToken,
                tokenAmount: fields.firstInput,
              },
              tokenB: {
                ...selectedTokenData.secondToken,
                tokenAmount: fields.secondInput,
              },
            };
            dispatch(
              addTransaction(payload, () => {
                // toastr.success("Transaction successfully completed!")
                props?.onHide && props.onHide();
                props?.onFinish && props.onFinish(payload);
              })
            );
            setAddLiquidityLoading(false);
            return toastr.success("Transaction successfully done");
          } else {
            setAddLiquidityLoading(false);
            return toastr.error("Transaction error");
          }
        }
      }
    } catch (error) {
      setAddLiquidityLoading(false);
      return toastr.error(error);
    }
  };
  return (
    <Fade>
      <Selecttokenmod
        show={showTokenModal?.show}
        onHide={() => onHideTokenModal(false)}
        tokenModalNum={showTokenModal?.tokenModalNum}
        tokensList={pairsList}
        selectedToken={selectedTokenData}
        setSelectedToken={setSelectedTokenData}
      />

      <Createpoolmod
        show={Createpool}
        onHide={() => setCreatepool(false)}
        selectedToken={selectedTokenData}
        firstTokenAmount={fields?.firstInput}
        secondTokenAmount={fields?.secondInput}
      />
      <div className="Liquidity-main">
        <Row>
          <Col lg={7} md={6} sm={12}>
            <div className="liqudity_form">
              <div className="d-flex justify-content-center">
                {addLiquidityLoading && <Spinner style={{ color: "#fff" }} />}
              </div>
              <div className="Add_remove">
                <Button className="add-btn-here">Add</Button>
                {/* <Button className="remove_btn">Remove</Button> */}
              </div>

              <Form className="form_liqudity" onSubmit={onClickSupplyBtn}>
                <FormGroup className="input_here_wrap">
                  <div className="input_label">
                    <p>Input</p>
                    <p>
                      Balance ~{" "}
                      {tokenBalance?.tokenABalance != 0
                        ? parseFloat(
                            Number(tokenBalance?.tokenABalance).toFixed(4)
                          )
                        : 0}
                    </p>
                  </div>

                  <div className="input_wrap">
                    <Input
                      type="number"
                      name="firstInput"
                      id="exampleEmail"
                      placeholder="0.00"
                      value={fields.firstInput}
                      onChange={onChangeFieldsAmount}
                      required
                      min={0}
                      step={"any"}
                    />

                    <div className="max-here">
                      {/* <p className="max_here_txt">MAX</p> */}
                      <Button
                        className="btn-click"
                        onClick={() => handleShowTokenModal(true, 1)}
                      >
                        <img
                          src={selectedTokenData?.firstToken?.icon}
                          alt=""
                          width="30px"
                          className="img-fluid"
                        />
                        <p>
                          {selectedTokenData?.firstToken?.symbol || "Select"}
                        </p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                        >
                          <path
                            d="M21.625 11.625C21.8542 11.8542 21.9688 12.1458 21.9688 12.5C21.9688 12.8542 21.8542 13.1458 21.625 13.375L15.875 19.125C15.75 19.25 15.6146 19.3387 15.4688 19.3912C15.3229 19.4429 15.1667 19.4687 15 19.4687C14.8333 19.4687 14.6717 19.4375 14.515 19.375C14.3592 19.3125 14.2292 19.2292 14.125 19.125L8.375 13.375C8.14584 13.1458 8.03125 12.8542 8.03125 12.5C8.03125 12.1458 8.14584 11.8542 8.375 11.625C8.60417 11.3958 8.89583 11.2812 9.25 11.2812C9.60417 11.2812 9.89584 11.3958 10.125 11.625L15 16.5L19.875 11.625C20.1042 11.3958 20.3958 11.2812 20.75 11.2812C21.1042 11.2812 21.3958 11.3958 21.625 11.625Z"
                            fill="#EEEEEE"
                          />
                        </svg>
                      </Button>
                    </div>
                  </div>
                </FormGroup>

                <div className="plus_center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                  >
                    <g opacity="0.5">
                      <path
                        d="M13.75 23.75V16.25H6.25V13.75H13.75V6.25H16.25V13.75H23.75V16.25H16.25V23.75H13.75Z"
                        fill="#EEEEEE"
                      />
                    </g>
                  </svg>
                </div>

                <FormGroup className="input_here_wrap">
                  <div className="input_label">
                    <p>Input</p>
                    <p>
                      Balance ~{" "}
                      {tokenBalance?.tokenBBalance != 0
                        ? parseFloat(
                            Number(tokenBalance?.tokenBBalance).toFixed(4)
                          )
                        : 0}
                    </p>
                  </div>

                  <div className="input_wrap">
                    <Input
                      type="number"
                      name="secondInput"
                      id="exampleEmail"
                      placeholder="0.00"
                      value={fields.secondInput}
                      onChange={onChangeFieldsAmount}
                      required
                      min={0}
                      step={"any"}
                    />

                    <div className="max-here">
                      {/* <p className="max_here_txt">MAX</p> */}
                      <Button
                        className="btn-click"
                        onClick={() => handleShowTokenModal(true, 2)}
                      >
                        <img
                          src={selectedTokenData.secondToken?.icon}
                          alt=""
                          width="30px"
                          className="img-fluid"
                        />
                        <p>
                          {selectedTokenData?.secondToken?.symbol || "Select"}
                        </p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                        >
                          <path
                            d="M21.625 11.625C21.8542 11.8542 21.9688 12.1458 21.9688 12.5C21.9688 12.8542 21.8542 13.1458 21.625 13.375L15.875 19.125C15.75 19.25 15.6146 19.3387 15.4688 19.3912C15.3229 19.4429 15.1667 19.4687 15 19.4687C14.8333 19.4687 14.6717 19.4375 14.515 19.375C14.3592 19.3125 14.2292 19.2292 14.125 19.125L8.375 13.375C8.14584 13.1458 8.03125 12.8542 8.03125 12.5C8.03125 12.1458 8.14584 11.8542 8.375 11.625C8.60417 11.3958 8.89583 11.2812 9.25 11.2812C9.60417 11.2812 9.89584 11.3958 10.125 11.625L15 16.5L19.875 11.625C20.1042 11.3958 20.3958 11.2812 20.75 11.2812C21.1042 11.2812 21.3958 11.3958 21.625 11.625Z"
                            fill="#EEEEEE"
                          />
                        </svg>
                      </Button>
                    </div>
                  </div>
                </FormGroup>
                <Button
                  className="big-add-token Supply-btn py-3"
                  type="submit"
                  disabled={insufficientFunds}
                  onClick={handleSubmit}
                >
                  <p>{insufficientFunds ? "Insufficient Balance" : "Supply"}</p>
                </Button>
              </Form>
            </div>
          </Col>
          <Col lg={5} md={6} sm={12}>
            <div className="liqudity_img">
              <img src="/images/liqudityimg.png" alt="" className="img-fluid" />
            </div>
          </Col>
        </Row>
      </div>
    </Fade>
  );
};

export default LiqudityPool;

import React from "react";
import Headerlogin from "Component/Header/Headerlogin";
import Botpage from "Pages/Profile/Bot";

export default function Bottheme() {
  return (
    <>
       <Headerlogin heading="Bot"/>
       <Botpage/>
    </>
  );
}

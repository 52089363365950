import * as TYPES from "./actionTypes"

export const addStakeAmount = (user, callback) => {
  return {
    type: TYPES.STAKE_AMOUNT,
    payload: { user, callback },
  }
}

export const addStakeAmountSuccessful = user => {
  return {
    type: TYPES.STAKE_AMOUNT_SUCCESSFUL,
    payload: user,
  }
}

export const addStakeAmountFailed = user => {
  return {
    type: TYPES.STAKE_AMOUNT_FAILED,
    payload: user,
  }
}

export const unstakeAmount = (user, callback) => {
  return {
    type: TYPES.UNSTAKE_AMOUNT,
    payload: { user, callback },
  }
}

export const unstakeAmountSuccessful = user => {
  return {
    type: TYPES.UNSTAKE_AMOUNT_SUCCESSFUL,
    payload: user,
  }
}

export const unstakeAmountFailed = user => {
  return {
    type: TYPES.UNSTAKE_AMOUNT_FAILED,
    payload: user,
  }
}

/* Get StakeToken */
export const getStakeTokens = data => ({
  type: TYPES.GET_STAKE_TOKENS,
  payload: data,
})

export const getStakeTokensSuccess = stakeTokens => ({
  type: TYPES.GET_STAKE_TOKENS_SUCCESS,
  payload: stakeTokens,
})

export const getStakeTokensFail = error => ({
  type: TYPES.GET_STAKE_TOKENS_FAIL,
  payload: error,
})
/* Get StakeToken END */

/* Get Stake List */
export const getStakeList = data => ({
  type: TYPES.GET_STAKE_LIST,
  payload: data,
})

export const getStakeListSuccess = stakeTokens => ({
  type: TYPES.GET_STAKE_LIST_SUCCESS,
  payload: stakeTokens,
})

export const getStakeListFail = error => ({
  type: TYPES.GET_STAKE_LIST_FAIL,
  payload: error,
})
/* Get Stake List END */
import {
  VERIFY_OTP,
  VERIFY_OTP_SUCCESSFUL,
  VERIFY_OTP_FAILED
} from "./actionTypes"

export const verifyOtp = (user, callback) => {
  return {
    type: VERIFY_OTP,
    payload: { user, callback },
  }
}

export const verifyOtpSuccessful = user => {
  return {
    type: VERIFY_OTP_SUCCESSFUL,
    payload: user,
  }
}

export const verifyOtpFailed = user => {
  return {
    type: VERIFY_OTP_FAILED,
    payload: user,
  }
}
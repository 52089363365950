import React from "react";
import Headerlogin from "Component/Header/Headerlogin";
import Walletdashboard from "Pages/Profile/Wallet";

export default function Wallettheme() {
  return (
    <>
      <Headerlogin heading="Wallet" />
      <Walletdashboard/>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
//store
import { deleteBlog, getBlogs } from "store/actions";

//Component
import Bloguploadmodal from "Component/Modals/Bloguploadmodal";
import CardWrapper from "Component/BlogCards/CardWrapper";
import ConfirmMod from "Component/Modals/ConfirmModal";

const Blogpage = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.Login);
  const { blogs, loading } = useSelector((state) => state.Blogs);

  const [blogopen, setBlogOpen] = useState({
    isOpen: false,
    data: null,
  });
  const [confirmMod, setConfirmMod] = useState({
    isOpen: false,
    _id: null,
  });

  useEffect(() => {
    if (user?._id) {
      dispatch(getBlogs({ userId: user?._id }));
    }
  }, [JSON.stringify(user?._id)]);

  const handleFormPopup = (data) => {
    setBlogOpen((pre) => ({
      ...pre,
      isOpen: !pre.isOpen,
      data: data || null,
    }));
  };

  const handleConfirmPop = (_id) => {
    setConfirmMod((pre) => ({
      ...pre,
      isOpen: !pre.isOpen,
      _id: _id || null,
    }));
  };

  const removeBlog = () => {
    const callback = (success, res) => {
      if (!success) return toastr.error(res.message);
      else {
        handleConfirmPop();
        dispatch(
          getBlogs({
            userId: user?._id,
          })
        );
      }
    };
    dispatch(deleteBlog({ _id: confirmMod?._id }, callback));
  };

  return (
    <>
      <Bloguploadmodal
        editData={blogopen.data}
        show={blogopen.isOpen}
        onhide={() => handleFormPopup()}
      />

      <ConfirmMod
        show={confirmMod.isOpen}
        handlePop={handleConfirmPop}
        handleConfirm={removeBlog}
        loading={loading}
        title={"Are you sure?"}
      />

      <section className="section_expertprofile mt-3">
        <div className="shared_head d-flex justify-content-between align-items_center mb-3">
          <h3 className="card_heading">{props?.heading}</h3>
        </div>

        <div className="tabbing_profiler mt-4">
          <div className="btn_group d-flex gap-3">
            <Button className="add_new_blog active">Post</Button>
            <Button className="add_new_blog" onClick={() => handleFormPopup()}>
              Add New Post
            </Button>
          </div>
          <CardWrapper
            blogs={blogs}
            enableActions={true}
            handleFormPopup={handleFormPopup}
            removeBlog={handleConfirmPop}
            loading={loading}
          />
        </div>
      </section>
    </>
  );
};

export default Blogpage;

import React from "react";
import Liqudity from "Pages/Profile/Liqudity";
import Headerlogin from "Component/Header/Headerlogin";

export default function Liquditytheme() {
  return (
    <>
       <Headerlogin heading="Liquidity Pool" />
       <Liqudity/> 
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
import { Form, FormGroup } from "reactstrap";
import { Fade } from "react-reveal";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import toastr from "toastr";
import OtpInput from "react-otp-input";
import { postTfaValidateOtp } from "store/actions";

const Signin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (!location.state) {
      navigate("/sign-in", { replace: true });
    }
  }, [location.state]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const fields = {
      ...location.state,
      token: otp,
    };
    if (fields.role == "") {
      return toastr.error("Role is required!");
    }
    if (fields.email == "") {
      return toastr.error("Email is required!");
    }
    if (fields.password == "") {
      return toastr.error("Password is required!");
    }
    if (fields.token == "") {
      return toastr.error("OTP is required!");
    }
    dispatch(postTfaValidateOtp(fields));
  };

  return (
    <Fade>
      <section className="signup_main common_sign_main sign_in">
        <Container>
          <div className="sign_up_inner">
            <div className="logo_form mb-4">
              <img
                width={"150px"}
                src="/images/logo.png"
                alt=""
                className="img-fluid"
              />
            </div>
            <h2 className="text-center Signup_head mb-0">
              Two Factor Authentication
            </h2>

            <Form className="signup_form" onSubmit={handleSubmit}>
              <div className="dont_have_account my-4">
                <p>
                  Open the two-step verification app on your mobile device to
                  get your verification code.
                </p>
              </div>

              <FormGroup className="position-relative">
                <div className="otp_input">
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} type="number" />}
                  />
                </div>
              </FormGroup>

              <Button className="btn_common w-100 text-uppercase" type="submit">
                Authenticate
              </Button>
            </Form>
          </div>
        </Container>
      </section>
    </Fade>
  );
};

export default Signin;

import React from "react";
import Headerlogin from "Component/Header/Headerlogin";
import Buytoken from "Pages/Profile/Buytoken";


export default function Buyselltheme() {
  return (
    <>
       <Headerlogin heading="Dashboard"/>
       <Buytoken/>
    </>
  );
}

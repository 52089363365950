import React from "react";
import { Fade } from "react-reveal";
import TradingViewWidget, { Themes } from "react-tradingview-widget";
import { useParams, useSearchParams } from "react-router-dom";

const Tradeexperts = (props) => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  return (
    <section className="trade_new Opendertable-table pairprice_chart my-3">
      <div className="pairshared_table">
        <Fade>
          <div className="Liquidity-main trading_table">
            <div className="KYC-table" style={{ height: "500px" }}>
              <TradingViewWidget
                symbol={`${searchParams.get("exchange")}:${params.symbol}`}
                theme={Themes.DARK}
                hide_side_toolbar={false}
                autosize
                interval={5}
                // range={5}
                timezone="Etc/UTC"
                style="1"
                locale="us"
              />
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default Tradeexperts;

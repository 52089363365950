import {
  GET_ALL_PAIRS_LIST_FAIL,
  GET_ALL_PAIRS_LIST_REQUEST,
  GET_ALL_PAIRS_LIST_SUCCESS,
  GET_TOP_TRADE_PAIRS_FAIL,
  GET_TOP_TRADE_PAIRS_REQUEST,
  GET_TOP_TRADE_PAIRS_SUCCESS,
  GET_PAIR_DETAILS_FAIL,
  GET_PAIR_DETAILS,
  GET_PAIR_DETAILS_SUCCESS,
  GET_TRADE_TOKEN_DETAILS_FAIL,
  GET_TRADE_TOKEN_DETAILS,
  GET_TRADE_TOKEN_DETAILS_SUCCESS
} from "./actionTypes";

import * as CONST from "./actionTypes";

export const getAllPairsListRequest = (data, callback) => {
  return {
    type: GET_ALL_PAIRS_LIST_REQUEST,
    payload: { data, callback },
  };
};

export const getAllPairsListSuccess = (data) => {
  return {
    type: GET_ALL_PAIRS_LIST_SUCCESS,
    payload: data,
  };
};

export const getAllPairsListFail = (error) => {
  return {
    type: GET_ALL_PAIRS_LIST_FAIL,
    payload: error,
  };
};

///GET TRADE TOKEN

export const getTradeTokenRequest = (data, callback) => {
  return {
    type: CONST.GET_TRADE_TOKENS_REQUEST,
    payload: { data, callback },
  };
};

export const getTradeTokenSuccess = (data) => {
  return {
    type: CONST.GET_TRADE_TOKENS_SUCCESS,
    payload: data,
  };
};

export const getTradeTokenFail = (error) => {
  return {
    type: CONST.GET_TRADE_TOKENS_FAIL,
    payload: error,
  };
};

export const getTopTradePairsRequest = (data, callback) => {
  return {
    type: GET_TOP_TRADE_PAIRS_REQUEST,
    payload: { data, callback },
  };
};

export const getTopTradePairsSuccess = (data) => {
  return {
    type: GET_TOP_TRADE_PAIRS_SUCCESS,
    payload: data,
  };
};

export const getTopTradePairsFail = (error) => {
  return {
    type: GET_TOP_TRADE_PAIRS_FAIL,
    payload: error,
  };
};

export const getPairDetails = (data, callback) => {
  return {
    type: GET_PAIR_DETAILS,
    payload: { data, callback },
  };
};

export const getPairDetailsSuccess = (data) => {
  return {
    type: GET_PAIR_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getPairDetailsFail = (error) => {
  return {
    type: GET_PAIR_DETAILS_FAIL,
    payload: error,
  };
};


export const getTradeDetails = (data, callback) => {
  return {
    type: CONST.GET_TRADE_DETAILS,
    payload: { data, callback },
  };
};

export const getTradeDetailsSuccess = (data) => {
  return {
    type: CONST.GET_TRADE_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getTradeDetailsFail = (error) => {
  return {
    type: CONST.GET_TRADE_DETAILS_FAIL,
    payload: error,
  };
};

export const getTokenCurrentPrice = (data, callback) => {
  return {
    type: CONST.GET_TOKEN_CURRENT_PRICE,
    payload: { data, callback },
  };
};

export const getTokenCurrentPriceSuccess = (data) => {
  return {
    type: CONST.GET_TOKEN_CURRENT_PRICE_SUCCESS,
    payload: data,
  };
};

export const getTokenCurrentPriceFail = (error) => {
  return {
    type: CONST.GET_TOKEN_CURRENT_PRICE_FAIL,
    payload: error,
  };
};


export const getCandlesList = (data, callback) => {
  return {
    type: CONST.GET_CANDLES_LIST,
    payload: { data, callback },
  };
};

export const getCandlesListSuccess = (data) => {
  return {
    type: CONST.GET_CANDLES_LIST_SUCCESS,
    payload: data,
  };
};

export const getCandlesListFail = (error) => {
  return {
    type: CONST.GET_CANDLES_LIST_FAIL,
    payload: error,
  };
};

import React from "react";
import { Spinner } from "reactstrap";
function Loading({size, textDisable}) {
  return (
    <div className="spinner text-center">
      <Spinner
        as="span"
        animation="border"
        size={size || "sm"}
        role="status"
        aria-hidden="true"
        style={{ color: "#fff" }}
      />
     {!textDisable && <span className="text-white"> Please wait...</span>}
    </div>
  );
}

export default Loading;

import React, { useState } from "react";
import { Fade } from "react-reveal";
import LiqudityPool from "./LiqudityPool";
import MyPool from "./MyPool";


export default function Liqudity() {
  const [state, setState] = useState({ tab: "tab1" });

  return (
    <>
      <Fade>
        <section className="total_tabbing">
          <div className="tabbing-herewrap">
            <div className="tab-frame dashboard-frame Sendrecieve">
              <div className="clearfix">
                <div className="label-wrap">
                  <input
                    type="radio"
                    name="tab"
                    id="tab1"
                    checked={state.tab == "tab1"}
                    onClick={() => setState({ tab: "tab1" })}
                  />
                  <label for="tab1">Liquidity Pool</label>
                </div>

                <div className="label-wrap">
                  <input
                    type="radio"
                    name="tab"
                    id="tab2"
                    checked={state.tab == "tab2"}
                    onClick={() => setState({ tab: "tab2" })}
                  />
                  <label for="tab2">My Pool</label>
                </div>
              </div>

              {state.tab == "tab1" && (
                <LiqudityPool />
              )}

              {state.tab == "tab2" && (
                <MyPool />
              )}
            </div>
          </div>
        </section>
      </Fade>
    </>
  );
}

import React from "react";
import Headerlogin from "Component/Header/Headerlogin";
import Tradeleverage from "Pages/Profile/Tradeleverage";


export default function Tradelistileverage() {
  return (
    <>
       <Headerlogin heading="Dashboard"/>
       <Tradeleverage/>
    </>
  );
}

import * as CONST from "./actionTypes";

export const getExpert = (payload) => ({
  type: CONST.GET_EXPERT,
  payload: payload,
});

export const getExpertSuccess = (data) => ({
  type: CONST.GET_EXPERT_SUCCESS,
  payload: data,
});

export const getExpertFail = (error) => ({
  type: CONST.GET_EXPERT_FAIL,
  payload: error,
});




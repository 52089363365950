export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGIN_OTP = "LOGIN_OTP";

export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";
export const GET_CURRENT_USER_FAIL = "GET_CURRENT_USER_FAIL";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const API_ERROR = "LOGIN_API_ERROR";

export const SOCIAL_LOGIN = "SOCIAL_LOGIN";

export const GET_LANGUAGES = "GET_LANGUAGES";
export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS";
export const GET_LANGUAGES_FAIL = "GET_LANGUAGES_FAIL";

export const GET_TFA_OTP = "GET_TFA_OTP";
export const POST_TFA_VERIFY_OTP = "POST_TFA_VERIFY_OTP";
export const POST_TFA_VALIDATE_OTP = "POST_TFA_VALIDATE_OTP";
export const POST_TFA_DISABLE_OTP = "POST_TFA_DISABLE_OTP";

export const LOGIN_WALLET_REQUEST = "LOGIN_WALLET_REQUEST";
export const LOGIN_WALLET_SUCCESS = "LOGIN_WALLET_SUCCESS";
export const LOGIN_WALLET_FAIL = "LOGIN_WALLET_FAIL";
import React from "react";
import Headerlogin from "Component/Header/Headerlogin";
import Leveragetrade from "Pages/Profile/Leveragetrade";

export default function Leveragetradetheme() {
  return (
    <>
       <Headerlogin heading="Dashboard"/>
       <Leveragetrade/>
    </>
  );
}

import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";

const tokenselect = [
  {
    token_icon: "/images/Tethersel.png",
    token_name: "Tether",
    token_short: "(USDT)",
    token_value: "0000000",
  },
  {
    token_icon: "/images/USDcoin.png",
    token_name: "USD Coin",
    token_short: "(USDC)",
    token_value: "0000000",
  },
  {
    token_icon: "/images/Binancetoken.png",
    token_name: "Binance",
    token_short: "(BUSD)",
    token_value: "0000000",
  },
  {
    token_icon: "/images/true.png",
    token_name: "True USD",
    token_short: "(TUSD)",
    token_value: "0000000",
  },
  {
    token_icon: "/images/Tethersel.png",
    token_name: "Tether",
    token_short: "(USDT)",
    token_value: "0000000",
  },
  {
    token_icon: "/images/USDcoin.png",
    token_name: "USD Coin",
    token_short: "(USDC)",
    token_value: "0000000",
  },
  {
    token_icon: "/images/Binancetoken.png",
    token_name: "Binance",
    token_short: "(BUSD)",
    token_value: "0000000",
  },
  {
    token_icon: "/images/true.png",
    token_name: "True USD",
    token_short: "(TUSD)",
    token_value: "0000000",
  },
  {
    token_icon: "/images/Tethersel.png",
    token_name: "Tether",
    token_short: "(USDT)",
    token_value: "0000000",
  },
  {
    token_icon: "/images/USDcoin.png",
    token_name: "USD Coin",
    token_short: "(USDC)",
    token_value: "0000000",
  },
  {
    token_icon: "/images/Binancetoken.png",
    token_name: "Binance",
    token_short: "(BUSD)",
    token_value: "0000000",
  },
  {
    token_icon: "/images/true.png",
    token_name: "True USD",
    token_short: "(TUSD)",
    token_value: "0000000",
  },
];

export default function Selecttokenmod(props) {
  const { tokensList, tokenModalNum, setSelectedToken, selectedToken } = props;
  const [uniqueTokens, setUniqueTokens] = useState([]);

  useEffect(() => {
    if (tokensList?.length) {
      if (tokenModalNum === 1) {
        const uniqueList = [];
        tokensList.forEach((item) => {
          const findPrimary = uniqueList.find(
            (uItem) => uItem?.symbol === item?.primaryToken?.symbol
          );

          const findSecondary = uniqueList.find(
            (uItem) => uItem?.symbol === item?.secondryToken?.symbol
          );

          if (!findPrimary) {
            uniqueList.push({ ...item?.primaryToken, pairId: item._id });
          }

          if (!findSecondary) {
            uniqueList.push({ ...item?.secondryToken, pairId: item._id });
          }
        });
        setUniqueTokens(uniqueList);
      }
      if (tokenModalNum === 2) {
        const uniqueList = [];
        tokensList.forEach((item) => {
          if (
            item?.primaryToken?.symbol === selectedToken?.firstToken?.symbol
          ) {
            const findTokenList = uniqueList.find(
              (uItem) => uItem?.symbol === item?.secondryToken?.symbol
            );
            if (!findTokenList) {
              uniqueList.push({ ...item.secondryToken, pairId: item._id });
            }
          }
          if (
            item?.secondryToken?.symbol === selectedToken?.firstToken?.symbol
          ) {
            const findTokenList = uniqueList.find(
              (uItem) => uItem?.symbol === item?.primaryToken?.symbol
            );
            if (!findTokenList) {
              uniqueList.push({ ...item.primaryToken, pairId: item._id });
            }
          }
        });
        setUniqueTokens(uniqueList);
      }
    }
  }, [tokensList, tokenModalNum]);

  const onSelectTokenHandler = (row, modalNum) => {
    const findPairsData = tokensList?.find((item) => item?._id === row?.pairId);
    if (findPairsData) {
      if (modalNum === 1) {
        setSelectedToken((prev) => ({ ...prev, firstToken: row }));
      } else {
        setSelectedToken((prev) => ({ ...prev, secondToken: row }));
      }
      props?.onHide();
    }
  };

  const navigate = useNavigate();
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="trasaxction-mod selectoken_mod"
    >
      <Button className="close-btn" onClick={props.onHide}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M6.75806 17.2431L12.0011 12.0001M17.2441 6.75708L12.0001 12.0001M12.0001 12.0001L6.75806 6.75708M12.0011 12.0001L17.2441 17.2431"
            stroke="#fff"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Button>
      <Modal.Body>
        <div className="select_token_modal">
          <h6 className="green-col">Select a token</h6>

          <Form className="token_search">
            <div className="select_token_here">
              <ul>
                {(uniqueTokens || [])?.map((item, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => onSelectTokenHandler(item, tokenModalNum)}
                    >
                      <div className="token_search_list">
                        <div className="token-icon">
                          <div className="token_icon">
                            <img src={item.icon} alt="" className="img-fluid" />
                          </div>
                          <p>
                            <strong>{item.name}</strong> {item.symbol}
                          </p>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

import React from "react";
import OTP from "Pages/OTP";

export default function OTPtheme() {
  return (
    <>
      <OTP/>
    </>
  );
}

import {
  LOGIN_USER,
  LOGIN_OTP,
  LOGIN_SUCCESS,
  GET_CURRENT_USER,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAIL,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  GET_LANGUAGES,
  GET_LANGUAGES_SUCCESS,
  GET_LANGUAGES_FAIL,
  LOGIN_WALLET_REQUEST,
  LOGIN_WALLET_SUCCESS,
  LOGIN_WALLET_FAIL,
} from "./actionTypes";

const { data } = JSON.parse(localStorage.getItem("authUser") || "{}");
const userD = JSON.parse(localStorage.getItem("userData") || "{}");

const initialState = {
  meLoading: false,
  isMeFailed: false,
  error: "",
  loading: false,
  user: userD?.data || {},
  authToken: data?.Token || null,
  languages: [],
  timezones: [],
  countries: [],
  currencies: [],
  loadingLoginWithWallet : false
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        error: "",
        loading: true,
      };
    case LOGIN_OTP:
      return {
        ...state,
        error: "",
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        error: "",
        user: action.payload.data,
        authToken: action.payload.data.Token,
        loading: false,
      };
    case GET_CURRENT_USER:
      return {
        ...state,
        error: "",
      };
    case GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        error: "",
        user: action.payload.data,
        meLoading: false,
        isMeFailed: false,
      };

    case GET_CURRENT_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        isMeFailed: true,
        meLoading: false,
      };
    case LOGOUT_USER:
      return { ...state };
    case LOGOUT_USER_SUCCESS:
      return { ...state, user: {}, authToken: null };
    case API_ERROR:
      return { ...state, error: action.payload, loading: false };

    case GET_LANGUAGES_SUCCESS:
      return { ...state, ...action.payload };
    
    case LOGIN_WALLET_REQUEST:
       return {...state,
        loadingLoginWithWallet : true, 
        error : ""
      };
    case LOGIN_WALLET_SUCCESS:
       return {...state,
        loadingLoginWithWallet : false,
        error: "",
        user: action.payload.data,
        authToken: action.payload.data.Token
      };
    case LOGIN_WALLET_FAIL:
      return {
        ...state,
        loadingLoginWithWallet : false, 
        error: ""};
    default:
      return { ...state };
  }
  return state;
};

export default login;

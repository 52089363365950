import React from "react";
import Headerlogin from "Component/Header/Headerlogin";
import StocksView from "Pages/Profile/Stocks/View";

export default function Stockstheme() {
  return (
    <>
      <Headerlogin heading="" />
      <StocksView />
    </>
  );
}

import {
  GET_ALL_PAIRS_LIST_REQUEST,
  GET_ALL_PAIRS_LIST_SUCCESS,
  GET_ALL_PAIRS_LIST_FAIL,
  GET_TOP_TRADE_PAIRS_REQUEST,
  GET_TOP_TRADE_PAIRS_SUCCESS,
  GET_TOP_TRADE_PAIRS_FAIL,
  GET_PAIR_DETAILS_FAIL,
  GET_PAIR_DETAILS,
  GET_PAIR_DETAILS_SUCCESS,
  GET_TRADE_TOKEN_DETAILS_FAIL,
  GET_TRADE_TOKEN_DETAILS,
  GET_TRADE_TOKEN_DETAILS_SUCCESS,
} from "./actionTypes";
import * as CONST from "./actionTypes";

const { data, ...authUser } = JSON.parse(
  localStorage.getItem("authUser") || "{}"
);

const initialState = {
  loading: false,
  pairsList: [],
  totalPairTokens: 0,
  error: "",
  topTradePairs: [],
  pairDetails: {},
  tradeDetails: null,
  loadingTradePairs: false,
  tradeTokenDetails: {},
  loadingTradeToken: false,
  tradeTokens: [],
  totalTradeTokens: 0,
  tokenCurrentPrice: {},

  candles: null,
  totalCandles: 0,
};

const CryptoReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PAIRS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        pairsList: [],
      };
    case GET_ALL_PAIRS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        pairsList: action.payload.data,
        totalPairTokens: action.payload.totalCount,
      };

    case GET_ALL_PAIRS_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CONST.GET_TRADE_TOKENS_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        pairsList: [],
      };
    case CONST.GET_TRADE_TOKENS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        tradeTokens: action.payload.data,
        totalTradeTokens: action.payload.totalCount,
      };

    case CONST.GET_TRADE_TOKENS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_TOP_TRADE_PAIRS_REQUEST:
      return {
        ...state,
        loadingTradePairs: true,
        error: "",
        topTradePairs: [],
      };
    case GET_TOP_TRADE_PAIRS_SUCCESS:
      return {
        ...state,
        loadingTradePairs: false,
        error: "",
        topTradePairs: action.payload.data,
      };

    case GET_TOP_TRADE_PAIRS_FAIL:
      return {
        ...state,
        loadingTradePairs: false,
        error: action.payload,
      };
    case GET_PAIR_DETAILS:
      return {
        ...state,
        loadingTradePairs: true,
        error: "",
        pairDetails: {},
      };
    case GET_PAIR_DETAILS_SUCCESS:
      return {
        ...state,
        loadingTradePairs: false,
        error: "",
        pairDetails: action.payload.data,
      };

    case GET_PAIR_DETAILS_FAIL:
      return {
        ...state,
        loadingTradePairs: false,
        error: action.payload,
      };

    case CONST.GET_TOKEN_CURRENT_PRICE:
      return {
        ...state,
        error: "",
        tokenCurrentPrice: null,
      };
    case CONST.GET_TOKEN_CURRENT_PRICE_SUCCESS:
      return {
        ...state,
        error: "",
        tokenCurrentPrice: action.payload.data,
      };

    case CONST.GET_TOKEN_CURRENT_PRICE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case CONST.GET_TRADE_DETAILS:
      return {
        ...state,
        loadingTradePairs: true,
        error: "",
        tradeDetails: null,
      };
    case CONST.GET_TRADE_DETAILS_SUCCESS:
      return {
        ...state,
        loadingTradePairs: false,
        error: "",
        tradeDetails: action.payload.data,
      };

    case CONST.GET_TRADE_DETAILS_FAIL:
      return {
        ...state,
        loadingTradePairs: false,
        error: action.payload,
      };

      case CONST.GET_CANDLES_LIST:
        return {
          ...state,
          loading: true,
          error: "",

        };
      case CONST.GET_CANDLES_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          error: "",
          candles: action.payload.data,
          totalCandles: action.payload.totalCount,
        };
  
      case CONST.GET_CANDLES_LIST_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

    default:
      return { ...state };
  }
};

export default CryptoReducer;

var io = require("socket.io-client");
export var socket = io.connect("https://leverage-api.suffescom.dev", {
  // autoConnect: false,
  reconnectionDelay: 1000,
  reconnection: true,
  transports: ["websocket"],
  jsonp: false,
  agent: false,
  rejectUnauthorized: false,
  timeout: 20000,
});

// // Add a connect listener
// socket.on("connect", function (e) {
//   console.log("Connected!", e);
//   const engine = socket.io.engine;
//   console.log(engine.transport.name);
//   engine.on("close", (e) => {
//     // called when the underlying connection is closed
//     console.log(e, "close reason")
//   });
// });

// // Automatically attempt to reconnect
// socket.on('reconnect', (e) => {
//   console.log('reconnect', e);
// });

// socket.on('user-disconnected', (e) => {
//   console.log('User Disconnected', e);
//   socket.connect();
// });

// //disconnect listner
// socket.on('disconnect', (e) => {
//   console.log('Disconnected', e);
//   socket.connect();
// });

// socket.on("connect_error", (error) => {
//   console.log(error, "error connect")
// });

import React, { useState, useEffect, useMemo } from "react";

import { BIT_FINEX_SOCKET } from "helpers/constants";

var W3CWebSocket = require("websocket").w3cwebsocket;

export const FundsContext = React.createContext({
  bitFinexValue: 0,
  selectedToken: null,
  selectToken: () => {},
});

export const FundsProvider = ({ children }) => {
  const [bitFinexValue, setBitFinexValue] = useState(0);
  const [selectedToken, setSelectedToken] = useState(null);

  const selectToken = (token) => {
    setSelectedToken(token);
  };

  useEffect(() => {
    if (!selectedToken) return;
    const client = new W3CWebSocket(BIT_FINEX_SOCKET);
    client.onmessage = async (msg) => {
      const resp = JSON.parse(msg.data.toString());
      if (resp[1] && resp[1].length == 10) {
        const currentPrice = resp[1][6];
        setBitFinexValue(currentPrice);
      }
    };
    let msg = JSON.stringify({
      event: "subscribe",
      channel: "ticker",
      symbol: `t${
        selectedToken &&
        selectedToken.bitFinexSymbol &&
        selectedToken?.bitFinexSymbol
      }`,
    });

    client.onopen = () => client.send(msg, "msg");

    return () => {
      client.close();
    };
  }, [selectedToken]);

  const values = useMemo(
    () => ({
      selectedToken,
      selectToken,
      bitFinexValue,
    }),
    [selectedToken, bitFinexValue, selectToken]
  );

  return (
    <FundsContext.Provider value={values}>{children}</FundsContext.Provider>
  );
};

import React from "react";
import Headerlogin from "Component/Header/Headerlogin";
import Leaderboard from "Pages/Profile/Leaderboard";


export default function Leaderboardtheme() {
  return (
    <>
      <Headerlogin heading="Leaderboard" />
      <Leaderboard />
    </>
  );
}

import * as TYPES from "./actionTypes";

const initialState = {
  message: null,
  loading: false,
  bots: [],
  orderDetails: null,
  totalBots: 0,
};

const order = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.MAKE_BOT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TYPES.MAKE_BOT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case TYPES.MAKE_BOT_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
      };
    case TYPES.EDIT_BOT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TYPES.EDIT_BOT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case TYPES.EDIT_BOT_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.CANCEL_BOT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TYPES.CANCEL_BOT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case TYPES.CANCEL_BOT_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
      };

    /* Get bots List */
    case TYPES.GET_BOT_REQUESTS:
      return {
        ...state,
        loading: true,
      };

    case TYPES.GET_BOT_REQUESTS_SUCCESS:
      return {
        ...state,
        bots: action.payload.data || [],
        totalBots: action.payload.totalCount || 0,
        loading: false,
        error: "",
      };

    case TYPES.GET_BOT_REQUESTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Get bots List END */

    case TYPES.SET_REQUEST_DETAIL:
      return {
        ...state,
        orderDetails: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default order;

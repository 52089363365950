import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Stake Redux states
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";
import * as API from "helpers/backend_helper";

function* addStakeAmount({ payload: { user, callback } }) {
  try {
    const response = yield call(API.stakeAmount, user);

    callback && callback(response);
    if (response.status == "failure") {
      return yield put(ACTIONS.addStakeAmountFailed(response.message));
    }
    yield put(ACTIONS.addStakeAmountSuccessful(response));
  } catch (error) {
    yield put(ACTIONS.addStakeAmountFailed("Internal Server Error"));
  }
}

function* unstakeAmount({ payload: { user, callback } }) {
  try {
    const response = yield call(API.unstakeAmount, user);

    callback && callback(response);
    if (response.status == "failure") {
      return yield put(ACTIONS.unstakeAmountFailed(response.message));
    }
    yield put(ACTIONS.unstakeAmountSuccessful(response));
  } catch (error) {
    yield put(ACTIONS.unstakeAmountFailed("Internal Server Error"));
  }
}

function* fetchStakeTokens({ payload }) {
  try {
    const response = yield call(API.getStakeTokens, payload)

    if (response.status == "failure") {
      return yield put(ACTIONS.getStakeTokensFail(response.message))
    }

    yield put(ACTIONS.getStakeTokensSuccess(response))
  } catch (error) {
    yield put(ACTIONS.getStakeTokensFail(error))
  }
}

function* fetchStakeList({ payload }) {
  try {
    const response = yield call(API.getStakeList, payload)

    if (response.status == "failure") {
      return yield put(ACTIONS.getStakeListFail(response.message))
    }

    yield put(ACTIONS.getStakeListSuccess(response))
  } catch (error) {
    yield put(ACTIONS.getStakeListFail(error))
  }
}

export function* Stake() {
  yield takeEvery(TYPES.STAKE_AMOUNT, addStakeAmount);
  yield takeEvery(TYPES.UNSTAKE_AMOUNT, unstakeAmount);
  yield takeEvery(TYPES.GET_STAKE_TOKENS, fetchStakeTokens);
  yield takeEvery(TYPES.GET_STAKE_LIST, fetchStakeList);
}

function* StakeSaga() {
  yield all([fork(Stake)]);
}

export default StakeSaga;

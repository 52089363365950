import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Hotpairs from "../Hotpairs";
import Leveragetrading from "./leveragetrading";

const Buytoken = () => {
  return (
    <section className="trade_new my-3 mb-5">
      <Container fluid>
        <Hotpairs />
        <Leveragetrading/>
      </Container>
    </section>
  );
};

export default Buytoken;

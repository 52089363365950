// GET_TRANSACTION
export const GET_TRANSACTION = "GET_TRANSACTION";
export const GET_TRANSACTION_FAIL = "GET_TRANSACTION_FAIL";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";

// ADD_TRANSACTION
export const ADD_TRANSACTION = "ADD_TRANSACTION";
export const ADD_TRANSACTION_FAIL = "ADD_TRANSACTION_FAIL";
export const ADD_TRANSACTION_SUCCESS = "ADD_TRANSACTION_SUCCESS";


// ADD_INVESTMENT
export const ADD_INVESTMENT = "ADD_INVESTMENT";
export const ADD_INVESTMENT_FAIL = "ADD_INVESTMENT_FAIL";
export const ADD_INVESTMENT_SUCCESS = "ADD_INVESTMENT_SUCCESS";


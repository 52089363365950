import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import * as TYPES from "./actionTypes";
import * as ACTIONS from "store/actions";
import * as API from "helpers/backend_helper";

function* getPairsListReq({ payload: { data, callback } }) {
  try {
    const response = yield call(API.getAllPairsListApi, data);

    if (response.status == "failure") {
      return yield put(ACTIONS.getAllPairsListFail(response.message));
    }
    yield put(ACTIONS.getAllPairsListSuccess(response));
    callback && callback();
  } catch (error) {
    yield put(ACTIONS.getAllPairsListFail("Internal Server Error"));
  }
}

function* getTradeTokenReq({ payload: { data, callback } }) {
  try {
    const response = yield call(API.getTradeTokensApi, data);

    if (response.status == "failure") {
      return yield put(ACTIONS.getTradeTokenFail(response.message));
    }
    yield put(ACTIONS.getTradeTokenSuccess(response));
    callback && callback();
  } catch (error) {
    yield put(ACTIONS.getTradeTokenFail("Internal Server Error"));
  }
}

function* getTopTradePairsReq({ payload: { data, callback } }) {
  try {
    const response = yield call(API.getTopTradePairsApi, data);

    if (response.status == "failure") {
      return yield put(ACTIONS.getTopTradePairsFail(response.message));
    }
    yield put(ACTIONS.getTopTradePairsSuccess(response));
    callback && callback();
  } catch (error) {
    yield put(ACTIONS.getTopTradePairsFail("Internal Server Error"));
  }
}

function* getPairDetails({ payload: { data, callback } }) {
  try {
    const response = yield call(API.pairDetails, data);

    if (response.status == "failure") {
      return yield put(ACTIONS.getPairDetailsFail(response.message));
    }
    yield put(ACTIONS.getPairDetailsSuccess(response));
    callback && callback();
  } catch (error) {
    yield put(ACTIONS.getPairDetailsFail("Internal Server Error"));
  }
}

function* getTradeDetails({ payload: { data, callback } }) {
  try {
    const response = yield call(API.getTradeDetails, data);

    if (response.status == "failure") {
      return yield put(ACTIONS.getTradeDetailsFail(response.message));
    }
    yield put(ACTIONS.getTradeDetailsSuccess(response));
    callback && callback();
  } catch (error) {
    yield put(ACTIONS.getTradeDetailsFail("Internal Server Error"));
  }
}

function* getTokenCurrentPrice({ payload: { data, callback } }) {
  try {
    const response = yield call(API.getTokenCurrentPrice, data);

    callback && callback(response);
    if (response.status == "failure") {
      return yield put(ACTIONS.getTokenCurrentPriceFail(response.message));
    }
    yield put(ACTIONS.getTokenCurrentPriceSuccess(response));
  } catch (error) {
    yield put(ACTIONS.getTokenCurrentPriceFail("Internal Server Error"));
  }
}

function* getCandlesListReq({ payload: { data, callback } }) {
  try {
    const response = yield call(API.getCandlesList, data);

    if (response.status == "failure") {
      return yield put(ACTIONS.getCandlesListFail(response.message));
    }
    yield put(ACTIONS.getCandlesListSuccess(response));
    callback && callback(response.data);
  } catch (error) {
    yield put(ACTIONS.getCandlesListFail("Internal Server Error"));
  }
}

function* CryptoSaga() {
  yield takeEvery(TYPES.GET_ALL_PAIRS_LIST_REQUEST, getPairsListReq);
  yield takeEvery(TYPES.GET_TOP_TRADE_PAIRS_REQUEST, getTopTradePairsReq);
  yield takeEvery(TYPES.GET_PAIR_DETAILS, getPairDetails);
  yield takeEvery(TYPES.GET_TRADE_TOKENS_REQUEST, getTradeTokenReq);
  yield takeEvery(TYPES.GET_TRADE_DETAILS, getTradeDetails);
  yield takeEvery(TYPES.GET_TOKEN_CURRENT_PRICE, getTokenCurrentPrice);
  yield takeEvery(TYPES.GET_CANDLES_LIST, getCandlesListReq);
}

export default CryptoSaga;

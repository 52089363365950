import { all, fork } from "redux-saga/effects";

import Alert from "./Alert/saga";

//public
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import Register from "./auth/register/saga";
import Verify from "./auth/verify/saga";
import SendOtp from "./auth/sendOtp/saga";
import Crypto from "./crypto/saga";
import File from "./file/saga";
import settingsSaga from "./Settings/saga";
///transaction
import transaction from "./transaction/saga";
import stake from "./stake/saga";
import order from "./order/saga";
import expert from "./expert/saga";
import Blogs from "./blogs/saga";
import Bot from "./bot/saga";

export default function* rootSaga() {
  yield all([
    fork(Alert),
    fork(AuthSaga),
    fork(settingsSaga),
    ProfileSaga(),
    ForgetSaga(),
    Register(),
    LayoutSaga(),
    Verify(),
    SendOtp(),
    Crypto(),
    fork(File),
    fork(transaction),
    fork(stake),
    fork(order),
    fork(expert),
    fork(Blogs),
    fork(Bot)
  ]);
}

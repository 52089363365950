import * as TYPES from "./actionTypes"

const initialState = {
  message: null,
  loading: false,
  stakeTokens: [],
  totalStakeTokens: 0,
  stakeList: [],
  totalStakeList: 0,
}

const stake = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.STAKE_AMOUNT:
      return {
        ...state,
        loading: true,
      }

    case TYPES.STAKE_AMOUNT_SUCCESSFUL:
      return {
        ...state,
        loading: false
      }

    case TYPES.STAKE_AMOUNT_FAILED:
      return {
        ...state,
        loading: false
      }
    case TYPES.UNSTAKE_AMOUNT:
      return {
        ...state,
        loading: true,
      }

    case TYPES.UNSTAKE_AMOUNT_SUCCESSFUL:
      return {
        ...state,
        loading: false
      }

    case TYPES.UNSTAKE_AMOUNT_FAILED:
      return {
        ...state,
        loading: false
      }
    /* Get StakeToken */
    case TYPES.GET_STAKE_TOKENS:
      return {
        ...state,
        loading: true,
      }

    case TYPES.GET_STAKE_TOKENS_SUCCESS:
      return {
        ...state,
        stakeTokens: action.payload.data || [],
        totalStakeTokens: action.payload.totalCount || 0,
        loading: false,
        error: "",
      }

    case TYPES.GET_STAKE_TOKENS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    /* Get stakeToken END */

    /* Get Stake List */
    case TYPES.GET_STAKE_LIST:
      return {
        ...state,
        loading: true,
      }

    case TYPES.GET_STAKE_LIST_SUCCESS:
      return {
        ...state,
        stakeList: action.payload.data || [],
        totalStakeList: action.payload.totalCount || 0,
        loading: false,
        error: "",
      }

    case TYPES.GET_STAKE_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    /* Get stake List END */

    default:
      return state
  }
}

export default stake

import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Fade } from "react-reveal";
import { useNavigate, Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import toastr from "toastr";
import { useDispatch } from "react-redux";
import { registerUser } from "store/actions";
import { checkPassword, isEmailValid } from "helpers/constants";

const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passwordType, setPasswordType] = useState("password");
  const [cpasswordType, setcPasswordType] = useState("password");
  const [fields, setFields] = useState({
    username: "",
    email: "",
    countryCode: "",
    mobileNumber: "",
    role: "",
    password: "",
  });

  const [passwordInput, setPasswordInput] = useState("");
  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };

  const [cpasswordInput, setcPasswordInput] = useState("");
  const chandlePasswordChange = (evnt) => {
    setcPasswordInput(evnt.target.value);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const ctogglePassword = () => {
    if (cpasswordType === "password") {
      setcPasswordType("text");
      return;
    }
    setcPasswordType("password");
  };

  const handleChange = (name) => (e) => {
    setFields((prev) => ({ ...prev, [name]: e.target.value }));
  };

  const handleSubmit = () => {
    if (fields.role == "") {
      return toastr.error("Role is required!");
    }
    if (fields.username == "") {
      return toastr.error("Username is required!");
    }
    if (fields.email == "") {
      return toastr.error("Email is required!");
    }
    // if (fields.countryCode == "") {
    //   return toastr.error("Country Code is required!");
    // }
    // if (fields.mobileNumber == "") {
    //   return toastr.error("Mobile Number is required!");
    // }
    if (passwordInput == "") {
      return toastr.error("Password is required!");
    }
    if (cpasswordInput == "") {
      return toastr.error("Confirm password is required!");
    }

    if (!checkPassword(passwordInput)) {
      return toastr.error(
        "Min 8 characters which contain at least one numeric digit and a special character"
      );
    }
    if (passwordInput != cpasswordInput) {
      return toastr.error("Password and confirm password not match!");
    }
    if (!isEmailValid) {
      return toastr.error("Invalid email address!");
    }
    const callback = (response) => {
      if (response.status == "success") {
        // toastr.success(`Verification code successfully send on your register email(${response.data.email}) and mobile(${response.data.countryCode} ${response.data.mobileNumber}).`);
        // navigate("/otp-verify");
        toastr.success("Register user successfully!");
        navigate("/sign-in");
      } else {
        return toastr.error(response.message);
      }
    };
    dispatch(
      registerUser(
        {
          ...fields,
          password: passwordInput,
          countryCode: "+" + fields.countryCode,
        },
        callback
      )
    );
  };
  return (
    <Fade>
      <section className="signup_main">
        <Container>
          <div className="sign_up_inner">
            <div className="logo_form mb-4">
              <img
                width={"150px"}
                src="/images/logo.png"
                alt=""
                className="img-fluid"
              />
            </div>
            <h2 className="text-center Signup_head">Create Account</h2>
            <Form className="signup_form">
              <div className="checks d-flex justify-content-evenly">
                <FormGroup check className="check_box">
                  <Label check>
                    <Input
                      type="radio"
                      name="radio1"
                      value="USER"
                      onChange={handleChange("role")}
                    />{" "}
                    Normal User
                  </Label>
                </FormGroup>
                <FormGroup check className="check_box">
                  <Label check>
                    <Input
                      type="radio"
                      name="radio1"
                      value="EXPERT"
                      onChange={handleChange("role")}
                    />{" "}
                    Expert User
                  </Label>
                </FormGroup>
              </div>
              <FormGroup className="position-relative">
                <Input
                  type="email"
                  id="exampleEmail"
                  placeholder="Email"
                  onChange={handleChange("email")}
                />

                <div className="leftform_icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M4.16663 4.16669H15C15.663 4.16669 16.2989 4.43008 16.7677 4.89892C17.2366 5.36776 17.5 6.00365 17.5 6.66669V14.1667C17.5 14.8297 17.2366 15.4656 16.7677 15.9345C16.2989 16.4033 15.663 16.6667 15 16.6667H4.16663C3.50358 16.6667 2.8677 16.4033 2.39886 15.9345C1.93002 15.4656 1.66663 14.8297 1.66663 14.1667V6.66669C1.66663 6.00365 1.93002 5.36776 2.39886 4.89892C2.8677 4.43008 3.50358 4.16669 4.16663 4.16669ZM4.16663 5.00002C3.74996 5.00002 3.38329 5.14169 3.09996 5.39169L9.58329 9.58335L16.0666 5.39169C15.7833 5.14169 15.4166 5.00002 15 5.00002H4.16663ZM9.58329 10.5917L2.60829 6.06669C2.54163 6.25002 2.49996 6.45835 2.49996 6.66669V14.1667C2.49996 14.6087 2.67555 15.0326 2.98811 15.3452C3.30068 15.6578 3.7246 15.8334 4.16663 15.8334H15C15.442 15.8334 15.8659 15.6578 16.1785 15.3452C16.491 15.0326 16.6666 14.6087 16.6666 14.1667V6.66669C16.6666 6.45835 16.625 6.25002 16.5583 6.06669L9.58329 10.5917Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </FormGroup>

              {/* <div className="phonenumber_signup">
                <FormGroup>
                  <PhoneInput
                    country={"us"}
                    // onChange={handleChange("countryCode")}
                    value={fields.countryCode}
                    onChange={(countryCode) => setFields(prev => ({ ...prev, countryCode }))}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="number"
                    id="exampleMobileNumber"
                    placeholder="56998 556 5625"
                    onChange={handleChange("mobileNumber")}
                  />
                </FormGroup>
              </div> */}

              <FormGroup className="position-relative">
                <Input
                  type="text"
                  // id="exampleUser"
                  placeholder="Username"
                  onChange={handleChange("username")}
                />
              </FormGroup>

              <FormGroup className="position-relative">
                <Input
                  type={passwordType}
                  id="pass"
                  placeholder="Password"
                  onChange={handlePasswordChange}
                  value={passwordInput}
                />

                {passwordType === "password" ? (
                  <Button className="eye_comon" onClick={togglePassword}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#fff"
                        d="M2 5.27L3.28 4L20 20.72L18.73 22l-3.08-3.08c-1.15.38-2.37.58-3.65.58c-5 0-9.27-3.11-11-7.5c.69-1.76 1.79-3.31 3.19-4.54L2 5.27M12 9a3 3 0 0 1 3 3a3 3 0 0 1-.17 1L11 9.17A3 3 0 0 1 12 9m0-4.5c5 0 9.27 3.11 11 7.5a11.79 11.79 0 0 1-4 5.19l-1.42-1.43A9.862 9.862 0 0 0 20.82 12A9.821 9.821 0 0 0 12 6.5c-1.09 0-2.16.18-3.16.5L7.3 5.47c1.44-.62 3.03-.97 4.7-.97M3.18 12A9.821 9.821 0 0 0 12 17.5c.69 0 1.37-.07 2-.21L11.72 15A3.064 3.064 0 0 1 9 12.28L5.6 8.87c-.99.85-1.82 1.91-2.42 3.13Z"
                      />
                    </svg>
                  </Button>
                ) : (
                  <Button className="eye_comon" onClick={togglePassword}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#fff"
                        d="M12 9a3 3 0 0 1 3 3a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3m0-4.5c5 0 9.27 3.11 11 7.5c-1.73 4.39-6 7.5-11 7.5S2.73 16.39 1 12c1.73-4.39 6-7.5 11-7.5M3.18 12a9.821 9.821 0 0 0 17.64 0a9.821 9.821 0 0 0-17.64 0Z"
                      />
                    </svg>
                  </Button>
                )}

                <div className="leftform_icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M15.8333 9.16669H4.16667C3.24619 9.16669 2.5 9.91288 2.5 10.8334V16.6667C2.5 17.5872 3.24619 18.3334 4.16667 18.3334H15.8333C16.7538 18.3334 17.5 17.5872 17.5 16.6667V10.8334C17.5 9.91288 16.7538 9.16669 15.8333 9.16669Z"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.83337 9.16669V5.83335C5.83337 4.72828 6.27236 3.66848 7.05376 2.88708C7.83516 2.10567 8.89497 1.66669 10 1.66669C11.1051 1.66669 12.1649 2.10567 12.9463 2.88708C13.7277 3.66848 14.1667 4.72828 14.1667 5.83335V9.16669"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </FormGroup>

              <FormGroup className="position-relative">
                <Input
                  type={cpasswordType}
                  id="cpass"
                  placeholder="Confirm Password"
                  onChange={chandlePasswordChange}
                  value={cpasswordInput}
                />

                {cpasswordType === "password" ? (
                  <Button className="eye_comon" onClick={ctogglePassword}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#fff"
                        d="M2 5.27L3.28 4L20 20.72L18.73 22l-3.08-3.08c-1.15.38-2.37.58-3.65.58c-5 0-9.27-3.11-11-7.5c.69-1.76 1.79-3.31 3.19-4.54L2 5.27M12 9a3 3 0 0 1 3 3a3 3 0 0 1-.17 1L11 9.17A3 3 0 0 1 12 9m0-4.5c5 0 9.27 3.11 11 7.5a11.79 11.79 0 0 1-4 5.19l-1.42-1.43A9.862 9.862 0 0 0 20.82 12A9.821 9.821 0 0 0 12 6.5c-1.09 0-2.16.18-3.16.5L7.3 5.47c1.44-.62 3.03-.97 4.7-.97M3.18 12A9.821 9.821 0 0 0 12 17.5c.69 0 1.37-.07 2-.21L11.72 15A3.064 3.064 0 0 1 9 12.28L5.6 8.87c-.99.85-1.82 1.91-2.42 3.13Z"
                      />
                    </svg>
                  </Button>
                ) : (
                  <Button className="eye_comon" onClick={ctogglePassword}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#fff"
                        d="M12 9a3 3 0 0 1 3 3a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3m0-4.5c5 0 9.27 3.11 11 7.5c-1.73 4.39-6 7.5-11 7.5S2.73 16.39 1 12c1.73-4.39 6-7.5 11-7.5M3.18 12a9.821 9.821 0 0 0 17.64 0a9.821 9.821 0 0 0-17.64 0Z"
                      />
                    </svg>
                  </Button>
                )}
                <div className="leftform_icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M15.8333 9.16669H4.16667C3.24619 9.16669 2.5 9.91288 2.5 10.8334V16.6667C2.5 17.5872 3.24619 18.3334 4.16667 18.3334H15.8333C16.7538 18.3334 17.5 17.5872 17.5 16.6667V10.8334C17.5 9.91288 16.7538 9.16669 15.8333 9.16669Z"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.83337 9.16669V5.83335C5.83337 4.72828 6.27236 3.66848 7.05376 2.88708C7.83516 2.10567 8.89497 1.66669 10 1.66669C11.1051 1.66669 12.1649 2.10567 12.9463 2.88708C13.7277 3.66848 14.1667 4.72828 14.1667 5.83335V9.16669"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </FormGroup>

              <Button className="btn_common" onClick={handleSubmit}>
                Sign Up
              </Button>

              <div className="dont_have_account my-4">
                <p>
                  Already have an account?{" "}
                  <Link to="/sign-in">Login Here </Link>
                </p>
              </div>
            </Form>
          </div>
        </Container>
      </section>
    </Fade>
  );
};

export default Signup;

import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

import Hotpairs from "../Hotpairs";
import Leveragetrading from "./leveragetrading";
import { useDispatch, useSelector } from "react-redux";
import { getTradeTokenRequest } from "store/actions";

const Tradeleverage = () => {
  const dispatch = useDispatch();
  const { tradeTokens } = useSelector((state) => state.Crypto);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    dispatch(getTradeTokenRequest(filters));
  }, [filters]);

  return (
    <section className="trade_new my-3 mb-5">
      <Container fluid>
        <Hotpairs tradeTokens={tradeTokens} />
        <Leveragetrading />
      </Container>
    </section>
  );
};

export default Tradeleverage;

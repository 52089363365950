export const MAKE_BOT_REQUEST = "MAKE_BOT_REQUEST"
export const MAKE_BOT_REQUEST_SUCCESS = "MAKE_BOT_REQUEST_SUCCESS"
export const MAKE_BOT_REQUEST_FAIL = "MAKE_BOT_REQUEST_FAIL"

export const EDIT_BOT_REQUEST = "EDIT_BOT_REQUEST"
export const EDIT_BOT_REQUEST_SUCCESS = "EDIT_BOT_REQUEST_SUCCESS"
export const EDIT_BOT_REQUEST_FAIL = "EDIT_BOT_REQUEST_FAIL"

export const CANCEL_BOT_REQUEST = "CANCEL_BOT_REQUEST"
export const CANCEL_BOT_REQUEST_FAIL = "CANCEL_BOT_REQUEST_FAIL"
export const CANCEL_BOT_REQUEST_SUCCESS = "CANCEL_BOT_REQUEST_SUCCESS"

export const GET_BOT_REQUESTS = "GET_BOT_REQUESTS"
export const GET_BOT_REQUESTS_FAIL = "GET_BOT_REQUESTS_FAIL"
export const GET_BOT_REQUESTS_SUCCESS = "GET_BOT_REQUESTS_SUCCESS"

export const SET_REQUEST_DETAIL = "SET_REQUEST_DETAIL"


import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, FormGroup, Input, Spinner } from "reactstrap";
import OtpInput from "react-otp-input";
import QRCode from "qrcode";
import { useDispatch } from "react-redux";
import toastr from "toastr";

//
import { getTfaOtp, postTfaVerifyOtp } from "store/actions";

export default function EnablettwoFAmodal(props) {
  const dispatch = useDispatch();

  const [loading, setloading] = useState(true);
  const [secret, setSecret] = useState({
    otpauth_url: "",
    base32: "",
  });
  const [qrcodeUrl, setqrCodeUrl] = useState("");
  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (props.show) {
      const cb = (response) => {
        if (response.status === "success") {
          setSecret({
            base32: response.data.base32,
            otpauth_url: response.data.otpauth_url,
          });

          QRCode.toDataURL(response.data.otpauth_url).then(setqrCodeUrl);
        }
        setloading(false);
      };
      dispatch(getTfaOtp(cb));

      return () => {
        setSecret({
          otpauth_url: "",
          base32: "",
        });
        setloading(true);
      };
    }
  }, [props.show]);

  const copySecret = () => {
    const textToCopy = secret.base32;

    const tempInput = document.createElement("textarea");
    tempInput.value = textToCopy;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    const cb = (resp) => {
      if (resp.status === "failure") {
        toastr.error(resp.message);
      } else {
        toastr.success("Two-Factor Auth Enabled Successfully!");
        props.onhide();
      }
    };
    dispatch(postTfaVerifyOtp({ token: otp }, cb));
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add_card_mod"
    >
      <Modal.Body>
        <div className="Enable_modal px-5">
          <h4>Enable 2FA</h4>
          <Form onSubmit={handleSubmit}>
            <div className="pointione">
              <ul>
                <li>
                  <div className="first_point_wrap d-flex align-items-start justify-content-between">
                    <div className="pointone_left">
                      <p>
                        <strong>
                          You will need an authenticator mobile app to complete
                          this process such as one the following.
                        </strong>
                      </p>
                      <p className="col_green">Google Authenticator</p>
                      <p className="col_green">Microsoft Authenticator</p>
                      <p className="col_green">Duo Mobile</p>
                    </div>
                    {loading && (
                      <div className="pointwoqr_left d-flex align-items-center justify-content-center">
                        <Spinner className="me-2" size="sm" />
                      </div>
                    )}
                    {!loading && (
                      <div className="pointwoqr_left">
                        <img src={qrcodeUrl} alt="" />
                      </div>
                    )}
                  </div>
                </li>

                <li>
                  <div className="first_point_wrap d-flex align-items-start justify-content-between">
                    <div className="pointone_left w-100">
                      <p>
                        <strong>
                          Scan the QR code with your authenticator
                        </strong>
                      </p>
                      <p>
                        If you can't scan the code , you can enter this secret
                        key into your authentication app
                      </p>
                      <FormGroup className="position-relative mt-3">
                        <Input
                          type="text"
                          name="code"
                          id="code"
                          value={secret.base32}
                          readOnly
                        />
                        <Button
                          className="copy_btn"
                          type="button"
                          onClick={copySecret}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#ccc"
                              d="M9.115 17q-.69 0-1.152-.462q-.463-.463-.463-1.153V4.615q0-.69.463-1.152Q8.425 3 9.115 3h7.77q.69 0 1.152.463q.463.462.463 1.152v10.77q0 .69-.462 1.153q-.463.462-1.153.462zm-3 3q-.69 0-1.152-.462q-.463-.463-.463-1.153V7.115q0-.213.143-.356q.144-.144.357-.144t.357.144t.143.356v11.27q0 .23.192.423q.193.192.423.192h8.27q.213 0 .356.143t.144.357q0 .213-.144.357q-.143.143-.356.143z"
                            />
                          </svg>
                        </Button>
                      </FormGroup>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="first_point_wrap d-flex align-items-start justify-content-between">
                    <div className="pointone_left w-100">
                      <p>
                        <strong>
                          After Scanning the QR code above, enter the six digit
                          code generated by the authenticator
                        </strong>
                      </p>
                      <div className="sixdigit_code">
                        <OtpInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={6}
                          renderSeparator={<span>-</span>}
                          renderInput={(props) => (
                            <input {...props} type="number" />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div className="btn-verify mt-4 d-flex align-items-center justify-content-end gap-3">
              <Button className="btn_verify_cancel" onClick={props.onhide}>
                Cancel
              </Button>
              <Button type="submit" className="btn_verify_done">
                Verify
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

import React from "react";
import Headerlogin from "Component/Header/Headerlogin";
import Expertprofile from "Pages/Profile/Expertprofile";

export default function Expertsprofiletheme() {
  return (
    <>
       <Headerlogin heading="Dashboard"/>
       <Expertprofile/>
    </>
  );
}

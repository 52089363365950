import { call, put, takeEvery } from "redux-saga/effects";
import * as CONST from "./actionTypes";
import * as ACT from "./actions";
import { addTransactionApi, getTransactionsApi, addInvestmentApi } from "helpers/backend_helper";
import toastr from "toastr";

function* getTransactionsSaga({ payload }) {
  try {
    const response = yield call(getTransactionsApi, payload);
    if (response.status == "failure") {
      return yield put(ACT.getTransactionFail(response.message));
    }
    yield put(ACT.getTransactionSuccess(response));
  } catch (error) {
    yield put(ACT.getTransactionFail(error));
  }
}

function* addTransactionSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(addTransactionApi, data);
    if (response.status == "failure") {
      toastr.error(response.message)
      return yield put(ACT.addTransactionFail(response.message));
    }
    toastr.success("Transaction successfully saved!")
    callback && callback(response);
    yield put(ACT.addTransactionSuccess(response));

  } catch (error) {
    toastr.error("Something went wrong!")
    yield put(ACT.addTransactionFail(error));
  }
}

function* addInvestmentSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(addInvestmentApi, data);
    if (response.status == "failure") {
      toastr.error(response.message)
      return yield put(ACT.addInvestmentFail(response.message));
    }
    toastr.success("Investment successfully saved!")
    callback && callback(response);
    yield put(ACT.addInvestmentSuccess(response));

  } catch (error) {
    toastr.error("Something went wrong!")
    yield put(ACT.addInvestmentFail(error));
  }
}




function* transactionSaga() {
  yield takeEvery(CONST.GET_TRANSACTION, getTransactionsSaga);
  yield takeEvery(CONST.ADD_TRANSACTION, addTransactionSaga);
  yield takeEvery(CONST.ADD_INVESTMENT, addInvestmentSaga);
}

export default transactionSaga;

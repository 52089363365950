import React, { useEffect, useState } from "react";
import { Spinner, Table } from "reactstrap";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import axios from "axios";

const Tradeexperts = (props) => {
  const [loading, setloading] = useState(true);
  const [stocks, setstocks] = useState([]);

  useEffect(() => {
    let username = process.env.REACT_APP_ALPACA_KEY;
    let password = process.env.REACT_APP_ALPACA_SECRET_KEY;
    const base64encodedData = Buffer.from(`${username}:${password}`).toString(
      "base64"
    );

    const options = {
      method: "GET",
      url: "https://broker-api.sandbox.alpaca.markets/v1/assets",
      params: { status: "active", asset_class: "us_equity", attributes: "" },
      headers: {
        accept: "application/json",
        authorization: `Basic ${base64encodedData}`,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        setstocks(response.data);
        setloading(false);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  return (
    <section className="trade_new Opendertable-table pairprice_chart my-3">
      <div className="pairshared_table">
        <Fade>
          <div className="Liquidity-main trading_table">
            <div className="KYC-table">
              <div className="d-flex justify-content-center">
                {loading && <Spinner style={{ color: "#fff" }} />}
              </div>

              <Table responsive>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Symbol</th>
                    <th>Exchange</th>
                  </tr>
                </thead>

                <tbody>
                  {stocks.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>

                      <td>{item.symbol}</td>

                      <td>{item.exchange}</td>

                      <td>
                        <Link
                          to={`/stocks/${item.symbol}?exchange=${item.exchange}`}
                          className="view_profile"
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default Tradeexperts;

import * as TYPES from "./actionTypes";



const INIT_STATE = {
  loading: false,
  settings: null,
  starterLoading: false,
  error: "",
};

const Settings = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get  Settings */
    case TYPES.GET_SETTINGS:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case TYPES.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        settings: action.payload.data,
      };

    case TYPES.GET_SETTINGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default Settings;

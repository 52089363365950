import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

const CustomPagination = ({ currentPage, totalPages, onPageChange, maxPagesToShow = 5 }) => {
  const getPageItems = () => {
    const items = [];
    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage < maxPagesToShow - 1) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => onPageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <Pagination>
      {currentPage > 1 && (
        <Pagination.Prev onClick={() => onPageChange(currentPage - 1)} />
      )}
      {getPageItems()}
      {currentPage < totalPages && (
        <Pagination.Next onClick={() => onPageChange(currentPage + 1)} />
      )}
    </Pagination>
  );
};

export default CustomPagination;

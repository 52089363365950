import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import toastr from "toastr";

// Login Redux States
import { EDIT_PROFILE, CHANGE_PASSWORD, VALIDATE_WALLET } from "./actionTypes";
import {
  profileSuccess,
  profileError,
  putChangePwdSuccess,
  putChangePwdError,
  validateWalletFail,
  validateWalletSuccess,
} from "./actions";

import { currentUserSuccess, loginWithOtp } from "store/actions";

//Include Both Helper File with needed methods
import {
  putProfile,
  putChangePassword,
  validateWallet,
} from "helpers/backend_helper";

function* editProfile({ payload: { data, callback } }) {
  try {
    const response = yield call(putProfile, data);
    if (response.status === "failure") {
      return yield put(profileError(response.message));
    }
    toastr.success("Updated successfully");
    yield put(profileSuccess(response));
    let lData = JSON.parse(localStorage.getItem("authUser") || "{}");
    if (lData?.data?.Token) {
      const updatedData = {
        ...lData,
        data: { ...lData.data, ...response.data },
      };
      localStorage.setItem("authUser", JSON.stringify(updatedData));
      localStorage.setItem("userData", JSON.stringify(updatedData));
      yield put(currentUserSuccess(updatedData));
    }

    callback && callback(response);
  } catch (error) {
    yield put(profileError("Internal Server Error"));
  }
}

function* changePassword({ payload: { data, callback } }) {
  try {
    const response = yield call(putChangePassword, data);

    callback && callback(response);

    if (response.status === "failure") {
      return yield put(putChangePwdError(response.message));
    }

    yield put(putChangePwdSuccess(response));
    toastr.success("Password updated successfully!");
  } catch (error) {
    yield put(putChangePwdError("Internal Server Error"));
  }
}

function* validateWalletSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(validateWallet, data);

    if (response.status === "failure") {
      callback && callback(false, response);
      return yield put(validateWalletFail(response.message));
    }
    callback && callback(true, response);
    yield put(validateWalletSuccess(response));
  } catch (error) {
    yield put(putChangePwdError("Internal Server Error"));
  }
}

export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile);
  yield takeEvery(CHANGE_PASSWORD, changePassword);
  yield takeEvery(VALIDATE_WALLET, validateWalletSaga);
}

function* ProfileSaga() {
  yield all([fork(watchProfile)]);
}

export default ProfileSaga;

import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { Button } from "reactstrap";

// images

export default function Header() {
  const [nav, setNav] = useState(false);

  const [scroll, setScroll] = useState(false);
  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     setScroll(window.scrollY > 50);
  //   });
  // }, []);

  return (
    <>
      <section className={scroll ? "header-main  fixed-header" : "header-main"}>
        <Container>
          <div className="header">
            <nav className="navbar navbar-expand-lg">
              <div className="container-fluid">
                <Link to="/" className="navbar-brand">
                  <img
                    width={"150px"}
                    src="images/logo.png"
                    alt="logo"
                    className="img-fluid"
                  />
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => setNav(!nav)}
                >
                  <span className="bar"></span>
                  <span className="bar"></span>
                  <span className="bar"></span>
                </button>
                <div
                  class={
                    nav
                      ? "collapse navbar-collapse show"
                      : "collapse navbar-collapse"
                  }
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav scroll-menu m-auto mb-2 mb-lg-0">
                    <Button
                      onClick={() => setNav(false)}
                      className="close-menu"
                    >
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--gg"
                        width="28"
                        height="28"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#ffffff"
                          d="M6.225 4.811a1 1 0 0 0-1.414 1.414L10.586 12L4.81 17.775a1 1 0 1 0 1.414 1.414L12 13.414l5.775 5.775a1 1 0 0 0 1.414-1.414L13.414 12l5.775-5.775a1 1 0 0 0-1.414-1.414L12 10.586L6.225 4.81Z"
                        />
                      </svg>
                    </Button>
                    <li className="nav-item">
                      <NavLink
                        to="/invest-tutorial"
                        className="nav-link gradient-text"
                        aria-current="page"
                      >
                        InVest Tutorials
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink
                        to="/leaderboard"
                        className="nav-link gradient-text"
                      >
                        Leaderboard
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/blog" className="nav-link gradient-text">
                        Post
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink
                        to="/stake-IT"
                        className="nav-link gradient-text"
                      >
                        Stake IT
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>

            <div className="add-wallet-wrap">
              <div className="wallet-section">
                <Link to="/sign-up" className="nav-link sigb-up-link">
                  Sign Up
                </Link>
                <Link to="/sign-in" className="nav-link sigb-up-link">
                  Sign In
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

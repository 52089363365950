import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";
import * as API from "helpers/backend_helper";

function* verifyOtp({ payload: { user, callback } }) {
  try {
    const response = yield call(API.postVerifyOtp, user);

    callback && callback(response);
    if (response.status == "failure") {
      return yield put(ACTIONS.verifyOtpFailed(response.message));
    }
    
    yield put(ACTIONS.verifyOtpSuccessful(response));
  } catch (error) {
    yield put(ACTIONS.verifyOtpFailed("Internal Server Error"));
  }
}

export function* watchVerifyOtp() {
  yield takeEvery(TYPES.VERIFY_OTP, verifyOtp);
}

function* accountSaga() {
  yield all([fork(watchVerifyOtp)]);
}

export default accountSaga;

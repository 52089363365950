export const OPEN_ORDER = "OPEN_ORDER"
export const OPEN_ORDER_SUCCESSFUL = "OPEN_ORDER_SUCCESSFUL"
export const OPEN_ORDER_FAILED = "OPEN_ORDER_FAILED"

export const UPDATE_ORDER = "UPDATE_ORDER"
export const UPDATE_ORDER_SUCCESSFUL = "UPDATE_ORDER_SUCCESSFUL"
export const UPDATE_ORDER_FAILED = "UPDATE_ORDER_FAILED"

// order List
export const GET_ORDERS = "GET_ORDERS"
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL"
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS"

// leaderboard
export const GET_LEADERBOARD = "GET_LEADERBOARD"
export const GET_LEADERBOARD_FAIL = "GET_LEADERBOARD_FAIL"
export const GET_LEADERBOARD_SUCCESS = "GET_LEADERBOARD_SUCCESS"

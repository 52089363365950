import React, { useEffect, useState } from "react";
import { Container, Spinner } from "reactstrap";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { addInvestment, getTransaction } from "store/actions";
import CustomPagination from "Component/CustomPagination";
import InvestmentConfirmModal from "../InvestmentConfirmModal";

const OTCtrade = () => {
  const { loading, total, transactions } = useSelector(
    ({ Transaction }) => Transaction
  );
  const { user } = useSelector(({ Login }) => Login);


  const dispatch = useDispatch();
  const [filters, setFiters] = useState({
    page: 1,
    limit: 10,
    loading: true,
    user: user._id
  });

  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    _id: null,
    isInvestment: false,
  });

  useEffect(() => {
    if (user) {
      dispatch(getTransaction({ ...filters }));
    }
  }, [user, filters]);

  const onPageChange = (page) => {
    setFiters((prev) => ({ ...prev, page }));
  };
  const totalPages = Math.ceil(total / filters?.limit);

  const toggleConfirmModal = () => {
    setConfirmModal({ isOpen: false, _id: null, isInvestment: false });
  };

  const investmentConfirm = (item) => {
    const { _id, isOpen } = confirmModal;

    setConfirmModal({
      isOpen: !isOpen,
      _id: item._id,
      isInvestment: !item.isInvestment,
    });

 };

  const invest = () => {
    const { _id, isOpen, isInvestment } = confirmModal;

    setConfirmModal({
        isOpen: false,
        _id: null,
        isInvestment: false,
      });

    dispatch(
      addInvestment(
        {
          _id,
          isInvestment,
        },
        () => {
          dispatch(getTransaction({ ...filters, loading: false}));
        }
      )
    );
  };

  return (
    <React.Fragment>
      <InvestmentConfirmModal
        isOpen={confirmModal.isOpen}
        toggle={() => toggleConfirmModal()}
        onConfirm={() => invest()}
      />
      <section className="OTCtrade_section">
        <Container>
          <h5 className="otc_historyhead">Trasnsaction History</h5>

          <div className="Otc_table">
            <Fade>
              <div className="Liquidity-main">
                <div className="KYC-table">
                  <div className="d-flex justify-content-center">
                    {loading && <Spinner style={{ color: "#fff" }} />}
                  </div>
                  {!loading && (
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Token A Amount</th>
                          <th>Token B Amount</th>
                          <th>Type</th>
                          <th>Transaction Hash</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(transactions || []).map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <Link
                                  to=""
                                  className="linked"
                                  // onClick={() => setmypool(false)}
                                >
                                  <div className="name_itmes">
                                    <img
                                      src={`${item?.tokenA?.icon}`}
                                      alt=""
                                      width="30px"
                                      className="img-fluid"
                                    />
                                    -
                                    <img
                                      src={`${item?.tokenB?.icon}`}
                                      width="30px"
                                      alt=""
                                      className="img-fluid"
                                    />
                                    <p>
                                      {item?.tokenA?.symbol}-{" "}
                                      {item?.transType == "buy" ? "Eth" :item?.tokenB?.symbol}
                                    </p>
                                  </div>
                                </Link>
                              </td>
                              <td>{item?.tokenA?.tokenAmount}</td>
                              <td>{item?.transType == "buy" ?  item?.cryptoAmount :item?.tokenB?.tokenAmount}</td>
                              <td>{item?.transType}</td>
                              <td title={item?.transactionHash}>
                                <a
                                  target="_blank"
                                  href={`${item?.explorerUrl}tx/${item?.transactionHash}`}
                                >
                                  {item?.transactionHash?.slice(0, 30) + "..."}
                                </a>
                              </td>
                              <td>{item?.receiptStatus}</td>
                           
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}
                  <div className="d-flex justify-content-end">
                    <CustomPagination
                      currentPage={filters?.page}
                      totalPages={totalPages}
                      onPageChange={(page) => onPageChange(page)}
                      maxPagesToShow={5}
                    />
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default OTCtrade;

import React from "react";
import TfaVerify from "Pages/TfaVerify";

export default function TfaVerifyTheme() {
  return (
    <>
      <TfaVerify />
    </>
  );
}

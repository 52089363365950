import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Expertprofilecol from "./Expertprofilecol";

const Expertprofile = () => {
  return (
    <section className="trade_new my-3">
      <Container fluid>
        <Expertprofilecol heading="Trading Experts" />
      </Container>
    </section>
  );
};

export default Expertprofile;

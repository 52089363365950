import React from "react";
import Headerlogin from "Component/Header/Headerlogin";
import Blogpage from "Pages/Profile/BLog";


export default function Blogtheme() {
  return (
    <>
       <Headerlogin heading="Post"/>
       <Blogpage/>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getExpert } from "store/actions";
import CustomPagination from "Component/CustomPagination";

const Tradeexpertschart = (props) => {
  const { loading, total, experts } = useSelector(({ Expert }) => Expert);
  
  const { user } = useSelector(({ Login }) => Login);
  const dispatch = useDispatch();

  const [filters, setFiters] = useState({
    page: 1,
    limit: 10,
    loading: true,
  });

  useEffect(() => {
    if (user) {
      dispatch(getExpert({ ...filters }));
    }
  }, [user, filters]);

  const onPageChange = (page) => {
    setFiters((prev) => ({ ...prev, page }));
  };
  const totalPages = Math.ceil(total / filters?.limit);

  return (
    <section className="Opendertable-table pairprice_chart mt-3">
      <div className="shared_head d-flex justify-content-between align-items_center mb-3">
        <h3 className="card_heading">{props?.heading}</h3>
      </div>

      <div className="pairshared_table">
        <Fade>
          <div className="Liquidity-main trading_table">
            <div className="KYC-table">
              <div className="d-flex justify-content-center">
                {loading && <Spinner style={{ color: "#fff" }} />}
              </div>
              {!loading && (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Username</th>
                      <th>Investments</th>
                      <th>Gain %</th>
                      <th>Value</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(experts || []).map((item, index) => {
                      return (
                        (item?._id !== user._id )
                          && <tr  key={index}>
                          <td>{item?.username}</td>
                          <td>
                            <Link to="">
                              <div className="table_assets d-flex align-items-center">
                                <div className="table_assets_img me-2">
                                  <img
                                    src="/images/USDcoin.png"
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="table_assets_content">
                                  <p>
                                    <strong>Name</strong>
                                  </p>
                                  <p> Uniswap v2 (Ethereum)</p>
                                </div>
                              </div>
                            </Link>
                          </td>
                          <td>12.04</td>
                          <td>0.087</td>
                          <td>
                            <Link to={`/expert-profile/${item?._id}`} className="view_profile">
                              View Profile
                            </Link>
                          </td>
                        </tr>
                      
                      );
                    })}
                  </tbody>
                </Table>
              )}
              <div className="d-flex justify-content-end">
                {experts.length > 1 || (experts.length && experts[0]._id !== user._id)
                &&
                <CustomPagination
                  currentPage={filters?.page}
                  totalPages={totalPages}
                  onPageChange={(page) => onPageChange(page)}
                  maxPagesToShow={5}
                />
                }
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default Tradeexpertschart;

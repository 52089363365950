import React from "react";
import Headerlogin from "Component/Header/Headerlogin";
import Position from "Pages/Profile/Position";

export default function Positiontheme() {
  return (
    <>
      <Headerlogin heading="Position" />
      <Position />
    </>
  );
}
